/* global appConfig */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';
import { auth as authActions } from '../../actions';
import routePath from '../../constants/routePath';
import Footer from '../../components/Footer';

class Login extends React.Component {
  componentDidMount() {
    document.body.classList.add('authentication-bg');
  }

  handleSubmit = values => {
    const { doLogin } = this.props;
    doLogin(values);
  };

  render() {
    const { loginProgress } = this.props;
    return (
      <>
        <div className="account-pages">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-11 col-xl-9">
                <div className="card ">
                  <div className="card-header border-bottom-dash text-center">
                    <img
                      className="logo-img"
                      src={appConfig.logo}
                      alt="{appConfig.logoALT}"
                      height="54"
                    />
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 border-right-dash">
                        <div className="p-md-3">
                          <h4 className="text-dark-50 mt-0 font-weight-bold ">
                            Do not have an Account?
                          </h4>
                          <p className="text-muted mb-2">
                            Register today for a quicker and easier checkout
                            process.
                          </p>
                          <h5 className="text-dark-50 mb-2 font-weight-semibold">
                            Sign up today and you will be able to:
                          </h5>
                          <div className="mb-3">
                            <ul className="list-none mb-0">
                              <li>
                                <i className="mdi mdi-check-circle text-success" />{' '}
                                Access Offers &amp; Promotions
                              </li>
                              <li>
                                <i className="mdi mdi-check-circle text-success" />{' '}
                                Speed your way through checkout
                              </li>
                              <li>
                                <i className="mdi mdi-check-circle text-success" />{' '}
                                Track your orders easily
                              </li>
                              <li>
                                <i className="mdi mdi-check-circle text-success" />{' '}
                                Keep a record of all your purchases
                              </li>
                            </ul>
                          </div>
                          <p className="text-muted mb-3 font-11">
                            Your personal data will be used to support your
                            experience throughout the website to manage access
                            to your account and for other purpose described in
                            our{' '}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={appConfig.companyTermsURL}
                            >
                              privacy policy
                            </a>
                            .
                          </p>
                          <div className="text-center">
                            <Link
                              to={routePath.register.path}
                              className="btn btn-success btn-lg pl-5 pr-5"
                            >
                              Register
                            </Link>
                          </div>
                          <div className="d-md-none mb-4" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="p-md-3">
                          <h4 className="text-dark-50 mt-0 font-weight-bold">
                            Login
                          </h4>
                          <p className="text-muted mb-2">
                            Welcome to the Customer Portal! Please sign-in with
                            your Cust ID or Email and password to access your
                            account.
                          </p>
                          <LoginForm
                            submitLogin={this.handleSubmit}
                            inProgress={loginProgress}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({ loginProgress: state.auth.loginProgress });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ doLogin: authActions.doLogin }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

Login.defaultProps = {
  doLogin: () => {},
  loginProgress: false
};

Login.propTypes = {
  doLogin: PropTypes.func,
  loginProgress: PropTypes.bool
};
