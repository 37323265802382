const paths = {
  dashboard: {
    path: '/',
    title: 'Dashboard'
  },
  login: {
    path: '/login',
    title: 'Login'
  },
  forgot: {
    path: '/forgot-password',
    title: 'Forgot Password'
  },
  reset: {
    path: '/reset-password/:token',
    title: 'Reset Password'
  },
  register: {
    path: '/register',
    title: 'Register'
  },
  ref: {
    path: '/ref/:refId',
    title: 'Redirect'
  },
  thankyou: {
    path: '/thank-you',
    title: 'Finish'
  },
  cancel: {
    path: '/cancel',
    title: 'Payment Cancel'
  },
  package: {
    path: '/package',
    title: 'Package Subscription'
  },
  associate: {
    path: '/associate',
    title: 'Associates',
    permission: 'associate_read',
    breadcrumb: ['associate']
  },
  associateAdd: {
    path: '/associate/add',
    title: 'Add Associates',
    permission: 'associate_read',
    breadcrumb: ['associate', 'associateAdd']
  },
  associatePackage: {
    path: '/associate-package',
    title: 'Packages',
    permission: 'package_read',
    breadcrumb: ['associatePackage']
  },
  associatePackageAdd: {
    path: '/associate-package/add',
    title: 'Add Package',
    permission: 'package_write',
    breadcrumb: ['associatePackage', 'associatePackageAdd']
  },
  associatePackageEdit: {
    path: '/associate-package/:id/edit',
    title: 'Edit Package',
    permission: 'package_write',
    breadcrumb: ['associatePackage', 'associatePackageEdit']
  },
  tree: {
    path: '/downline-tree',
    title: 'Downline Tree',
    permission: 'tree_read',
    breadcrumb: ['tree']
  },
  role: {
    path: '/role',
    title: 'Role',
    permission: 'permission_read',
    breadcrumb: ['role']
  },
  roleUserList: {
    path: '/role/:id/users',
    title: 'User List',
    permission: 'permission_write',
    breadcrumb: ['role', 'roleUserList']
  },
  rolePermissionList: {
    path: '/role/:id/permissions',
    title: 'Role Permissions',
    permission: 'permission_write',
    breadcrumb: ['role', 'rolePermissionList']
  },
  category: {
    path: '/category',
    title: 'Categories',
    permission: 'product_read',
    breadcrumb: ['category']
  },
  categoryAdd: {
    path: '/category/add',
    title: 'Add Category',
    permission: 'product_write',
    breadcrumb: ['category', 'categoryAdd']
  },
  categoryEdit: {
    path: '/category/:id/edit',
    title: 'Edit Category',
    permission: 'product_write',
    breadcrumb: ['category', 'categoryEdit']
  },
  categoryProduct: {
    path: '/category/:id/product',
    title: 'Product List',
    permission: 'product_read',
    breadcrumb: ['category', 'categoryProduct']
  },
  product: {
    path: '/product',
    title: 'Products',
    permission: 'product_read',
    breadcrumb: ['product']
  },
  productView: {
    path: '/product/:id/view',
    title: 'View Product',
    permission: 'product_read',
    breadcrumb: ['product', 'productView']
  },
  productAdd: {
    path: '/product/add',
    title: 'Add Product',
    permission: 'product_write',
    breadcrumb: ['product', 'productAdd']
  },
  productEdit: {
    path: '/product/:id/edit',
    title: 'Edit Product',
    permission: 'product_write',
    breadcrumb: ['product', 'productEdit']
  },
  generation: {
    path: '/generation',
    title: 'Generation',
    permission: 'generation_read',
    breadcrumb: ['generation']
  },
  generationAdd: {
    path: '/generation/add',
    title: 'Add Generation',
    permission: 'generation_write',
    breadcrumb: ['generation', 'generationAdd']
  },
  generationEdit: {
    path: '/generation/:id/edit',
    title: 'Edit Generation',
    permission: 'generation_write',
    breadcrumb: ['generation', 'generationEdit']
  },
  rank: {
    path: '/rank',
    title: 'Rank',
    permission: 'rank_read',
    breadcrumb: ['rank']
  },
  rankAdd: {
    path: '/rank/add',
    title: 'Add Rank',
    permission: 'rank_write',
    breadcrumb: ['rank', 'rankAdd']
  },
  rankEdit: {
    path: '/rank/:id/edit',
    title: 'Edit Rank',
    permission: 'rank_write',
    breadcrumb: ['rank', 'rankEdit']
  },
  order: {
    path: '/order',
    title: 'Orders',
    breadcrumb: ['order']
  },
  myOrder: {
    path: '/order/own',
    title: 'Orders',
    breadcrumb: ['order']
  },
  invoiceView: {
    path: '/order/:id/invoice',
    title: 'Invoice',
    breadcrumb: ['order', 'invoiceView']
  },
  downlineAssociateOrder: {
    path: '/order/downline-associates',
    title: 'Downline Associate',
    breadcrumb: ['myOrder', 'downlineAssociateOrder']
  },
  confirmation: {
    path: '/confirmation/:id',
    title: 'Confirmation',
    breadcrumb: ['confirmation']
  },
  ownCustomerOrder: {
    path: '/order/own-customers',
    title: 'Own Customers',
    breadcrumb: ['myOrder', 'ownCustomerOrder']
  },
  packageInvoiceView: {
    path: '/subscription/:id/invoice',
    title: 'Subscription Invoice',
    breadcrumb: ['order', 'packageInvoiceView']
  },
  confirmationZotto: {
    path: '/confirmation',
    title: 'Confirmation',
    breadcrumb: ['confirmationZotto']
  },
  fsb: {
    path: '/fsb',
    title: 'FSB Config',
    permission: 'bonus_read',
    breadcrumb: ['fsb']
  },
  fsbView: {
    path: '/fsb/:id/view',
    title: 'FSB Config',
    permission: 'bonus_read',
    breadcrumb: ['fsb', 'fsbView']
  },
  fsbEdit: {
    path: '/fsb/:id/edit',
    title: 'Edit FSB Config',
    permission: 'bonus_write',
    breadcrumb: ['fsb', 'fsbEdit']
  },
  orderView: {
    path: '/order/:id/view',
    title: 'View Order',
    breadcrumb: ['order', 'orderView']
  },
  shop: {
    path: '/shop',
    title: 'Shop',
    permission: 'shop_associate_read',
    breadcrumb: ['shop']
  },
  shopCategoryProducts: {
    path: '/shop/:category',
    title: 'Product',
    permission: 'shop_associate_read',
    breadcrumb: ['shop', 'shopCategoryProducts']
  },
  shopPagination: {
    path: '/shop/:category/:page',
    title: 'Category Products',
    permission: 'shop_associate_read',
    breadcrumb: ['shop', 'shopCategoryProducts']
  },
  shopProduct: {
    path: '/shop/product/:slug',
    title: 'Product Details',
    permission: 'shop_associate_read',
    breadcrumb: ['shop', 'shopProduct']
  },
  cart: {
    path: '/cart',
    title: 'Cart',
    breadcrumb: ['cart']
  },
  checkout: {
    path: '/checkout',
    title: 'Checkout',
    breadcrumb: ['checkout']
  },
  cancelPayment: {
    path: '/cancel',
    title: 'Cancel Payment',
    breadcrumb: ['checkout']
  },
  account: {
    path: '/account',
    title: 'Account',
    breadcrumb: ['account']
  },
  profile: {
    path: '/account/profile',
    title: 'Profile & Security',
    breadcrumb: ['account', 'profile']
  },
  addresses: {
    path: '/account/addresses',
    title: 'Addresses',
    breadcrumb: ['account', 'addresses']
  },
  // payOptions: {
  //   path: '/account/pay-options',
  //   title: 'Payment Options',
  //   breadcrumb: ['account', 'payOptions']
  // },
  fsbTracker: {
    path: '/account/fsb-tracker',
    title: 'FSB',
    breadcrumb: ['account', 'fsbTracker']
  },
  template: {
    path: '/template',
    title: 'Email Templates',
    permission: 'email_template_read',
    breadcrumb: ['template']
  },
  templateAdd: {
    path: '/template/add',
    title: 'Add Email Template',
    permission: 'email_template_write',
    breadcrumb: ['template', 'templateAdd']
  },
  templateEdit: {
    path: '/template/:id/edit',
    title: 'Edit Email Template',
    permission: 'email_template_write',
    breadcrumb: ['template', 'templateEdit']
  },
  setting: {
    path: '/setting',
    title: 'Settings',
    permission: 'email_template_read',
    breadcrumb: ['setting']
  },
  settingAdd: {
    path: '/setting/add',
    title: 'Add Setting',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'settingAdd']
  },
  generalSetting: {
    path: '/setting/general',
    title: 'General Settings',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'generalSetting']
  },
  storeSetting: {
    path: '/setting/store',
    title: 'Store Settings',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'storeSetting']
  },
  tax: {
    path: '/tax',
    title: 'Tax',
    permission: 'tax_read',
    breadcrumb: ['tax']
  },
  taxAdd: {
    path: '/tax/add',
    title: 'Add Tax',
    permission: 'tax_write',
    breadcrumb: ['tax', 'taxAdd']
  },
  taxEdit: {
    path: '/tax/:id/edit',
    title: 'Edit Tax',
    permission: 'tax_write',
    breadcrumb: ['tax', 'taxEdit']
  },
  shipping: {
    path: '/shipping',
    title: 'Shipping',
    permission: 'shipping_read',
    breadcrumb: ['shipping']
  },
  shippingAdd: {
    path: '/shipping/add',
    title: 'Add Shipping',
    permission: 'shipping_write',
    breadcrumb: ['shipping', 'shippingAdd']
  },
  shippingEdit: {
    path: '/shipping/:id/edit',
    title: 'Edit Shipping',
    permission: 'shipping_write',
    breadcrumb: ['shipping', 'shippingEdit']
  },
  media: {
    path: '/setting/media',
    title: 'Media',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'media']
  },
  mediaAdd: {
    path: '/setting/media/add',
    title: 'Add Media',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'media', 'mediaAdd']
  },
  mediaEdit: {
    path: '/setting/media/:id/edit',
    title: 'Edit Media',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'media', 'mediaEdit']
  },
  country: {
    path: '/setting/country',
    title: 'Countries',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'country']
  },
  state: {
    path: '/setting/region',
    title: 'Regions',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'state']
  },
  city: {
    path: '/setting/city',
    title: 'Cities',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'city']
  },
  locationAdd: {
    path: '/setting/:type/add/',
    title: 'Add Location',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'locationAdd']
  },
  locationEdit: {
    path: '/setting/:type/:id/edit',
    title: 'Edit Location',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'locationEdit']
  },
  loginHistory: {
    path: '/setting/loginHistory',
    title: 'Login History',
    permission: 'email_template_read',
    breadcrumb: ['setting', 'loginHistory']
  },
  affiliateLogin: {
    path: '/affiliate-login',
    title: 'Affiliate Login'
  }
};

export default paths;
