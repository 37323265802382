import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import MultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';
import PhoneInput from 'react-intl-tel-input';
import { map } from 'lodash';
import InputMask from 'react-input-mask';

const labelOutput = ({ label, tooltip, inline, input }) => {
  if (label) {
    return (
      <label className={inline && `${inline[0]} col-form-label`}>
        {label}{' '}
        {tooltip && (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id={`tooltip-${input.name}`}>{tooltip}</Tooltip>}
          >
            <i className="mdi mdi-help-circle text-primary font-12" />
          </OverlayTrigger>
        )}
      </label>
    );
  }
  return '';
};

export const inputField = ({
  input,
  label,
  tooltip,
  inline,
  size,
  placeholder,
  type,
  readOnly,
  feedback,
  className,
  groupClassName = 'form-group',
  helpBlock,
  meta: { asyncValidating, touched, error, warning }
}) => {
  const changeHandler = e => {
    input.onChange(e);
    if (typeof feedback === 'function') {
      feedback(e.currentTarget.value);
    }
  };

  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''} ${
    size ? ` form-group-${size}` : ''
  } ${asyncValidating ? 'async-validating' : ''}`;

  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        <input
          {...input}
          onChange={changeHandler}
          className={className}
          placeholder={placeholder}
          type={type}
          readOnly={readOnly}
        />

        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const inputGroupField = ({
  input,
  label,
  tooltip,
  inline,
  size,
  placeholder,
  type,
  readOnly,
  feedback,
  className,
  groupClassName = 'form-group',
  helpBlock,
  meta: { asyncValidating, touched, error, warning },
  children,
  position = 'prepend'
}) => {
  const changeHandler = e => {
    input.onChange(e);
    if (typeof feedback === 'function') {
      feedback(e.currentTarget.value);
    }
  };

  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''} ${
    size ? ` form-group-${size}` : ''
  } ${asyncValidating ? 'async-validating' : ''}`;

  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        <div className="input-group">
          {position === 'prepend' && (
            <div className="input-group-prepend">{children}</div>
          )}
          <input
            {...input}
            onChange={changeHandler}
            className={className}
            placeholder={placeholder}
            type={type}
            readOnly={readOnly}
          />
          {position !== 'prepend' && (
            <div className="input-group-append">{children}</div>
          )}
        </div>
        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const inputMaskField = ({
  input,
  label,
  tooltip,
  inline,
  size,
  mask,
  feedback,
  className,
  groupClassName = 'form-group',
  helpBlock,
  setValueToForm = true,
  meta: { touched, error, warning }
}) => {
  const changeHandler = e => {
    if (setValueToForm) {
      input.onChange(e.currentTarget.value);
    }
    if (typeof feedback === 'function') {
      feedback(e.currentTarget.value);
    }
  };

  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''} ${
    size ? ` form-group-${size}` : ''
  }`;
  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        <InputMask
          {...input}
          mask={mask}
          className={className}
          onChange={changeHandler}
        />

        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const phoneField = ({
  input,
  label,
  tooltip,
  inline,
  size,
  placeholder,
  readOnly,
  className,
  groupClassName = 'form-group',
  helpBlock,
  feedback,
  meta: { touched, error, warning }
}) => {
  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''} ${
    size ? ` form-group-${size}` : ''
  }`;
  const changeHandler = (valid, phone) => {
    console.log('PHONE', phone, valid);
    input.onChange(phone);
    if (typeof feedback === 'function') {
      feedback(phone);
    }
  };
  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        <PhoneInput
          {...input}
          // autoFormat={false}
          // onChange={changeHandler}
          // className={className}
          onPhoneNumberChange={changeHandler}
          nationalMode={false}
          format
          autoPlaceholder
          inputClassName={className}
          placeholder={placeholder}
          readOnly={readOnly}
        />

        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const colorField = ({
  input,
  label,
  tooltip,
  inline,
  placeholder,
  type,
  feedback,
  className,
  helpBlock,
  groupClassName = 'form-group',
  meta: { asyncValidating, touched, error, warning }
}) => {
  const changeHandler = e => {
    // console.log(e.currentTarget);
    input.onChange(e.currentTarget.value);
    if (typeof feedback === 'function') {
      feedback(e.currentTarget.value);
    }
  };

  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''} ${
    asyncValidating ? 'async-validating' : ''
  }`;

  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        <input
          {...input}
          onChange={changeHandler}
          className={className}
          placeholder={placeholder}
          type={type}
        />
        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const selectField = ({
  input,
  label,
  tooltip,
  inline,
  notSearchable,
  checkbox,
  id,
  className = 'react-select',
  groupClassName = 'form-group',
  helpBlock,
  feedback,
  options,
  placeholder,
  disabled,
  multiple,
  meta: { touched, error, warning }
}) => {
  const changeHandler = val => {
    let value = '';
    if (multiple) {
      value = map(val, 'value');
    } else {
      value = val ? val.value : val;
    }
    input.onChange(value);
    if (typeof feedback === 'function') {
      feedback(value);
    }
  };

  // const blurHandler = () => {
  //   input.onBlur(input.value);
  // };

  const getValue = () => {
    if (multiple) {
      return Array.isArray(input.value)
        ? options.filter(val => input.value.includes(val.value))
        : [];
    }
    return options.filter(val => val.value === input.value);
  };

  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''}`;

  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        {checkbox ? (
          <MultiSelectCheckboxes
            {...input}
            value={getValue()}
            isSearchable={!notSearchable}
            isDisabled={disabled}
            onChange={changeHandler}
            // onBlur={blurHandler}
            onBlur={event => event.preventDefault()}
            id={id}
            placeholderButtonLabel={placeholder}
            options={options}
            className={className}
            classNamePrefix={className}
          />
        ) : (
          <Select
            {...input}
            value={getValue()}
            isMulti={!!multiple}
            isSearchable={!notSearchable}
            isDisabled={disabled}
            onChange={changeHandler}
            // onBlur={blurHandler}
            onBlur={event => event.preventDefault()}
            id={id}
            placeholder={placeholder}
            options={options}
            className={className}
            classNamePrefix={className}
          />
        )}
        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const inputFieldControlled = ({
  input,
  label,
  tooltip,
  inline,
  placeholder,
  type,
  className,
  helpBlock,
  groupClassName = 'form-group',
  feedback,
  meta: { asyncValidating, touched, error, warning }
}) => {
  const changeHandler = e => {
    //  console.log('CHANGED', e.target.value);
    input.onChange(e.target.value);
    if (typeof feedback === 'function') {
      feedback(e.target.value);
    }
  };
  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''} ${
    asyncValidating ? 'async-validating' : ''
  }`;

  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        <input
          {...input}
          value={input.value}
          className={className}
          placeholder={placeholder}
          type={type}
          onChange={changeHandler}
        />
        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const radioField = ({
  input,
  label,
  tooltip,
  inline,
  options,
  className,
  groupClassName = 'form-group',
  isInline,
  helpBlock,
  feedback,
  meta: { touched, error, warning }
}) => {
  const changeHandler = e => {
    input.onChange(e.currentTarget.value);
    if (typeof feedback === 'function') {
      feedback(e.currentTarget.value);
    }
  };
  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''}`;

  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        {options.map(item => (
          <label
            className={`custom-control custom-radio ${isInline &&
              'custom-control-inline'}`}
            key={item.value}
          >
            <input
              type="radio"
              {...input}
              value={item.value}
              // eslint-disable-next-line eqeqeq
              checked={item.value == input.value}
              className={`custom-control-input ${className}`}
              onChange={changeHandler}
            />
            <div className="custom-control-label font-weight-normal">
              {item.label}
            </div>
          </label>
        ))}
        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const checkboxField = ({
  label,
  tooltip,
  inline,
  options,
  input,
  disabled = false,
  className,
  labelClass = '',
  isInline,
  groupClassName = 'form-group',
  helpBlock,
  feedback,
  meta: { touched, error, warning }
}) => {
  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''}`;

  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        {options.length > 1 ? (
          options.map((option, index) => (
            <label
              className={`custom-control custom-checkbox ${labelClass} ${
                isInline ? 'custom-control-inline' : ''
              }`}
              key={index}
            >
              <input
                type="checkbox"
                name={`${input.name}[${index}]`}
                className={`custom-control-input ${className}`}
                value={option.value}
                disabled={option.disable || disabled}
                checked={input.value.indexOf(option.value) !== -1}
                onChange={event => {
                  const newValue = [...input.value];
                  if (event.target.checked) {
                    newValue.push(option.value);
                  } else {
                    newValue.splice(newValue.indexOf(option.value), 1);
                  }
                  if (typeof feedback === 'function') {
                    feedback(newValue);
                  }
                  return input.onChange(newValue);
                }}
              />
              <div className="custom-control-label font-weight-normal">
                {option.label}
              </div>
            </label>
          ))
        ) : (
          <label
            className={`custom-control custom-checkbox ${labelClass} ${
              isInline ? 'custom-control-inline' : ''
            }`}
          >
            <input
              type="checkbox"
              {...input}
              disabled={disabled}
              checked={input.value}
              className={`custom-control-input ${className}`}
            />
            <div className="custom-control-label font-weight-normal">
              {options[0].label}
            </div>
          </label>
        )}
        {touched &&
          ((error && <div className="invalid-feedback d-block">{error}</div>) ||
            (warning && (
              <div className="invalid-feedback d-block">{warning}</div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const dateField = ({
  input,
  label,
  tooltip,
  inline,
  placeholder,
  className,
  format = 'DD/MM/YYYY',
  max = false,
  min = false,
  openDate = false,
  inputGroupClass = 'input-group',
  groupClassName = 'form-group',
  helpBlock,
  meta: { touched, error, warning }
}) => {
  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''}`;

  // const years = range(1900, moment().get('year') + 1, 1);
  // const months = [
  //   'January',
  //   'February',
  //   'March',
  //   'April',
  //   'May',
  //   'June',
  //   'July',
  //   'August',
  //   'September',
  //   'October',
  //   'November',
  //   'December'
  // ];

  const changeHandler = date => {
    // console.log('DATE', moment(date).format(format));
    input.onChange(moment(date).format(format));
  };

  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        <div className={inputGroupClass}>
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="mdi mdi-calendar" />
            </span>
          </div>
          <DatePicker
            {...input}
            className={className}
            placeholderText={placeholder}
            dateFormat={format}
            // selected={input.value ? moment(input.value) : null}
            onChange={changeHandler}
            maxDate={max}
            minDate={min}
            openToDate={openDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            // renderCustomHeader={(
            //   {
            //     date,
            //     changeYear,
            //     changeMonth,
            //     decreaseMonth,
            //     increaseMonth,
            //     prevMonthButtonDisabled,
            //     nextMonthButtonDisabled
            //   }
            // ) => (
            //   <div
            //     style={{
            //       margin: 10,
            //       display: 'flex',
            //       justifyContent: 'center'
            //     }}
            //   >
            //     <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            //       {'<'}
            //     </button>
            //     <select
            //       value={moment(date).get('year')}
            //       onChange={({ target: { value } }) => changeYear(value)}
            //     >
            //       {years.map(option => (
            //         <option key={option} value={option}>
            //           {option}
            //         </option>
            //       ))}
            //     </select>
            //
            //     <select
            //       value={months[moment(date).get('month')]}
            //       onChange={({ target: { value } }) => changeMonth(months.indexOf(value))
            //       }
            //     >
            //       {months.map(option => (
            //         <option key={option} value={option}>
            //           {option}
            //         </option>
            //       ))}
            //     </select>
            //
            //     <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            //       {'>'}
            //     </button>
            //   </div>
            // )}
          />
        </div>
        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const editorField = ({
  input,
  label,
  tooltip,
  inline,
  groupClassName = 'form-group',
  helpBlock,
  meta: { touched, error, warning }
}) => {
  const handleChangeEditor = (event, editor) => {
    const data = editor.getData();
    input.onChange(data);
  };

  const handleInitEditor = editor => {
    editor.setData(input.value);
  };

  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''}`;

  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: [
              'heading',
              '|',
              'bold',
              'italic',
              'bulletedList',
              'numberedList',
              '|',
              'undo',
              'redo'
            ]
          }}
          onInit={handleInitEditor}
          onChange={handleChangeEditor}
          events={{
            change: event => input.onChange(event.editor.getData())
          }}
        />
        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const imageField = ({
  input,
  id,
  containerClassName = 'form-group',
  label,
  tooltip,
  inline,
  btnLabel,
  removeLabel,
  helpBlock,
  preview,
  meta: { touched, error, warning }
}) => {
  const previewUrl = preview && preview.url ? preview.url : '';
  const previewWidth = preview && preview.width ? preview.width : 'inherit';
  const previewHeight = preview && preview.height ? preview.height : 'inherit';
  const [image, setimage] = useState(previewUrl);
  const [imageWidth, setImageWidth] = useState(previewWidth);
  const [imageHeight, setImageHeight] = useState(previewHeight);
  const handleChange = e => {
    input.onChange(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = event => {
      setimage(event.target.result);
      setImageWidth('inherit');
      setImageHeight('inherit');
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const reset = () => {
    setimage('');
    input.onChange('');
  };
  const newContainerClassName = `${containerClassName} ${inline ? ' row' : ''}`;

  return (
    <div className={newContainerClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        <div className="file-cloud">
          <input
            type="file"
            id={id}
            className="d-none"
            onChange={handleChange}
          />
          <div className={image && 'mb-2'}>
            {image && (
              <img
                src={image}
                style={{
                  maxWidth: '100%',
                  width: imageWidth,
                  height: imageHeight
                }}
                alt={label}
              />
            )}
          </div>
          {image ? (
            <button
              type="button"
              className="btn btn-light btn-sm mb-1"
              onClick={() => reset()}
            >
              {removeLabel || 'Remove'}
            </button>
          ) : (
            <label htmlFor={id} className="btn btn-light btn-sm cursor-pointer">
              {btnLabel || 'Browse'}
            </label>
          )}
        </div>
        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const textareaField = ({
  input,
  label,
  tooltip,
  inline,
  placeholder,
  feedback,
  className,
  groupClassName = 'form-group',
  helpBlock,
  meta: { asyncValidating, touched, error, warning }
}) => {
  const changeHandler = e => {
    input.onChange(e.currentTarget.value);
    if (typeof feedback === 'function') {
      feedback(e.currentTarget.value);
    }
  };
  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''} ${
    asyncValidating ? 'async-validating' : ''
  }`;

  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        <textarea
          {...input}
          onChange={changeHandler}
          className={className}
          placeholder={placeholder}
        />
        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

export const switchField = ({
  input,
  label,
  tooltip,
  inline,
  helpBlock,
  groupClassName = 'form-group',
  id,
  type, // bool, primary, success, info, warning, danger
  meta: { touched, error, warning }
}) => {
  const newGroupClassName = `${groupClassName} ${inline ? ' row' : ''}`;

  return (
    <div className={newGroupClassName}>
      {labelOutput({
        label,
        tooltip,
        inline,
        input
      })}
      <div className={inline && inline[1]}>
        <div>
          <input
            {...input}
            type="checkbox"
            checked={input.value}
            id={id}
            data-switch={type}
          />
          <label htmlFor={id} data-on-label="Yes" data-off-label="No" />
        </div>
        {touched &&
          ((error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )) ||
            (warning && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {warning}
              </div>
            )))}
        {helpBlock && <small className="help-block">{helpBlock}</small>}
      </div>
    </div>
  );
};

// input field
inputField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any
  }),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.string,
  feedback: PropTypes.func,
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  helpBlock: PropTypes.string,
  meta: PropTypes.shape({
    asyncValidating: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  })
};
inputField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: ''
  },
  label: '',
  tooltip: undefined,
  inline: undefined,
  size: undefined,
  placeholder: '',
  type: 'text type',
  maxLength: undefined,
  readOnly: undefined,
  feedback: undefined,
  className: '',
  groupClassName: 'form-group',
  helpBlock: undefined,
  meta: {
    asyncValidating: false,
    touched: false,
    error: undefined,
    warning: undefined
  }
};

// input Group Field
inputGroupField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any
  }),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.string,
  feedback: PropTypes.func,
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  helpBlock: PropTypes.string,
  meta: PropTypes.shape({
    asyncValidating: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  position: PropTypes.string
};
inputGroupField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: ''
  },
  label: '',
  tooltip: undefined,
  inline: undefined,
  size: undefined,
  placeholder: '',
  type: 'text type',
  maxLength: undefined,
  readOnly: undefined,
  feedback: undefined,
  className: '',
  groupClassName: 'form-group',
  helpBlock: undefined,
  meta: {
    asyncValidating: false,
    touched: false,
    error: undefined,
    warning: undefined
  },
  position: 'prepend'
};

// inputMaskfield
inputMaskField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any
  }),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.string,
  feedback: PropTypes.func,
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  helpBlock: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  position: PropTypes.string,
  mask: PropTypes.string,
  setValueToForm: PropTypes.bool
};
inputMaskField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: ''
  },
  label: '',
  tooltip: undefined,
  inline: undefined,
  size: undefined,
  placeholder: '',
  type: 'text type',
  maxLength: undefined,
  readOnly: undefined,
  feedback: undefined,
  className: '',
  groupClassName: 'form-group',
  helpBlock: undefined,
  meta: {
    touched: false,
    error: undefined,
    warning: undefined
  },
  position: 'prepend',
  mask: undefined,
  setValueToForm: true
};

// PhoneField
phoneField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any
  }),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.string,
  feedback: PropTypes.func,
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  helpBlock: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  position: PropTypes.string,
  mask: PropTypes.string,
  setValueToForm: PropTypes.bool
};
phoneField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: ''
  },
  label: '',
  tooltip: undefined,
  inline: undefined,
  size: undefined,
  placeholder: '',
  type: 'text type',
  maxLength: undefined,
  readOnly: undefined,
  feedback: undefined,
  className: '',
  groupClassName: 'form-group',
  helpBlock: undefined,
  meta: {
    touched: false,
    error: undefined,
    warning: undefined
  },
  position: 'prepend',
  mask: undefined,
  setValueToForm: true
};

// colourField
colorField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any
  }),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.string,
  feedback: PropTypes.func,
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  helpBlock: PropTypes.string,
  meta: PropTypes.shape({
    asyncValidating: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  position: PropTypes.string,
  mask: PropTypes.string,
  setValueToForm: PropTypes.bool
};
colorField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: ''
  },
  label: '',
  tooltip: undefined,
  inline: undefined,
  size: undefined,
  placeholder: '',
  type: 'text type',
  maxLength: undefined,
  readOnly: undefined,
  feedback: undefined,
  className: '',
  groupClassName: 'form-group',
  helpBlock: undefined,
  meta: {
    asyncValidating: undefined,
    touched: false,
    error: undefined,
    warning: undefined
  },
  position: 'prepend',
  mask: undefined,
  setValueToForm: true
};

// Selcet Field
selectField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any
  }),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.string,
  feedback: PropTypes.func,
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  helpBlock: PropTypes.string,
  meta: PropTypes.shape({
    asyncValidating: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  position: PropTypes.string,
  mask: PropTypes.string,
  setValueToForm: PropTypes.bool,
  notSearchable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  checkbox: PropTypes.bool,
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
  multiple: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
};
selectField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: ''
  },
  label: '',
  tooltip: undefined,
  inline: undefined,
  size: undefined,
  placeholder: '',
  type: 'text type',
  maxLength: undefined,
  readOnly: undefined,
  feedback: undefined,
  className: 'react-select',
  groupClassName: 'form-group',
  helpBlock: undefined,
  meta: {
    asyncValidating: undefined,
    touched: false,
    error: undefined,
    warning: undefined
  },
  position: 'prepend',
  mask: undefined,
  setValueToForm: true,
  notSearchable: undefined,
  checkbox: undefined,
  id: '',
  options: [],
  disabled: false,
  multiple: undefined
};

// inputFieldControlled
inputFieldControlled.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any
  }),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.string,
  feedback: PropTypes.func,
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  helpBlock: PropTypes.string,
  meta: PropTypes.shape({
    asyncValidating: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  position: PropTypes.string,
  mask: PropTypes.string,
  setValueToForm: PropTypes.bool
};
inputFieldControlled.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: ''
  },
  label: '',
  tooltip: undefined,
  inline: undefined,
  size: undefined,
  placeholder: '',
  type: 'text type',
  maxLength: undefined,
  readOnly: undefined,
  feedback: undefined,
  className: '',
  groupClassName: 'form-group',
  helpBlock: undefined,
  meta: {
    asyncValidating: undefined,
    touched: false,
    error: undefined,
    warning: undefined
  },
  position: 'prepend',
  mask: undefined,
  setValueToForm: true
};

// radion field
radioField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any
  }),
  feedback: PropTypes.func,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  helpBlock: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  isInline: PropTypes.bool
};
radioField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: undefined
  },
  label: '',
  tooltip: undefined,
  inline: undefined,
  className: '',
  groupClassName: 'form-group',
  helpBlock: undefined,
  meta: {
    touched: false,
    error: undefined,
    warning: undefined
  },
  options: [],
  feedback: undefined,
  isInline: true
};

// checkBox Field
checkboxField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string
  }),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  feedback: PropTypes.func,
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  helpBlock: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  options: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
  labelClass: PropTypes.string,
  isInline: PropTypes.bool
};
checkboxField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: ''
  },
  label: '',
  tooltip: undefined,
  inline: undefined,
  feedback: undefined,
  className: '',
  groupClassName: 'form-group',
  helpBlock: undefined,
  meta: {
    touched: false,
    error: undefined,
    warning: undefined
  },
  options: [],
  disabled: false,
  labelClass: '',
  isInline: true
};

// data field
dateField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string
  }),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  helpBlock: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  options: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
  labelClass: PropTypes.string,
  isInline: PropTypes.bool,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  max: PropTypes.bool,
  min: PropTypes.bool,
  openDate: PropTypes.bool,
  inputGroupClass: PropTypes.string
};

dateField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: ''
  },
  label: '',
  tooltip: undefined,
  inline: undefined,
  className: '',
  groupClassName: 'form-group',
  helpBlock: undefined,
  meta: {
    touched: false,
    error: undefined,
    warning: undefined
  },
  options: [],
  disabled: false,
  labelClass: '',
  isInline: true,
  placeholder: '',
  format: 'DD/MM/YYYY',
  max: false,
  min: false,
  openDate: false,
  inputGroupClass: 'input-group'
};

// editor Field
editorField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string
  }),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  groupClassName: PropTypes.string,
  helpBlock: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  })
};
editorField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: ''
  },
  label: '',
  tooltip: undefined,
  inline: undefined,
  groupClassName: 'form-group',
  helpBlock: undefined,
  meta: {
    touched: false,
    error: undefined,
    warning: undefined
  }
};

// imageField
imageField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any
  }),
  id: PropTypes.string,
  containerClassName: PropTypes.string,
  btnLabel: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  helpBlock: PropTypes.string,
  removeLabel: PropTypes.string,
  preview: PropTypes.node,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  })
};
imageField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: {}
  },
  id: 'profilePic',
  containerClassName: 'form-group',
  btnLabel: 'Browse Profile Photo btnLabel',
  label: '',
  tooltip: undefined,
  inline: undefined,
  helpBlock: undefined,
  removeLabel: 'Remove Photo',
  preview: undefined,
  meta: {
    touched: false,
    error: undefined,
    warning: undefined
  }
};

// textArea Field
textareaField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any
  }),
  placeholder: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  groupClassName: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  helpBlock: PropTypes.string,
  removeLabel: PropTypes.string,
  preview: PropTypes.string,
  feedback: PropTypes.func,
  meta: PropTypes.shape({
    asyncValidating: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  })
};
textareaField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: {}
  },
  feedback: undefined,
  placeholder: '',
  id: 'profilePic',
  className: '',
  label: '',
  groupClassName: 'form-group',
  tooltip: undefined,
  inline: undefined,
  helpBlock: undefined,
  removeLabel: 'Remove Photo',
  preview: undefined,
  meta: {
    asyncValidating: false,
    touched: false,
    error: undefined,
    warning: undefined
  }
};

// switch Field
switchField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any
  }),
  type: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  groupClassName: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.string,
  helpBlock: PropTypes.string,
  meta: PropTypes.shape({
    asyncValidating: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  })
};
switchField.defaultProps = {
  input: {
    name: 'address_line1',
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value: {}
  },
  id: 'profilePic',
  label: '',
  groupClassName: 'form-group',
  tooltip: undefined,
  inline: undefined,
  helpBlock: undefined,
  type: false,
  meta: {
    touched: false,
    error: undefined,
    warning: undefined
  }
};
