/* eslint-disable no-restricted-globals */
/* global location */

import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import qs from 'qs';
import StorageService from '../services/storageService';
import {
  shop as shopActions,
  cart as cartActions,
  app as appActions
} from '../actions';
import routePath from '../constants/routePath';
import history from '../services/historyService';

function ShopHelper({ addToCart, setShopCountry, setShopState, cartId }) {
  // Store addtocart params in storage
  useMemo(() => {
    const qParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (qParams && qParams.atc) {
      StorageService.setAddToCart({
        id: qParams.atc,
        country: qParams.C,
        state: qParams.S
      });
    }
  }, []);

  useEffect(() => {
    const qParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (qParams && qParams.atc) {
      StorageService.setAddToCart({
        id: qParams.atc,
        country: qParams.C,
        state: qParams.S
      });
    }
  }, []);

  // Process Auto Add to Cart
  useEffect(() => {
    const pObj = StorageService.getAddToCart();
    if (pObj && cartId) {
      addToCart({
        cart_id: cartId,
        product_id: pObj.id,
        quantity: 1,
        country: pObj.country,
        state: pObj.state
      });
      StorageService.unsetAddToCart();

      // Set shop country & state
      if (pObj.country) {
        setShopCountry(pObj.country);
        StorageService.setShopCountry(pObj.country);
      }
      if (pObj.state) {
        setShopState(pObj.state);
        StorageService.setShopState(pObj.state);
      }

      // Redirect to shop
      history.replace(routePath.shop);
    } else {
      // eslint-disable-next-line no-restricted-globals
      const qParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (qParams && qParams.atc && cartId) {
        addToCart({
          cart_id: cartId,
          product_id: qParams.atc,
          quantity: 1,
          country: qParams.C,
          state: qParams.S
        });
        StorageService.unsetAddToCart();

        // Set shop country & state
        if (qParams.C) {
          setShopCountry(qParams.C);
          StorageService.setShopCountry(qParams.C);
        }
        if (qParams.S) {
          setShopState(qParams.S);
          StorageService.setShopState(qParams.S);
        }

        // Redirect to shop
        history.replace(routePath.shop);
      }
    }
  }, [cartId]);

  return null;
}

const mapStateToProps = state => ({
  cartId: state.cart.cartId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProductDetails: shopActions.getProductDetails,
      addToCart: cartActions.addToCart,
      setShopCountry: appActions.setShopCountry,
      setShopState: appActions.setShopState
    },
    dispatch
  );
ShopHelper.defaultProps = {
  cartId: undefined,
  addToCart: () => {}
};

ShopHelper.propTypes = {
  cartId: PropTypes.number,
  addToCart: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopHelper);
