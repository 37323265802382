const order = {
  ORDER_ALL_LIST_REQUEST: 'ORDER_ALL_LIST_REQUEST',
  ORDER_ALL_LIST_SUCCESS: 'ORDER_ALL_LIST_SUCCESS',
  ORDER_ALL_LIST_ERROR: 'ORDER_ALL_LIST_ERROR',
  ORDER_REQUEST: 'ORDER_REQUEST',
  ORDER_SUCCESS: 'ORDER_SUCCESS',
  ORDER_ERROR: 'ORDER_ERROR',
  ORDER_TNX_REQUEST: 'ORDER_TNX_REQUEST',
  ORDER_TNX_SUCCESS: 'ORDER_TNX_SUCCESS',
  getAllOrders: data => ({
    type: order.ORDER_ALL_LIST_REQUEST,
    data
  }),
  getOrderById: id => ({ type: order.ORDER_REQUEST, id }),
  getOrderByTnxId: id => ({ type: order.ORDER_TNX_REQUEST, id })
};

export default order;
