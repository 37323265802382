import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import SetDefaultButton from './SetDefaultButton';
import { address as addressActions } from '../../actions';

function AddressItem({ address, showModal, showAlert, grid }) {
  function handleEdit() {
    let city = '';
    let otherCity = '';
    if (address.city_details) {
      city = address.city_details.id;
    } else if (address.city) {
      city = 'others';
      otherCity = address.city;
    }
    showModal({
      id: address.id,
      country: address.country ? address.country.iso2 : '',
      type: address.type,
      name: address.name,
      mobile_number: `+${address.mobile_number}`,
      postcode: address.postcode,
      street_name_number: address.street_name_number,
      address_line1: address.address_line1,
      address_line2: address.address_line2,
      state: address.state ? address.state.id : '',
      city,
      otherCity,
      location_type: address.location_type,
      is_default: address.is_default
    });
  }

  function gridClass() {
    switch (grid) {
      case 4:
        return 'col-sm-6 col-md-3 mb-3';
      case 3:
        return 'col-sm-6 col-md-4 mb-3';
      case 2:
        return 'col-sm-6 mb-3';
      case 1:
        return 'col-sm-12 mb-3';
      default:
        return 'col-sm-6 col-md-3 mb-3';
    }
  }

  return (
    <div className={gridClass()}>
      <div className="card border border-primary h-100 mb-0">
        <div className="card-body">
          <h5 className="card-title text-truncate">{address.name}</h5>
          <p className="card-text font-12 mb-1">
            {address.street_name_number}
            <br />
            {address.address_line1}
            <br />
            {address.address_line2 ? (
              <>
                {address.address_line2}
                <br />
              </>
            ) : null}
            {address.postcode}
            <br />
            {address.city || address.city_details ? (
              <>
                {address.city_details
                  ? address.city_details.name
                  : address.city}
                <br />
              </>
            ) : null}
            {address.state ? address.state.name : ''}
            <br />
            {address.country.name || address.country}
          </p>
          <div className=" font-12 text-uppercase mb-2">
            {address.is_default === 1 ? (
              <span className="badge badge-primary mr-1">Default</span>
            ) : (
              ''
            )}
            <span
              className={`badge mr-1 badge-${
                address.location_type === 'HOME' ? 'warning' : 'info'
              }`}
            >
              {address.location_type}
            </span>
            <span
              className={`badge badge-${
                address.type === 'BILLING' ? 'success' : 'dark'
              }`}
            >
              {address.type}
            </span>
          </div>
          <button
            type="button"
            className="btn btn-link p-0 card-link text-custom font-12"
            onClick={handleEdit}
          >
            Edit
          </button>
          <button
            type="button"
            className="btn btn-link p-0 card-link text-custom font-12"
            onClick={() => showAlert(address)}
          >
            Delete
          </button>
          {address.is_default !== 1 ? (
            <SetDefaultButton id={address.id} />
          ) : null}
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showModal: addressActions.showModal,
      showAlert: addressActions.showAlert
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(AddressItem);

AddressItem.defaultProps = {
  address: {},
  showModal: () => {},
  showAlert: () => {},
  grid: undefined
};

AddressItem.propTypes = {
  address: PropTypes.objectOf(PropTypes.any),
  showModal: PropTypes.func,
  showAlert: PropTypes.func,
  grid: PropTypes.string
};
