import { auth as authActionTypes } from '../actions';
import StorageService from '../services/storageService';
import initialState from '../constants/initialState';

function auth(state = initialState.auth, action) {
  switch (action.type) {
    case authActionTypes.LOGIN_REQUEST:
    case authActionTypes.AFFILIATE_LOGIN_REQUEST:
      return {
        ...state,
        loginProgress: true,
        loginError: false
      };
    case authActionTypes.LOGIN_SUCCESS:
      if (action.data) {
        StorageService.setToken(action.data.access_token);
        StorageService.setRefreshToken(action.data.refresh_token);
      }
      return {
        ...state,
        loginProgress: false
      };
    case authActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginProgress: false,
        loginError: true
      };
    case authActionTypes.REGISTER_REQUEST:
      return {
        ...state,
        registrationProgress: true
      };
    case authActionTypes.REGISTER_SUCCESS:
      if (action.data) {
        StorageService.setToken(action.data.access_token);
        StorageService.setRefreshToken(action.data.refresh_token);
      }
      return {
        ...state,
        registrationProgress: false
      };
    case authActionTypes.REGISTER_ERROR:
      return {
        ...state,
        registrationProgress: false
      };
    case authActionTypes.REF_USER_ID_SUCCESS:
      // window.sessionStorage.setItem('refUUId', action.data.uuid);
      return {
        ...state,
        refUserId: action.data.uuid,
        refUserChecked: true
      };
    case authActionTypes.REF_USER_ID_ERROR:
      return {
        ...state,
        refUserId: '',
        refUserChecked: true
      };
    case authActionTypes.LOGOUT_SUCCESS:
      if (action.data) {
        StorageService.clearStorage();
      }
      return initialState.auth;
    case authActionTypes.USER_INFO_REQUEST:
      return {
        ...state,
        userInfoProgress: true
      };
    case authActionTypes.USER_INFO_SUCCESS:
      if (action.data) {
        return {
          ...state,
          user: action.data,
          userInfoProgress: false
        };
      }
      return {
        ...state,
        userInfoProgress: false
      };
    case authActionTypes.USER_INFO_ERROR:
      return {
        ...state,
        userInfoProgress: false
      };
    case authActionTypes.SUBSCRIPTION_SUCCESS:
      if (action.data) {
        return {
          ...state,
          user: { ...state.user, subscription: action.data },
          userInfoProgress: false
        };
      }
      return {
        ...state,
        userInfoProgress: false
      };
    case authActionTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordProgress: true
      };
    case authActionTypes.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordProgress: false
      };
    case authActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: true,
        forgotPasswordProgress: false
      };

    case authActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordProgress: true
      };
    case authActionTypes.RESET_PASSWORD_SUCCESS:
    case authActionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordProgress: false
      };

    case authActionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordProgress: true
      };
    case authActionTypes.CHANGE_PASSWORD_SUCCESS:
      if (action.data) {
        StorageService.setToken(action.data);
      }
      return {
        ...state,
        changePasswordProgress: false
      };
    case authActionTypes.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordProgress: false
      };
    default:
      return state;
  }
}

export default auth;
