import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { inputGroupField } from './Fields';

const ShowHidePassField = props => {
  const { name, label, validate, feedback, placeholder } = props;
  const [showEye, setShowEye] = useState(true);
  const showPassword = () => {
    setShowEye(!showEye);
  };
  return (
    <Field
      name={name}
      component={inputGroupField}
      type={!showEye ? 'text' : 'password'}
      className="form-control w-100"
      groupClassName="form-group login-form-group"
      label={label}
      validate={validate}
      feedback={feedback}
      placeholder={placeholder}
    >
      <button
        type="button"
        onClick={showPassword}
        className="btn btn-icon btn-sm position-absolute"
      >
        <i
          className={
            showEye ? 'action-icon mdi mdi-eye' : 'action-icon mdi mdi-eye-off'
          }
        />
      </button>
    </Field>
  );
};

export default ShowHidePassField;

ShowHidePassField.defaultProps = {
  name: '',
  label: '',
  validate: [],
  feedback: () => {},
  placeholder: ''
};

ShowHidePassField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.any),
  feedback: PropTypes.func,
  placeholder: PropTypes.string
};
