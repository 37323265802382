import { reducer as formReducer } from 'redux-form';
import app from './app';
import auth from './auth';
import associate from './associate';
import tree from './tree';
import packages from './package';
import permission from './permission';
import category from './category';
import product from './product';
import generation from './generation';
import rank from './rank';
import fsb from './fsb';
import tax from './tax';
import address from './address';
import profile from './profile';
import paymentOption from './paymentOption';
import template from './template';
import shop from './shop';
import cart from './cart';
import shipping from './shipping';
import setting from './setting';
import media from './media';
import location from './location';
import associateFsb from './associateFsb';
import order from './order';
import checkout from './checkout';
import dashboard from './dashboard';

export default {
  app,
  auth,
  associate,
  tree,
  packages,
  permission,
  category,
  product,
  generation,
  rank,
  fsb,
  tax,
  address,
  profile,
  paymentOption,
  template,
  shop,
  cart,
  shipping,
  setting,
  media,
  location,
  associateFsb,
  order,
  checkout,
  form: formReducer,
  dashboard
};
