import { get } from 'lodash';
import axios from 'axios';
import { sentenceCase } from './custom';

const getURLObject = () => window.URL || window.webkitURL;

const click = node => {
  try {
    node.dispatchEvent(new MouseEvent('click'));
  } catch (e) {
    const evt = document.createEvent('MouseEvents');
    evt.initMouseEvent(
      'click',
      true,
      true,
      window,
      0,
      0,
      0,
      80,
      20,
      false,
      false,
      false,
      false,
      0,
      null
    );
    node.dispatchEvent(evt);
  }
};

export async function ajax(options) {
  try {
    const responseJson = await axios(options);
    return responseJson;
  } catch (error) {
    return Promise.reject(error);
  }
}

export function getErrorMessage(response) {
  const error = get(response, 'data', {});
  if (response.status === 422) {
    const errorMessage = [];
    Object.keys(error).forEach(item => {
      if (Array.isArray(error[item])) {
        errorMessage.push(sentenceCase(error[item][0]));
      }
    });
    return errorMessage.join(', ');
  }
  if (error.message) {
    return error.message;
  }
  return 'Something went wrong!';
}

export function getFileName(response, defaultFileName = '') {
  let fileName = defaultFileName;
  const disposition = get(response, 'headers.content-disposition', '');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
  }

  // through error if no name found
  if (!fileName) {
    throw new Error('Make sure you have passed a default file name');
  }

  return fileName;
}

export function downloadBlob(response, defaultFileName = '') {
  const type = get(response, 'headers.content-type', '');
  const url = getURLObject().createObjectURL(
    new Blob([response.data], { type })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', getFileName(response, defaultFileName));
  document.body.appendChild(link);
  click(link);
}

export function createForm(data) {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', data.url);

  // Create an input element
  Object.keys(data.form).forEach(key => {
    const elm = document.createElement('input');
    elm.setAttribute('type', 'hidden');
    elm.setAttribute('name', key);
    elm.setAttribute('value', data.form[key]);
    form.append(elm);
  });

  document.getElementsByTagName('body')[0].appendChild(form);
  form.submit();
}
