import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { downloadBlob } from '../../utills/ajax';

function SingleDownloadButton({ id, invoice, type }) {
  const handleSingleDownload = useCallback(async () => {
    const url =
      type === 'order'
        ? `${process.env.REACT_APP_API_URL}/product/invoice`
        : `${process.env.REACT_APP_API_URL}/subscription/invoice`;
    const response = await axios({
      url,
      method: 'GET',
      params: { id },
      headers: { Accept: 'application/pdf' },
      responseType: 'blob'
    });
    downloadBlob(response, `${moment().format('DDMMYYYY')}-${invoice}.pdf`);
  }, [id, type]);

  return (
    <button
      type="button"
      onClick={handleSingleDownload}
      className="btn btn-icon btn-sm"
    >
      <i className="action-icon mdi mdi-file-pdf" />
    </button>
  );
}

export default SingleDownloadButton;

SingleDownloadButton.defaultProps = {
  id: undefined,
  invoice: undefined,
  type: ''
};

SingleDownloadButton.propTypes = {
  id: PropTypes.number,
  invoice: PropTypes.number,
  type: PropTypes.string
};
