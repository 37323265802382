import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  app as appActionTypes,
  associate as associateActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAssociates() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/associate`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: associateActionTypes.ASSOCIATE_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* createAssociate(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/user/add`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Associate was created successfully'
    });
    if (responseJson) {
      yield put({
        type: associateActionTypes.PRODUCT_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.associate.path
      });
    }
  } catch (error) {
    yield put({ type: associateActionTypes.PRODUCT_CREATE_ERROR, data: error });
  }
}

function* watchAssociateList() {
  yield takeLatest(associateActionTypes.ASSOCIATE_LIST_REQUEST, getAssociates);
}

function* watchAssociateCreate() {
  yield takeLatest(
    associateActionTypes.ASSOCIATE_CREATE_REQUEST,
    createAssociate
  );
}

export default [fork(watchAssociateList), fork(watchAssociateCreate)];
