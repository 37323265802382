import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import CartItem from './CartItem';
import InnerLoader, { OverlayLoader } from '../../components/InnerLoader';
import { cart as cartActions } from '../../actions';
import { getCustomerProductPrice } from '../../utills/custom';
import ShippingOptions from './ShippingOptions';
import ApplyCoupon from './ApplyCoupon';

function OrderSummary({
  cartDetails,
  getCartTotalDetails,
  shippingAddress,
  cartCalculation,
  countryCurrencies,
  dispatch,
  calculationProgress,
  displayOnly,
  shippingMode,
  storePickup,
  couponCode
}) {
  const items = [];
  if (cartCalculation.cp_cart_items) {
    cartCalculation.cp_cart_items.forEach(element => {
      items.push({
        id: element.item.id,
        use_cpoint: true,
        quantity: element.quantity_covered
      });
    });
  }

  useEffect(() => {
    if (!displayOnly) {
      getCartTotalDetails({
        cart_id: cartDetails.id,
        address_id: shippingAddress,
        cp_cart_items: items,
        pickup_from_store: storePickup,
        shipping_mode: shippingMode,
        coupon_code: couponCode
      });
    }
  }, [shippingMode, shippingAddress, storePickup, couponCode]);

  function handleCpointChecbox(data) {
    let newState =
      data.use_cpoint === true
        ? items.concat(data)
        : items.filter(item => item.id !== data.id);
    newState = Array.from(
      new Set(
        newState
          .slice(0)
          .reverse()
          .map(x => x.id)
      )
    ).map(id => {
      return {
        id,
        quantity: newState
          .slice(0)
          .reverse()
          .find(cp => cp.id === id).quantity
      };
    });
    getCartTotalDetails({
      cart_id: cartDetails.id,
      address_id: shippingAddress,
      cp_cart_items: newState,
      pickup_from_store: storePickup,
      shipping_mode: shippingMode,
      coupon_code: couponCode
    });

    dispatch(change('checkoutForm', 'cp_cart_items', newState));
  }

  const currency = countryCurrencies[cartDetails.country]
    ? countryCurrencies[cartDetails.country].currency_symbol
    : '';

  return (
    <div>
      {!isEmpty(cartCalculation) && (
        <div className="mb-2 border px-3 py-2 rounded font-weight-bold text-left">
          <div className="d-inline">
            {cartCalculation.cp_cart_items.length > 0
              ? 'REMAINING C-POINT'
              : 'AVAILABLE C-POINT'}
            :
          </div>
          <div className="d-inline float-right">
            {cartCalculation.available_cpoint
              ? cartCalculation.available_cpoint
              : '0.00'}
          </div>
        </div>
      )}
      <div className="border p-3 mt-4 mt-lg-0 rounded">
        <h4 className="header-title mb-3">Order Summary</h4>

        {cartDetails.items.map(product => (
          <CartItem
            key={`cart_item_${product.id}`}
            data={product}
            currency={currency}
            handleCpoint={handleCpointChecbox}
            cartCalculation={cartCalculation}
            checkedValue={
              parseFloat(cartCalculation.available_cpoint) <
              parseFloat(product.cpoint)
            }
          />
        ))}
        {calculationProgress && isEmpty(cartCalculation) ? (
          <InnerLoader type="table" />
        ) : (
          <>
            <div className="table-responsive position-relative">
              {calculationProgress ? <OverlayLoader type="spinner" /> : null}
              <table className="table table-summary mb-0">
                <tbody>
                  {!isEmpty(cartCalculation) ? (
                    <>
                      <tr>
                        <td>Sub Total:</td>
                        <td className="text-right">
                          {`${currency}${cartCalculation.subtotal}`}
                        </td>
                      </tr>

                      <ApplyCoupon
                        cartId={cartDetails.id}
                        couponDetails={cartCalculation.coupon_details}
                      />

                      <ShippingOptions
                        cartId={cartDetails.id}
                        currency={currency}
                      />
                      {cartCalculation.tax_details &&
                        cartCalculation.tax_details.map((taxItem, index) => (
                          <tr key={`tax_xlass${index}`}>
                            <td>{`${taxItem.name} (${taxItem.tax_rate}%):`}</td>
                            <td className="text-right">{`${currency}${taxItem.amount}`}</td>
                          </tr>
                        ))}
                      <tr className="border-top font-weight-bold">
                        <td>Grand Total: </td>
                        <td className="text-right">
                          {`${currency}${cartCalculation.total_amount}`}
                        </td>
                      </tr>
                      {cartCalculation.cp_cart_items.length > 0 && (
                        <>
                          <tr className="text-danger font-weight-bold border-top">
                            <td colSpan="2">C-POINT SUMMARY</td>
                          </tr>
                          {cartCalculation.cp_cart_items.map((cp, key) => (
                            <tr
                              className="font-11 border-top border-bottom"
                              key={key}
                            >
                              <td>
                                {cp.item.product.title}
                                <div className="mt-1">
                                  {` (${
                                    cp.quantity_covered
                                  } x ${currency}${getCustomerProductPrice(
                                    cp.item.product,
                                    true
                                  )})`}
                                </div>

                                <div className="mt-1 badge badge-danger">
                                  C-POINT: {cp.cp_used}
                                </div>
                              </td>
                              <td className="text-right text-right text-danger">
                                {currency}
                                {parseFloat(
                                  cp.quantity_covered *
                                    getCustomerProductPrice(
                                      cp.item.product,
                                      true
                                    )
                                ).toFixed(2)}
                              </td>
                            </tr>
                          ))}
                          <tr className="font-11">
                            <td>USED C-POINT:</td>
                            <td className="text-right text-danger">
                              {cartCalculation.cpoint_used}
                            </td>
                          </tr>
                          {cartCalculation.cp_tax_details.map((tax, key) => (
                            <tr className="font-11" key={key}>
                              <td> {`${tax.name} (${tax.tax_rate}%):`}</td>
                              <td className="text-right text-danger">
                                {currency}
                                {parseFloat(tax.amount).toFixed(2)}
                              </td>
                            </tr>
                          ))}
                          <tr className="font-11 font-weight-bold">
                            <td>Total CP Amount</td>
                            <td className="text-right text-danger">
                              {currency}
                              {cartCalculation.total_cp_amount_with_vat}
                            </td>
                          </tr>
                        </>
                      )}
                      {cartCalculation.coupon_details &&
                      cartCalculation.coupon_details.coupon_id ? (
                        <tr className="font-11 font-weight-bold">
                          <td className="text-danger">Discount</td>

                          <td className="text-right text-danger">
                            {`${currency}${cartCalculation.coupon_details.discount_amount}`}
                          </td>
                        </tr>
                      ) : null}
                      {Number(cartCalculation.total_amount) >
                      Number(cartCalculation.total_payable_amount) ? (
                        <tr>
                          <th>Net Payable Amount: </th>
                          <th className="text-right">
                            {`${currency}${cartCalculation.total_payable_amount}`}
                          </th>
                        </tr>
                      ) : null}
                    </>
                  ) : null}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const selector = formValueSelector('checkoutForm');

const mapStateToProps = state => ({
  cartDetails: state.cart.cartDetails,
  cartCalculation: state.cart.cartCalculation,
  calculationProgress: state.cart.calculationProgress,
  countryCurrencies: state.app.countryCurrencies,
  shippingAddress: selector(state, 'shipping_address_id'),
  storePickup: selector(state, 'pickup_from_store'),
  shippingMode: selector(state, 'shipping_mode'),
  couponCode: selector(state, 'coupon_code')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCartDetails: cartActions.getCartDetails,
      getCartTotalDetails: cartActions.getCartTotalDetails,
      dispatch: action => action
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSummary);

OrderSummary.propTypes = {
  getCartTotalDetails: PropTypes.func.isRequired,
  shippingAddress: PropTypes.number,
  cartCalculation: PropTypes.shape({
    shipping_charges: PropTypes.number,
    shipping_charges_title: PropTypes.string,
    shipping_tax_amount: PropTypes.string,
    subtotal: PropTypes.string,
    subtotalProduct: PropTypes.string,
    tax_details: PropTypes.array,
    tax_name: PropTypes.array,
    total: PropTypes.number,
    total_amount: PropTypes.string,
    total_bp: PropTypes.string,
    total_tax_amount: PropTypes.string,
    total_tax_percentage: PropTypes.number,
    total_tax_product: PropTypes.string
  }),
  cartDetails: PropTypes.shape({
    created_at: PropTypes.string,
    id: PropTypes.number,
    items: PropTypes.array,
    status: PropTypes.number,
    updated_at: PropTypes.string,
    user_id: PropTypes.number
  }),
  calculationProgress: PropTypes.bool,
  displayOnly: PropTypes.bool,
  couponCode: PropTypes.string
};

OrderSummary.defaultProps = {
  shippingAddress: undefined,
  cartCalculation: {},
  cartDetails: {},
  calculationProgress: false,
  displayOnly: false,
  couponCode: ''
};
