import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  address as addressActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllAddress() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/address/user`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: addressActionTypes.ADDRESS_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAddress(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/address/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: addressActionTypes.ADDRESS_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* createAddress(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/address/add`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Address was added successfully'
    });
    if (responseJson) {
      yield put({
        type: addressActionTypes.ADDRESS_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: addressActionTypes.ADDRESS_HIDE_MODAL
      });
      yield put({
        type: addressActionTypes.ADDRESS_ALL_LIST_REQUEST
      });
    }
  } catch (error) {
    yield put({ type: addressActionTypes.ADDRESS_CREATE_ERROR, data: error });
  }
}

function* updateAddress(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/address/${actions.data.id}/edit`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Address updated successfully'
    });
    if (responseJson) {
      yield put({
        type: addressActionTypes.ADDRESS_UPDATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: addressActionTypes.ADDRESS_HIDE_MODAL,
        data: responseJson
      });
      yield put({
        type: addressActionTypes.ADDRESS_ALL_LIST_REQUEST
      });
    }
  } catch (error) {
    yield put({ type: addressActionTypes.ADDRESS_UPDATE_ERROR, data: error });
  }
}

function* deleteAddress(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/address/${actions.id}/delete`,
      method: 'DELETE',
      successMessage: 'Address deleted successfully'
    });
    if (responseJson) {
      yield put({
        type: addressActionTypes.ADDRESS_DELETE_SUCCESS,
        id: actions.id
      });
      yield put({
        type: addressActionTypes.ADDRESS_HIDE_ALERT,
        id: actions.id
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.address.path
        });
      }
    }
  } catch (error) {
    yield put({ type: addressActionTypes.ADDRESS_DELETE_ERROR, data: error });
  }
}

function* watchPackageListAll() {
  yield takeLatest(addressActionTypes.ADDRESS_ALL_LIST_REQUEST, getAllAddress);
}

function* watchGetAddress() {
  yield takeLatest(addressActionTypes.ADDRESS_REQUEST, getAddress);
}

function* watchAddressCreate() {
  yield takeLatest(addressActionTypes.ADDRESS_CREATE_REQUEST, createAddress);
}

function* watchAddressUpdate() {
  yield takeLatest(addressActionTypes.ADDRESS_UPDATE_REQUEST, updateAddress);
}

function* watchAddressDelete() {
  yield takeLatest(addressActionTypes.ADDRESS_DELETE_REQUEST, deleteAddress);
}

export default [
  fork(watchPackageListAll),
  fork(watchAddressCreate),
  fork(watchAddressUpdate),
  fork(watchAddressDelete),
  fork(watchGetAddress)
];
