import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  profile as profileActionTypes,
  auth as authActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* checkAvailability(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/user/check-referral-link`,
      method: 'POST',
      data: { ref_url: actions.name }
    });
    if (responseJson) {
      if (responseJson.message) {
        yield put({
          type: profileActionTypes.SET_REFERRAL_PROFILE,
          status: true
        });
      } else {
        yield put({
          type: profileActionTypes.SET_REFERRAL_PROFILE,
          status: false
        });
        const firstError = responseJson.ref_url[0];
        yield put({
          type: profileActionTypes.PROFILE_NAME_AVAILABILITY_ERROR,
          error: firstError
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getProfile() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/customer/user-general-info`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: profileActionTypes.PROFILE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateProfile(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/customer/update-profile/${actions.data.id}`,
      method: 'PUT',
      data: actions.data,
      successMessage: 'Profile updated successfully.'
    });
    if (responseJson) {
      yield put({
        type: profileActionTypes.PROFILE_UPDATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: authActionTypes.USER_INFO_REQUEST
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.profile.path
        });
      }
    }
  } catch (error) {
    yield put({ type: profileActionTypes.PROFILE_UPDATE_ERROR, data: error });
  }
}

function* updateProfileImage(actions) {
  try {
    let responseJson = {};
    if (actions.data && actions.data.deletePic) {
      responseJson = yield axios({
        url: `${process.env.REACT_APP_API_URL}/user/delete-profile-pic`,
        method: 'DELETE',
        successMessage: 'Profile image deleted successfully.'
      });
    } else {
      responseJson = yield axios({
        url: `${process.env.REACT_APP_API_URL}/user/update-profile-picture`,
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: actions.data,
        successMessage: 'Profile image updated successfully.'
      });
    }
    if (responseJson) {
      yield put({
        type: profileActionTypes.PROFILE_IMAGE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: authActionTypes.USER_INFO_REQUEST
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.profile.path
        });
      }
    }
  } catch (error) {
    yield put({ type: profileActionTypes.PROFILE_UPDATE_ERROR, data: error });
  }
}

function* watchGetProfile() {
  yield takeLatest(profileActionTypes.PROFILE_REQUEST, getProfile);
}

function* watchProfileUpdate() {
  yield takeLatest(profileActionTypes.PROFILE_UPDATE_REQUEST, updateProfile);
}

function* watchProfileImageUpdate() {
  yield takeLatest(
    profileActionTypes.PROFILE_IMAGE_REQUEST,
    updateProfileImage
  );
}

function* watchCheckAvailability() {
  yield takeLatest(
    profileActionTypes.PROFILE_NAME_AVAILABILITY_REQUEST,
    checkAvailability
  );
}

export default [
  fork(watchProfileUpdate),
  fork(watchProfileImageUpdate),
  fork(watchGetProfile),
  fork(watchCheckAvailability)
];
