import { findIndex } from 'lodash';

import { paymentOption as paymentOptionTypes } from '../actions';
import initialState from '../constants/initialState';
import { insertItem, updateObjectInArray, removeItem } from '../utills/custom';

function paymentOption(state = initialState.paymentOption, action) {
  switch (action.type) {
    case paymentOptionTypes.PAYMENT_OPTION_ALL_CARD_LIST_REQUEST:
      return {
        ...state,
        isCardLoading: true
      };
    case paymentOptionTypes.PAYMENT_OPTION_ALL_BANK_LIST_REQUEST:
      return {
        ...state,
        isBankLoading: true
      };
    case paymentOptionTypes.PAYMENT_OPTION_ALL_CARD_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isCardLoading: false,
          cardList: action.data
        };
      }
      return state;
    case paymentOptionTypes.PAYMENT_OPTION_ALL_BANK_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isBankLoading: false,
          bankList: action.data
        };
      }
      return state;
    case paymentOptionTypes.PAYMENT_OPTION_ALL_CARD_LIST_ERROR:
      return {
        ...state,
        isCardLoading: false
      };
    case paymentOptionTypes.PAYMENT_OPTION_ALL_BANK_LIST_ERROR:
      return {
        ...state,
        isBankLoading: false
      };
    case paymentOptionTypes.PAYMENT_OPTION_SHOW_MODAL:
      if (action.data) {
        return {
          ...state,
          editPaymentOption: action.data,
          modal: action.data.optionType // Modal optionType: card || bank
        };
      }
      return {
        ...state,
        modal: false
      };

    case paymentOptionTypes.PAYMENT_OPTION_HIDE_MODAL:
      return {
        ...state,
        editPaymentOption: [],
        modal: false
      };
    case paymentOptionTypes.PAYMENT_OPTION_CREATE_REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case paymentOptionTypes.PAYMENT_OPTION_CREATE_SUCCESS:
    case paymentOptionTypes.PAYMENT_OPTION_CREATE_ERROR:
      return {
        ...state,
        inProgress: false
      };
    case paymentOptionTypes.PAYMENT_OPTION_SUCCESS:
      if (action.data) {
        if (action.optionType === 'card') {
          const newList = insertItem(state.cardList, {
            index: 0,
            item: action.data
          });
          return {
            ...state,
            isLoading: false,
            cardList: newList
          };
        }
        if (action.optionType === 'bank') {
          const newList = insertItem(state.bankList, {
            index: 0,
            item: action.data
          });
          return {
            ...state,
            isLoading: false,
            bankList: newList
          };
        }
      }
      return state;
    case paymentOptionTypes.PAYMENT_OPTION_UPDATE_REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case paymentOptionTypes.PAYMENT_OPTION_UPDATE_SUCCESS:
      if (action.data) {
        const index = findIndex(state.cardList, { id: action.data.id });
        if (index > -1) {
          if (action.optionType === 'card') {
            const newList = updateObjectInArray(state.cardList, {
              index,
              item: action.data
            });
            return {
              ...state,
              isProgress: false,
              cardList: newList
            };
          }
          if (action.optionType === 'bank') {
            const newList = updateObjectInArray(state.bankList, {
              index,
              item: action.data
            });
            return {
              ...state,
              isProgress: false,
              bankList: newList
            };
          }
        }
      }
      return {
        ...state,
        editPaymentOption: [],
        inProgress: false
      };
    case paymentOptionTypes.PAYMENT_OPTION_UPDATE_ERROR:
      return {
        ...state,
        inProgress: false
      };

    case paymentOptionTypes.PAYMENT_OPTION_SHOW_ALERT:
      return {
        ...state,
        alert: action.data
      };
    case paymentOptionTypes.PAYMENT_OPTION_HIDE_ALERT:
      return {
        ...state,
        alert: false
      };
    case paymentOptionTypes.PAYMENT_OPTION_DELETE_REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case paymentOptionTypes.PAYMENT_OPTION_DELETE_SUCCESS:
      if (action.data.id) {
        if (action.data.optionType === 'card') {
          const index = findIndex(state.cardList.data, { id: action.data.id });
          if (index > -1) {
            const newList = removeItem(state.cardList.data, {
              index
            });
            return {
              ...state,
              inProgress: false,
              cardList: { ...state.cardList, data: newList }
            };
          }
        } else if (action.data.optionType === 'bank') {
          const index = findIndex(state.bankList.data, { id: action.data.id });
          if (index > -1) {
            const newList = removeItem(state.bankList.data, {
              index
            });
            return {
              ...state,
              inProgress: false,
              bankList: { ...state.bankList, data: newList }
            };
          }
        }
      }
      return {
        ...state,
        inProgress: false
      };
    case paymentOptionTypes.PAYMENT_OPTION_DELETE_ERROR:
      return {
        ...state,
        inProgress: false
      };
    default:
      return state;
  }
}

export default paymentOption;
