import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, change, Form, getFormValues } from 'redux-form';
import { Button, Modal } from 'react-bootstrap';
import { required } from '../../utills/validator';
import {
  inputField,
  radioField,
  selectField,
  phoneField
} from '../../components/Fields';
import { app as appActions, address as addressActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';

class AddressForm extends React.Component {
  componentDidMount() {
    const {
      getCountry,
      getCityByState,
      getStateByCountry,
      initialValues
    } = this.props;
    // Fetch state and city list
    getCountry();

    if (initialValues.country) {
      getStateByCountry(initialValues.country);
    }
    if (initialValues.state) {
      getCityByState(initialValues.state);
    }
  }

  handleClose = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  countryChange = val => {
    const { dispatch, getStateByCountry } = this.props;
    dispatch(change('addressForm', 'city', ''));
    dispatch(change('addressForm', 'state', ''));
    if (val) {
      getStateByCountry(val);
    }
  };

  stateChange = val => {
    const { dispatch, getCityByState } = this.props;
    dispatch(change('addressForm', 'city', ''));
    if (val) {
      getCityByState(val);
    }
  };

  submitForm = values => {
    const { createAddress, updateAddress, editAddress } = this.props;
    const fieldValues = {
      ...values,
      mobile_number: values.mobile_number.replace(/\D/g, '')
    };
    if (
      fieldValues.city &&
      fieldValues.city === 'others' &&
      fieldValues.otherCity
    ) {
      fieldValues.city = fieldValues.otherCity;
    }
    if (editAddress && editAddress.id) {
      updateAddress({
        id: editAddress.id,
        data: fieldValues
      });
    } else createAddress(fieldValues);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      inProgress,
      countryList,
      stateList,
      cityList,
      formValues
    } = this.props;
    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(this.submitForm)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row address-modal-row">
            <div className="col-xs-12 col-md-6">
              <Field
                name="type"
                options={[
                  { value: 'BILLING', label: 'Billing' },
                  { value: 'SHIPPING', label: 'Shipping' },
                  { value: 'BOTH', label: 'Both' }
                ]}
                id="addressType"
                component={radioField}
                label="Location Type"
                validate={[required]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <Field
                name="country"
                id="country"
                component={selectField}
                label="Country"
                placeholder="Select..."
                feedback={this.countryChange}
                options={countryList}
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-4">
              <Field
                name="state"
                id="state"
                component={selectField}
                label="Region/Province"
                feedback={this.stateChange}
                options={stateList}
                placeholder="Select..."
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-4">
              <Field
                name="city"
                id="city"
                component={selectField}
                label="City"
                placeholder="Select..."
                options={[{ label: 'Others', value: 'others' }, ...cityList]}
              />
            </div>

            {formValues.city === 'others' && (
              <div className="col-xs-12 col-md-12">
                <Field
                  name="otherCity"
                  id="otherCity"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="City name"
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-8">
              <Field
                name="name"
                component={inputField}
                type="text"
                className="form-control"
                label="Full name"
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-4">
              <Field
                name="mobile_number"
                component={phoneField}
                className="form-control"
                label="Mobile or Phone"
                validate={[required]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-8">
              <Field
                name="street_name_number"
                component={inputField}
                type="text"
                className="form-control"
                label="Street no. & name"
                placeholder="Street and number, P.O. box, c/o."
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-4">
              <Field
                name="postcode"
                component={inputField}
                type="text"
                className="form-control"
                label="Postal code"
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <Field
                name="address_line1"
                component={inputField}
                type="text"
                className="form-control"
                label="Address line 1"
                placeholder="Apartment number, suite, unit, building, floor, etc"
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <Field
                name="address_line2"
                component={inputField}
                type="text"
                className="form-control"
                label="Address line 2"
                placeholder=""
              />
            </div>
          </div>
          <div className="row address-modal-row address-modal-row--wide">
            <div className="col-xs-12 col-md-6">
              <Field
                name="location_type"
                options={[
                  { value: 'HOME', label: 'Home' },
                  { value: 'OFFICE', label: 'Office' }
                ]}
                id="locationType"
                component={radioField}
                label="Location Type"
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <Field
                name="is_default"
                options={[
                  { value: 0, label: 'No' },
                  { value: 1, label: 'Yes' }
                ]}
                id="isDefault"
                component={radioField}
                label="Default"
                validate={[required]}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={inProgress || pristine || submitting}
            variant="primary"
          >
            {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.app.countryList,
  stateList: state.app.stateList,
  cityList: state.app.cityList,
  editAddress: state.address.editAddress,
  formValues: getFormValues('addressForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountry: appActions.getCountry,
      getStateByCountry: appActions.getStateByCountry,
      getCityByState: appActions.getCityByState,
      hideModal: addressActions.hideModal,
      createAddress: addressActions.createAddress,
      getAddressById: addressActions.getAddressById,
      updateAddress: addressActions.updateAddress
    },
    dispatch
  );

const AddressFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressForm);

export default reduxForm({
  form: 'addressForm' // a unique identifier for this form
})(AddressFormWithRedux);

AddressForm.defaultProps = {
  getCountry: () => {},
  getCityByState: () => {},
  getStateByCountry: () => {},
  initialValues: {},
  hideModal: () => {},
  createAddress: () => {},
  updateAddress: () => {},
  editAddress: {},
  handleSubmit: () => {},
  pristine: false,
  submitting: false,
  inProgress: false,
  countryList: [],
  stateList: [],
  cityList: [],
  formValues: {}
};

AddressForm.propTypes = {
  getCountry: PropTypes.func,
  getCityByState: PropTypes.func,
  getStateByCountry: PropTypes.func,
  initialValues: PropTypes.objectOf(PropTypes.any),
  hideModal: PropTypes.func,
  createAddress: PropTypes.func,
  updateAddress: PropTypes.func,
  editAddress: PropTypes.objectOf(PropTypes.any),
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  inProgress: PropTypes.bool,
  countryList: PropTypes.arrayOf(PropTypes.object),
  stateList: PropTypes.arrayOf(PropTypes.any),
  cityList: PropTypes.arrayOf(PropTypes.any),
  formValues: PropTypes.objectOf(PropTypes.any)
};
