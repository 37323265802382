import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  category as categoryActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllCategory(actions) {
  const page = actions.data && actions.data.page ? actions.data.page : false;
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/category${
        page > 1 ? `?page=${page}` : ''
      }`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: categoryActionTypes.CATEGORY_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getCategory(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/category/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: categoryActionTypes.CATEGORY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* createCategory(actions) {
  try {
    const fd = new FormData();
    Object.keys(actions.data).forEach(item => {
      fd.append(item, actions.data[item]);
    });
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/category/add`,
      method: 'POST',
      data: fd,
      successMessage: 'Category was addedd successfully'
    });
    if (responseJson) {
      yield put({
        type: categoryActionTypes.CATEGORY_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.category.path
      });
    }
  } catch (error) {
    yield put({ type: categoryActionTypes.CATEGORY_CREATE_ERROR, data: error });
  }
}

function* updateCategory(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/category/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Category updated successfully'
    });
    if (responseJson) {
      yield put({
        type: categoryActionTypes.CATEGORY_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.category.path
        });
      }
    }
  } catch (error) {
    yield put({ type: categoryActionTypes.CATEGORY_UPDATE_ERROR, data: error });
  }
}

function* watchPackageListAll() {
  yield takeLatest(
    categoryActionTypes.CATEGORY_ALL_LIST_REQUEST,
    getAllCategory
  );
}

function* watchGetCategory() {
  yield takeLatest(categoryActionTypes.CATEGORY_REQUEST, getCategory);
}

function* watchCategoryCreate() {
  yield takeLatest(categoryActionTypes.CATEGORY_CREATE_REQUEST, createCategory);
}

function* watchCategoryUpdate() {
  yield takeLatest(categoryActionTypes.CATEGORY_UPDATE_REQUEST, updateCategory);
}

export default [
  fork(watchPackageListAll),
  fork(watchCategoryCreate),
  fork(watchCategoryUpdate),
  fork(watchGetCategory)
];
