const category = {
  CATEGORY_ALL_LIST_REQUEST: 'CATEGORY_ALL_LIST_REQUEST',
  CATEGORY_ALL_LIST_SUCCESS: 'CATEGORY_ALL_LIST_SUCCESS',
  CATEGORY_ALL_LIST_ERROR: 'CATEGORY_ALL_LIST_ERROR',
  CATEGORY_CREATE_REQUEST: 'CATEGORY_CREATE_REQUEST',
  CATEGORY_CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
  CATEGORY_CREATE_ERROR: 'CATEGORY_CREATE_ERROR',
  CATEGORY_UPDATE_REQUEST: 'CATEGORY_UPDATE_REQUEST',
  CATEGORY_UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
  CATEGORY_UPDATE_ERROR: 'CATEGORY_UPDATE_ERROR',
  CATEGORY_REQUEST: 'CATEGORY_REQUEST',
  CATEGORY_SUCCESS: 'CATEGORY_SUCCESS',
  getAllCategories: data => ({
    type: category.CATEGORY_ALL_LIST_REQUEST,
    data
  }),
  createCategory: data => ({ type: category.CATEGORY_CREATE_REQUEST, data }),
  updateCategory: data => ({ type: category.CATEGORY_UPDATE_REQUEST, data }),
  getCategoryById: id => ({ type: category.CATEGORY_REQUEST, id })
};

export default category;
