import _, { keyBy } from 'lodash';
import moment from 'moment';
import languages from './languages.json';
import timezones from './timezones.json';
import countries from './countries.json';
import currencies from './currencies.json';

export function titleCase(str) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export function sentenceCase(str) {
  const lowerStr = str.toLowerCase();
  let firstChar = lowerStr.charAt(0);
  firstChar = firstChar.toUpperCase();
  return lowerStr.replace(/^./, firstChar);
}

export function readableTransform(str) {
  let replacedText = str.replace(/[_\W]+/g, ' ');
  replacedText = replacedText.replace(/\s{2,10}/g, ' ');
  return titleCase(replacedText);
}

export function categoryHierarchyWIthIndex(list) {
  const sortedList = [];
  const indexOf = {};
  const sortedIDList = [];
  list.forEach(item => {
    if (item.parent_id) {
      if (!sortedIDList.includes(item.parent_id)) {
        sortedIDList.push(item.parent_id);
        indexOf[item.parent_id] = item.parent_id;
      }

      let index = item.parent_id;
      let findOf = 0;
      do {
        findOf = index;
        index = indexOf[findOf];
      } while (index !== findOf);
      index = sortedIDList.indexOf(index) + 1;
      sortedIDList.splice(index, 0, item.id);
      indexOf[item.id] = item.id;
      indexOf[item.parent_id] = item.id;
    } else {
      sortedIDList.push(item.id);
      indexOf[item.id] = item.id;
    }
  });

  const listById = keyBy(list, 'id');
  let hierarchyList = [];
  sortedIDList.forEach(itemId => {
    const item = listById[itemId];
    if (item.parent_id) {
      if (!hierarchyList.includes(item.parent_id)) {
        hierarchyList.push(item.parent_id);
      } else if (item.parent_id !== hierarchyList[hierarchyList.length - 1]) {
        hierarchyList.pop();
      }
    } else {
      hierarchyList = [];
    }
    const newListItem = { ...item, hierarchyLevel: hierarchyList.length };
    sortedList.push(newListItem);
  });

  return sortedList;
}

export function updateObjectInArray(array, action) {
  return array.map((item, index) => {
    if (index !== action.index) {
      return item;
    }
    return {
      ...item,
      ...action.item
    };
  });
}

export function insertItem(array, action) {
  const newArray = array.slice();
  newArray.splice(action.index, 0, action.item);
  return newArray;
}

export function removeItem(array, action) {
  const newArray = array.slice();
  newArray.splice(action.index, 1);
  return newArray;
}

export function getSlug(title = '') {
  if (title) {
    return title.replace(/\W+/g, '-').toLowerCase();
  }
  return title;
}

export function getDiscount(price, salePrice) {
  if (price && salePrice) {
    return (((price - salePrice) * 100) / price).toFixed(2);
  }

  return 0;
}

export function getAssocPrice(price, bPoints) {
  if (price && bPoints) {
    return (price - ((bPoints * 50) / 100) * 1.43).toFixed(2);
  }

  return 0;
}

export function hierarchyDash(level) {
  switch (level) {
    case 1:
      return '-';
    case 2:
      return '--';
    case 3:
      return '---';
    case 0:
    default:
      return '';
  }
}

export function timezoneDropdown() {
  const timezoneList = [];
  _.forEach(timezones, (label, value) => {
    timezoneList.push({
      label,
      value
    });
  });
  return timezoneList;
}

export function languageDropdown() {
  const languageList = [];
  _.forEach(languages, (language, value) => {
    languageList.push({
      label: language.name,
      value
    });
  });
  return languageList;
}

export function countryDropdown() {
  const countryList = [];
  _.forEach(countries, country => {
    countryList.push({
      label: country.name,
      value: country.code
    });
  });
  return countryList;
}

export function currencyDropdown() {
  const currencyList = [];
  _.forEach(currencies, currency => {
    currencyList.push({
      label: currency.name,
      value: currency.code
    });
  });
  return currencyList;
}

export function makeKeyValue(list, key, keyForValue) {
  return _.chain(list)
    .keyBy(key)
    .mapValues(keyForValue)
    .value();
}

export function getCurrencySymbol(val) {
  const currency = _.get(currencies, val);
  return currency ? currency.symbol_native : '';
}

export function payoutDropdown(limit = 31, suffix = 'of every month') {
  const daysList = [];
  for (let day = 1; day <= limit; day += 1) {
    daysList.push({
      label: `${day} ${suffix}`,
      value: day
    });
  }
  return daysList;
}

export function threeDTransactionForm(data) {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = data.action;
  const fields = [
    {
      name: 'MD',
      value: data.MD
    },
    {
      name: 'PaReq',
      value: data.PaReq
    },
    {
      name: 'TermUrl',
      value: data.TermUrl
    }
  ];
  fields.forEach(item => {
    const field = document.createElement('input');
    field.type = 'hidden';
    field.name = item.name;
    field.value = decodeURIComponent(item.value);
    form.appendChild(field);
  });
  document.body.appendChild(form);
  form.submit();
}

export function numberToWords(input) {
  const a = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen '
  ];
  const b = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety'
  ];

  const num = Math.floor(input).toString();
  if (num.length > 9) {
    return 'overflow';
  }
  const n = `000000000${num}`
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

  if (!n) {
    return '';
  }
  let str = '';
  str +=
    parseInt(n[1], 10) !== 0
      ? `${a[Number(n[1])] || `${b[n[1][0]]} ${a[n[1][1]]}`}crore `
      : '';
  str +=
    parseInt(n[2], 10) !== 0
      ? `${a[Number(n[2])] || `${b[n[2][0]]} ${a[n[2][1]]}`}lakh `
      : '';
  str +=
    parseInt(n[3], 10) !== 0
      ? `${a[Number(n[3])] || `${b[n[3][0]]} ${a[n[3][1]]}`}thousand `
      : '';
  str +=
    parseInt(n[4], 10) !== 0
      ? `${a[Number(n[4])] || `${b[n[4][0]]} ${a[n[4][1]]}`}hundred `
      : '';
  str +=
    parseInt(n[5], 10) !== 0
      ? `${(str !== '' ? 'and ' : '') +
          (a[Number(n[5])] || `${b[n[5][0]]} ${a[n[5][1]]}`)}only `
      : '';
  return titleCase(str);
}

export function getImageUrl(url, size = {}, retina = false) {
  // const fileName = url.match(/(?:[^/][\d\w.]+)$(?<=.\w{3,4})/g);
  const fileName = url.substring(url.lastIndexOf('/') + 1);
  if (fileName) {
    const baseName = fileName.replace(/\.[a-zA-z]{2,5}$/g, '');
    const extension = fileName.match(/\.[a-zA-z]{2,5}$/g);
    let newFileName = `${baseName}_${size.width}x${size.height}${extension}`;
    if (retina) {
      newFileName = `${baseName}_${size.width}x${size.height}@2x${extension}`;
    }
    const newUrl = url.replace(fileName, '');
    return newUrl + newFileName;
  }
  return url;
}

export function getCustomerProductPrice(data, noTax = false) {
  if (data.taxable && !noTax)
    return parseFloat(data.customer_price_vat.replace(',', ''));
  return parseInt(data.sale_price, 10)
    ? parseFloat(data.sale_price.replace(',', ''))
    : parseFloat(data.regular_price.replace(',', ''));
}

export const dateRanges = {
  Daily: [moment(), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [
    moment()
      .subtract(1, 'month')
      .startOf('month'),
    moment()
      .subtract(1, 'month')
      .endOf('month')
  ],
  'This Year': [moment().startOf('year'), moment().endOf('year')],
  'Last Year': [
    moment()
      .startOf('year')
      .subtract(1, 'year'),
    moment()
      .endOf('year')
      .subtract(1, 'year')
  ],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()]
};

export const dateRangesValues = {
  Daily: 'daily',
  'This Month': 'monthly',
  'Last Month': 'last_month',
  'This Year': 'yearly',
  'Last Year': 'last_year',
  'Last 7 Days': 'last_seven_days',
  'Last 30 Days': 'last_thirty_days'
};

export const getOrderStatusClass = status => {
  switch (status) {
    case 'PROCESSING':
      return 'badge badge-warning';
    case 'PENDING':
      return 'badge badge-secondary';
    case 'SUCCESS':
    case 'SUCCESS-PARTIAL-CANCELLED':
    case 'SUCCESS-PARTIAL-REFUND':
      return 'badge badge-success';
    case 'DECLINED':
      return 'badge badge-info';
    case 'FAILED':
    default:
      return 'badge badge-danger';
  }
};

export const getFulfillmentClass = (status, addClass = '') => {
  switch (status) {
    case 'DELIVERED':
      return `${addClass} badge badge-success`;
    case 'SHIPPED':
      return `${addClass} badge badge-info`;
    default:
      return `${addClass} badge badge-secondary`;
  }
};
