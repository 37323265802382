class StorageService {
  constructor() {
    this.storage = window.localStorage;
    this.sessionStorage = window.sessionStorage;
    this.token_key = 'token';
    this.refresh_token_key = 'refresh_token';
    this.add_to_cart = 'add_to_cart';
    this.shop_country = 'shop_country';
    this.shop_state = 'shop_state';
  }

  getToken() {
    return this.storage.getItem(this.token_key) || false;
  }

  setToken(token) {
    this.storage.setItem(this.token_key, token);
  }

  getRefreshToken() {
    return this.storage.getItem(this.refresh_token_key) || false;
  }

  setRefreshToken(token) {
    this.storage.setItem(this.refresh_token_key, token);
  }

  setAddToCart(data) {
    this.storage.setItem(this.add_to_cart, JSON.stringify(data));
  }

  getAddToCart() {
    const data = this.storage.getItem(this.add_to_cart);
    return data ? JSON.parse(data) : false;
  }

  unsetAddToCart() {
    this.storage.removeItem(this.add_to_cart);
  }

  getShopCountry() {
    return this.sessionStorage.getItem(this.shop_country) || null;
  }

  setShopCountry(country) {
    this.sessionStorage.setItem(this.shop_country, country);
  }

  getShopState() {
    return this.sessionStorage.getItem(this.shop_state) || null;
  }

  setShopState(state) {
    this.sessionStorage.setItem(this.shop_state, state);
  }

  getAppliedCoupon() {
    return this.sessionStorage.getItem(this.applied_coupon);
  }

  setAppliedCoupon(value) {
    this.sessionStorage.setItem(this.applied_coupon, value);
  }

  clearStorage() {
    this.storage.clear();
    this.sessionStorage.clear();
  }
}

export default new StorageService();
