import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import {
  auth as authActions,
  cart as cartActions,
  app as appActions
} from '../actions';
import RouteWithSubRoutes from '../components/RouteWithSubRoutes';
import ScrollToTop from '../components/ScrollToTop';
import Navbar from '../components/Navbar';
import Footer from '../components/FooterDash';
import PageLoader from '../components/PageLoader';
import NoMatch from './NoMatch';

export class Main extends React.Component {
  componentDidMount() {
    document.body.classList.remove('authentication-bg');
    const {
      getUserInfo,
      getCart,
      getPrefetchImageStyle,
      getPrefetchSetting,
      getCountryWiseCurrency
    } = this.props;
    getUserInfo();
    getPrefetchSetting();
    getCart();
    getPrefetchImageStyle();
    getCountryWiseCurrency();
  }

  render() {
    const { routes, userInfoProgress, userInfo } = this.props;
    const permission = get(userInfo, 'permission.permission', {});
    return (
      <ScrollToTop>
        <div className="dashboard">
          <div className="wrapper">
            {userInfoProgress || isEmpty(userInfo) ? (
              <PageLoader />
            ) : (
              <>
                {/* <LeftSidebar /> */}
                <div className="content-page">
                  <div className="content mb-3">
                    <Navbar />
                    <div className="container-fluid">
                      <Switch>
                        {routes.map(route => {
                          if (
                            route.permission === undefined ||
                            (route.permission && permission[route.permission])
                          ) {
                            return (
                              <RouteWithSubRoutes key={route.name} {...route} />
                            );
                          }
                          // hard coding
                          if (route.permission === 'tree_read')
                            return (
                              <RouteWithSubRoutes key={route.name} {...route} />
                            );
                          return null;
                        })}
                        <Route component={NoMatch} />
                      </Switch>
                    </div>
                  </div>
                  <Footer />
                </div>
              </>
            )}
          </div>
        </div>
      </ScrollToTop>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.user,
  userInfoProgress: state.auth.userInfoProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserInfo: authActions.getUserInfo,
      getPrefetchSetting: appActions.getPrefetchSetting,
      getCountryWiseCurrency: appActions.getCountryWiseCurrency,
      getCart: cartActions.getCart,
      getPrefetchImageStyle: appActions.getPrefetchImageStyle
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);

Main.defaultProps = {
  getUserInfo: () => {},
  getCart: () => {},
  getPrefetchImageStyle: () => {},
  getPrefetchSetting: () => {},
  routes: [],
  userInfo: {},
  userInfoProgress: false
};

Main.propTypes = {
  getUserInfo: PropTypes.func,
  getCart: PropTypes.func,
  getPrefetchImageStyle: PropTypes.func,
  getPrefetchSetting: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
  userInfo: PropTypes.objectOf(PropTypes.any),
  userInfoProgress: PropTypes.bool
};
