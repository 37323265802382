import { ajax } from './ajax';
import { email } from './validator';

// eslint-disable-next-line import/prefer-default-export
export const registrationAsyncValidate = async (values /* , dispatch */) => {
  try {
    if (values.email && email(values.email) === undefined) {
      await ajax({
        url: `${process.env.REACT_APP_API_URL}/check-email`,
        method: 'POST',
        data: { email: values.email },
        hideMessage: true,
        hideErrorMessage: true
      });
    }
  } catch (error) {
    const err = { email: 'Email ID already registred' };
    throw err;
  }
};
