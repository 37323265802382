import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { checkout as checkoutTypes } from '../actions';
import { createForm } from '../utills/ajax';

function* checkout(actions) {
  try {
    let successMsg = 'Redirecting to QuickPay';
    if (actions.data.payment_type === 'zotto_pay') {
      successMsg = 'Redirecting to Zottopay';
    }
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/checkout`,
      method: 'POST',
      data: actions.data,
      successMessage: successMsg
    });
    if (responseJson) {
      if (actions.data.payment_type === 'zotto_pay') {
        createForm(responseJson);
      } else {
        window.location = responseJson.url;
      }
    }
  } catch (error) {
    yield put({ type: checkoutTypes.CHECKOUT_ERROR, data: error });
  }
}

function* cancelPaymentRequest(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/cancel-payment`,
      method: 'POST',
      data: actions.data,
      successMessage: actions.data.messsage
    });
    if (responseJson) {
      yield put({
        type: checkoutTypes.CANCEL_PAYMENT_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: checkoutTypes.CANCEL_PAYMENT_ERROR, data: error });
  }
}

function* watchCheckout() {
  yield takeLatest(checkoutTypes.CHECKOUT_REQUEST, checkout);
}

function* watchCancelPaymentRequest() {
  yield takeLatest(checkoutTypes.CANCEL_PAYMENT_REQUEST, cancelPaymentRequest);
}

export default [fork(watchCheckout), fork(watchCancelPaymentRequest)];
