import { dashboard as dashboardActionTypes } from '../actions';
import initialState from '../constants/initialState';

function dashboard(state = initialState.dashboard, action) {
  switch (action.type) {
    case dashboardActionTypes.DASHBOARD_REQUEST:
      return {
        ...state,
        progress: true
      };
    case dashboardActionTypes.DASHBOARD_SUCCESS:
      return {
        ...state,
        progress: false,
        data: action.data
      };
    case dashboardActionTypes.DASHBOARD_ERROR:
      return {
        ...state,
        progress: false,
        error: action.data
      };
    default:
      return state;
  }
}

export default dashboard;
