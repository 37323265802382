export const productImage = {
  productAdmin: 'product-listing-admin',
  productListMedium: 'product-list-medium',
  productGridThumb: 'product-grid-thumb',
  productGridMedium: 'product-grid-medium'
};

export const categoryImage = {
  categoryAdmin: 'cat-img-admin',
  shopCategoryMedium: 'cat-img-medium'
};

export const profileImage = {
  profileSmall: 'profile-img-small',
  profileThumbnail: 'profile-img-thumbnail'
};
