import React, { useState } from 'react';
import { connect } from 'react-redux';
import { WarningAlert } from '../../components/ConfirmAlert';

function AddToCartButton({
  onClick,
  disabled = false,
  className,
  shopCountry,
  shopState,
  cartDetails
}) {
  const [displayPrompt, setDisplayPrompt] = useState(false);
  const handleClick = () => {
    if (
      cartDetails &&
      cartDetails.items.length &&
      ((cartDetails.country && shopCountry !== cartDetails.country) ||
        (!(!shopState && !cartDetails.state) &&
          shopState !== cartDetails.state))
    ) {
      setDisplayPrompt(true);
    } else {
      onClick();
    }
  };

  const handleConfirm = () => {
    setDisplayPrompt(false);
    onClick();
  };

  const handleCalcel = () => {
    setDisplayPrompt(false);
  };
  return (
    <>
      <button
        type="button"
        className={className}
        onClick={handleClick}
        disabled={disabled}
      >
        <i className="mdi mdi-cart" />
        <span> Add to cart</span>
      </button>

      <WarningAlert
        show={displayPrompt}
        onConfirm={handleConfirm}
        onCancel={handleCalcel}
      >
        You are adding product for different country/state, you have added
        before. Your existing cart items might not be available or price may
        vary. Confirm to continue
      </WarningAlert>
    </>
  );
}

const mapStateToProps = state => ({
  shopCountry: state.app.shopCountry,
  shopState: state.app.shopState,
  cartDetails: state.cart.cartDetails
});

export default connect(
  mapStateToProps,
  null
)(AddToCartButton);
