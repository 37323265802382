/* global appConfig */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ForgotPasswordForm from './ForgotPasswordForm';
import { auth as authActions } from '../../actions';
import routePath from '../../constants/routePath';
import Footer from '../../components/Footer';

class ForgotPassword extends React.Component {
  componentDidMount() {
    document.body.classList.add('authentication-bg');
  }

  handleSubmit = values => {
    const { forgotPassword } = this.props;
    forgotPassword(values);
  };

  render() {
    const { forgotPasswordProgress } = this.props;
    return (
      <>
        <div className="account-pages">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="card">
                  <div className="card-header border-bottom-dash text-center">
                    <img
                      className="logo-img"
                      src={appConfig.logo}
                      alt="{appConfig.logoALT}"
                      height="54"
                    />
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                        Forgot Password
                      </h4>
                      <p className="text-muted mb-3">
                        Enter your registered email and follow the instructions
                        to reset your password.
                      </p>
                    </div>
                    {/* {forgotPasswordSuccess ? (
                      <div className="alert alert-success" role="alert">
                        <i className="dripicons-checkmark mr-2" />
                        Password reset link has been sent to your email Id
                      </div>
                    ) : null} */}
                    <ForgotPasswordForm
                      submitLogin={this.handleSubmit}
                      inProgress={forgotPasswordProgress}
                    />
                  </div>
                  <div className="card-footer text-center">
                    <p className="text-muted mt-1">
                      <span>Already have an account?</span>
                      <Link to={routePath.login.path} className="ml-1">
                        <b>Sign In</b>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  forgotPasswordProgress: state.auth.forgotPasswordProgress,
  forgotPasswordSuccess: state.auth.forgotPasswordSuccess
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ forgotPassword: authActions.forgotPassword }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);

ForgotPassword.defaultProps = {
  forgotPassword: () => {},
  forgotPasswordProgress: false
};

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func,
  forgotPasswordProgress: PropTypes.bool
};
