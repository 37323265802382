import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { fsb as fsbActionTypes } from '../actions';

function* getFsb() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/fsb/config`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: fsbActionTypes.FSB_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateFsb(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/fsb/config`,
      method: 'PUT',
      data: actions.data,
      successMessage: 'Fsb updated successfully'
    });
    if (responseJson) {
      yield put({
        type: fsbActionTypes.FSB_UPDATE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: fsbActionTypes.FSB_UPDATE_ERROR, data: error });
  }
}

function* watchGetFsb() {
  yield takeLatest(fsbActionTypes.FSB_REQUEST, getFsb);
}

function* watchFsbUpdate() {
  yield takeLatest(fsbActionTypes.FSB_UPDATE_REQUEST, updateFsb);
}

export default [fork(watchFsbUpdate), fork(watchGetFsb)];
