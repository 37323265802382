import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BreadCrumb from '../../components/BreadCrumb';
import DeleteAlert from '../../components/ConfirmAlert';
import routePath from '../../constants/routePath';
import PaymentOptionModal from './PaymentOptionModal';
import { paymentOption as paymentOptionActions } from '../../actions';
import InnerLoader from '../../components/InnerLoader';
import NoResult from '../../components/NoResult';

class PaymentOptions extends React.Component {
  componentDidMount() {
    const { getAllPaymentOption } = this.props;
    getAllPaymentOption('CARD');
  }

  handleAdd = optionType => {
    const { showModal } = this.props;
    showModal({
      optionType,
      is_default: '0'
    });
  };

  handleDelete = () => {
    const { deletePaymentOption, alert } = this.props;
    deletePaymentOption(alert);
  };

  render() {
    const { cards, alert, inProgress, hideAlert, isCardLoading } = this.props;
    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.payOptions.breadcrumb} />
              <h4 className="page-title">{routePath.payOptions.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row  mb-3">
              <div className="col">
                <h5 className="mt-0">Cards</h5>
                <p className="text-muted font-13">
                  Manage your credit and debit cards here for faster checkout
                  experience.
                </p>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-primary float-right"
                  onClick={() => this.handleAdd('card')}
                >
                  <i className="mdi mdi-plus-circle mr-2" />
                  Add New{' '}
                  <span className="d-none d-sm-inline-block"> Card</span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="row">
              {isCardLoading && <InnerLoader type="table" />}
              {!cards.data.length && !isCardLoading && (
                <NoResult icon="mdi mdi-credit-card" />
              )}
              {/* {cards.data &&
                cards.data.map(card => (
                  <PaymentOptionItem
                    paymentOption={card}
                    optionType="card"
                    key={card.id}
                  />
                ))} */}
              <DeleteAlert
                show={!!alert}
                inProgress={inProgress}
                onConfirm={this.handleDelete}
                onCancel={hideAlert}
              />
            </div>
          </div>
        </div>
        <PaymentOptionModal />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  cards: state.paymentOption.cardList,
  inProgress: state.paymentOption.inProgress,
  isCardLoading: state.paymentOption.isCardLoading,
  alert: state.paymentOption.alert
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showModal: paymentOptionActions.showModal,
      hideAlert: paymentOptionActions.hideAlert,
      getAllPaymentOption: paymentOptionActions.getAllPaymentOption,
      deletePaymentOption: paymentOptionActions.deletePaymentOption
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentOptions);

PaymentOptions.defaultProps = {
  getAllPaymentOption: () => {},
  showModal: () => {},
  deletePaymentOption: () => {},
  cards: {},
  hideAlert: () => {},
  alert: false,
  inProgress: false,
  isCardLoading: false
};

PaymentOptions.propTypes = {
  getAllPaymentOption: PropTypes.func,
  showModal: PropTypes.func,
  deletePaymentOption: PropTypes.func,
  cards: PropTypes.objectOf(PropTypes.any),
  hideAlert: PropTypes.func,
  alert: PropTypes.bool,
  inProgress: PropTypes.bool,
  isCardLoading: PropTypes.bool
};
