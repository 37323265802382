import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import { keyBy } from 'lodash';
import { app as appActionTypes } from '../actions';
import history from '../services/historyService';

function* getCountryList() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/prefetch/countries`,
      method: 'GET'
    });
    if (responseJson) {
      const options = [];
      responseJson.forEach(item => {
        options.push({ id: item.id, label: item.name, value: item.iso2 });
      });
      yield put({
        type: appActionTypes.COUNTRY_LIST_SUCCESS,
        data: options
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getStateList(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/states/${actions.data}`,
      method: 'GET'
    });
    if (responseJson) {
      const options = [];
      responseJson.forEach(item => {
        options.push({ label: item.name, value: item.id });
      });
      yield put({
        type: appActionTypes.STATE_LIST_SUCCESS,
        data: options
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getCityList(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/cities/${actions.data}`,
      method: 'GET'
    });
    if (responseJson) {
      const options = [];
      responseJson.forEach(item => {
        options.push({ label: item.name, value: item.id });
      });
      yield put({ type: appActionTypes.CITY_LIST_SUCCESS, data: options });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getPrefetchCategoryList() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/prefetch/category`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: appActionTypes.PREFETCH_CATEGORY_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getPrefetchShippingList() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/prefetch/shipping`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: appActionTypes.PREFETCH_SHIPPING_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getPrefetchSettingList(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/prefetch/settings`,
      method: 'GET',
      params: actions.data,
      paramsSerializer: params => qs.stringify(params)
    });
    if (responseJson) {
      yield put({
        type: appActionTypes.PREFETCH_SETTING_SUCCESS,
        data: responseJson.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getPrefetchImageStyle() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/prefetch/image-style`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: appActionTypes.PREFETCH_IMAGE_STYLE_SUCCESS,
        data: keyBy(responseJson, 'name')
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getPrefetchStoreAddress(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/prefetch/store-address/${actions.country}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: appActionTypes.PREFETCH_STORE_ADDRESS_SUCCESS,
        data: responseJson.data,
        country: actions.country
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getPrefetchCountryWiseCurtrency() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/prefetch/active-country-currencies`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: appActionTypes.PREFETCH_COUNTRY_WISE_CURRENCY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* navigate(actions) {
  if (actions.path) {
    yield history.replace(actions.path);
  }
}

function* watchCityList() {
  yield takeLatest(appActionTypes.CITY_LIST_REQUEST, getCityList);
}

function* watchStateList() {
  yield takeLatest(appActionTypes.STATE_LIST_REQUEST, getStateList);
}
function* watchCountryList() {
  yield takeLatest(appActionTypes.COUNTRY_LIST_REQUEST, getCountryList);
}

function* watchNavigate() {
  yield takeLatest(appActionTypes.NAVIGATE_REQUEST, navigate);
}

function* watchPrefetchCategoryList() {
  yield takeLatest(
    appActionTypes.PREFETCH_CATEGORY_LIST_REQUEST,
    getPrefetchCategoryList
  );
}

function* watchPrefetchShippingList() {
  yield takeLatest(
    appActionTypes.PREFETCH_SHIPPING_LIST_REQUEST,
    getPrefetchShippingList
  );
}

function* watchPrefetchSettingList() {
  yield takeLatest(
    appActionTypes.PREFETCH_SETTING_REQUEST,
    getPrefetchSettingList
  );
}

function* watchPrefetchImageStyle() {
  yield takeLatest(
    appActionTypes.PREFETCH_IMAGE_STYLE_REQUEST,
    getPrefetchImageStyle
  );
}

function* watchPrefetchStoreAddress() {
  yield takeLatest(
    appActionTypes.PREFETCH_STORE_ADDRESS_REQUEST,
    getPrefetchStoreAddress
  );
}

function* watchPrefetchCountryWiseCurtrenc() {
  yield takeLatest(
    appActionTypes.PREFETCH_COUNTRY_WISE_CURRENCY_REQUEST,
    getPrefetchCountryWiseCurtrency
  );
}

export default [
  fork(watchCountryList),
  fork(watchStateList),
  fork(watchCityList),
  fork(watchPrefetchCategoryList),
  fork(watchPrefetchShippingList),
  fork(watchPrefetchSettingList),
  fork(watchPrefetchImageStyle),
  fork(watchNavigate),
  fork(watchPrefetchStoreAddress),
  fork(watchPrefetchCountryWiseCurtrenc)
];
