import React from 'react';
import PropTypes from 'prop-types';
import { formValueSelector, change, submit } from 'redux-form';
import connect from 'react-redux/es/connect/connect';
import Button from 'react-bootstrap/Button';
import validator from 'card-validator';
import PaymentMode from './PaymentMode';
import btnLoader from '../../assets/images/btn-loader.gif';

class PaymentOption extends React.Component {
  handleCheckout = () => {
    const { dispatch } = this.props;
    dispatch(submit('checkoutForm'));
  };

  selectSavedCard = e => {
    const { dispatch } = this.props;
    dispatch(change('checkoutForm', 'card_id', e.target.value));
    dispatch(change('checkoutForm', 'payment_type', 'saved_card'));
  };

  selectPaymentType = e => {
    const { dispatch } = this.props;
    dispatch(change('checkoutForm', 'payment_type', e.target.value));
  };

  handleCardNumber = e => {
    const { dispatch } = this.props;
    const numberValidation = validator.number(e.target.value);
    if (
      numberValidation &&
      numberValidation.isPotentiallyValid &&
      numberValidation.card
    ) {
      dispatch(change('checkoutForm', 'card_type', numberValidation.card.type));
    }
    dispatch(change('checkoutForm', 'card_number', e.target.value));
  };

  handleCardInputs = e => {
    const { dispatch } = this.props;
    dispatch(change('checkoutForm', e.target.name, e.target.value));
  };

  handleCardExp = e => {
    const { dispatch } = this.props;
    const expDateValidation = validator.expirationDate(e.target.value);
    dispatch(change('checkoutForm', 'exp_date', e.target.value));
    if (expDateValidation.month) {
      dispatch(change('checkoutForm', 'exp_month', expDateValidation.month));
    }
    if (expDateValidation.year) {
      dispatch(change('checkoutForm', 'exp_year', expDateValidation.year));
    }
  };

  render() {
    const {
      paymentType,
      processing,
      handleTabChange,
      appSetting,
      shopCountry
    } = this.props;
    return (
      <>
        <h4 className="mt-2 mb-3">Choose Payment Option</h4>
        <PaymentMode
          country={shopCountry}
          value={paymentType}
          onChange={this.selectPaymentType}
        />

        <div className="row text-center text-xs-left text-sm-left">
          <div className="col-12">
            <hr />
          </div>
          <div className="col-xs-6 col-sm-6">
            <Button
              variant="light"
              className="d-sm-inline-block font-weight-semibold mb-3 mb-sm-0"
              onClick={() => handleTabChange('billing-information')}
            >
              <i className="mdi mdi-arrow-left mr-1" />
              <span>Back</span>
            </Button>
          </div>
          <div className="col-xs-6 col-sm-6">
            <div className="text-xs-right text-sm-right">
              <Button
                variant="danger"
                className="font-weight-semibold"
                disabled={!paymentType}
                onClick={this.handleCheckout}
              >
                {processing ? (
                  <img src={btnLoader} alt="" />
                ) : (
                  <>
                    <i className="mdi mdi-check-decagram mr-1" />
                    <span>Place Order</span>
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const selector = formValueSelector('checkoutForm');

const mapStateToProps = state => ({
  processing: state.checkout.progress,
  appSetting: state.app.settings,
  cardId: selector(state, 'card_id'),
  paymentType: selector(state, 'payment_type'),
  cardNumber: selector(state, 'card_number'),
  cardName: selector(state, 'card_name'),
  expDate: selector(state, 'exp_date'),
  cvv: selector(state, 'cvv'),
  shopCountry: state.app.shopCountry
});

export default connect(
  mapStateToProps,
  null
)(PaymentOption);

PaymentOption.defaultProps = {
  paymentType: undefined,
  processing: false,
  handleTabChange: () => {},
  appSetting: {}
};

PaymentOption.propTypes = {
  paymentType: PropTypes.string,
  processing: PropTypes.bool,
  handleTabChange: PropTypes.func,

  appSetting: PropTypes.objectOf(PropTypes.any)
};
