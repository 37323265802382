import React from 'react';

export default function PageLoader() {
  return (
    <div className="preloader">
      <div className="status">
        <div className="bouncing-loader">
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
}

// UNUSED FUNCTION
export function PageLoaderInner() {
  return (
    <div className="preloader-inner">
      <div className="status-inner">
        <div className="bouncing-loader">
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
}

export function DotLoader() {
  return (
    <div className="bouncing-loader">
      <div />
      <div />
      <div />
    </div>
  );
}
