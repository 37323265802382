/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { change, getFormValues } from 'redux-form';

import AdvanceTable from '../../components/AdvanceTable';
import OrderFilterForm from './OrderFilterForm';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import SingleDownloadButton from './SingleDownloadButton';
import { order as orderActions } from '../../actions';
import {
  getCurrencySymbol,
  dateRangesValues,
  getOrderStatusClass,
  getFulfillmentClass
} from '../../utills/custom';
import { downloadBlob } from '../../utills/ajax';

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      selected: []
    };

    this.handleFilter = values => {
      const { formValues, getAllOrders } = this.props;
      const filters = { ...formValues, ...values };
      getAllOrders(filters);
    };
  }

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleInvoiceDownload = async () => {
    const { selected } = this.state;
    if (selected) {
      await axios({
        url: `${
          process.env.REACT_APP_API_URL
        }/orders/download-merged-invoice?id=${selected.join(',')}`,
        method: 'GET',
        headers: { Accept: 'application/pdf' },
        responseType: 'blob'
      }).then(response => {
        downloadBlob(response, `${moment().format('DDMMYYYY')}-INV-Merged.pdf`);
      });
    }
  };

  handleTableChange = (type, options) => {
    const { sizePerPage: perPage, page } = options;
    if (type === 'pagination') this.handleFilter({ page, per_page: perPage });
    this.setState({ page });
  };

  handleDateRange = (event, picker) => {
    const { changeStartDate, changeEndDate, changeDateFilter } = this.props;
    const start = moment(picker.startDate).format('DDMMYYYY');
    const end = moment(picker.endDate).format('DDMMYYYY');

    const filter = dateRangesValues[picker.chosenLabel] || 'custom';
    if (filter) {
      changeDateFilter(filter);
      changeStartDate('');
      changeEndDate('');
    } else {
      changeDateFilter('custom');
      changeStartDate(start);
      changeEndDate(end);
    }
    this.handleFilter();
  };

  render() {
    const { orders, isLoading, appSetting } = this.props;
    const { page, selected } = this.state;
    const columns = [
      {
        dataField: 'invoice_number',
        text: 'Inv. no.',
        formatter: (col, row) => (
          <>
            {row.cart_id ? (
              <Link
                to={`/order/${row.id}/invoice`}
                className="dashed-underline"
              >
                {col}
              </Link>
            ) : (
              <Link
                to={`/subscription/${row.id}/invoice`}
                className="dashed-underline"
              >
                {col}
              </Link>
            )}
          </>
        )
      },
      {
        dataField: 'order_local_date_tz',
        text: 'Date',
        // headerClasses: 'text-center',
        // classes: 'text-center',
        // headerAttrs: { width: '10%' },
        formatter: col => {
          const dateObj = col.split(' ');
          return (
            <span className="date">
              {dateObj[0]}
              <br />
              <small>{dateObj[1]}</small>
            </span>
          );
        }
      },
      {
        dataField: 'original_total',
        text: 'Total Price',
        headerClasses: 'text-center',
        classes: 'text-center',
        // headerAttrs: { width: '10%' },
        formatter: (col, row) => (
          <span className="font-weight-semibold text-primary">
            {`${row.currency ? row.currency.currency_symbol : ''}${col}`}
          </span>
        )
      },
      {
        dataField: 'order_type',
        text: 'Order Type',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '10%' },
        formatter: (col, row) => (
          <span className={row.order_type_class_name}>{col}</span>
        )
      },
      {
        dataField: 'id',
        text: 'Order ID'
      },
      {
        dataField: 'method',
        // headerAttrs: { width: '15%' },
        text: 'Transaction ID',
        formatter: (col, row) => (
          <span>
            {row.order_transaction && row.order_transaction[0]
              ? row.order_transaction[0].transaction_id
              : '---'}
          </span>
        )
      },
      {
        dataField: 'payment_gateway_status',
        text: 'Payment Status',
        headerClasses: 'text-center',
        classes: 'text-center',
        // headerAttrs: { width: '15%' },
        formatter: col => (
          <span className={getOrderStatusClass(col)}>{col}</span>
        )
      },
      {
        dataField: 'fulfillment_status',
        text: 'Fulfillment Status',
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (col, row) => (
          <span className={getFulfillmentClass(col)}>{col || 'None'}</span>
        )
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '8%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            {row.cart_id ? (
              <Link to={`/order/${row.id}/invoice`}>
                <i className="action-icon mdi mdi-eye" />
              </Link>
            ) : (
              <Link to={`/subscription/${row.id}/invoice`}>
                <i className="action-icon mdi mdi-eye" />
              </Link>
            )}
            <SingleDownloadButton
              id={row.id}
              invoice={row.invoice_number}
              type={row.cart_id ? 'order' : 'subscription'}
            />
            {/* <Link to={`/order/${row.order_number}/delete`}><i className="action-icon mdi mdi-trash-can" /></Link> */}
          </>
        )
      }
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.order.breadcrumb} />
              <h4 className="page-title">{routePath.order.title}</h4>
            </div>
          </div>
          {/* <div className="col-12">
            <div className="row mb-2">
              <div className="col-sm-4">
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  ranges={ranges}
                  onApply={this.handleDateRange}
                >
                  <div className="form-group has-icon">
                    <i className="mdi mdi-calendar" />
                    <input type="text" value={dateRangeValue} className="form-control input-medium cursor-pointer bg-white" placeholder="Select date range" readOnly />
                  </div>
                </DateRangePicker>
              </div>
              <div className="col-sm-8 text-sm-right">
                <div className="btn-group btn-group-sm ml-2">
                  <Dropdown>
                    <Dropdown.Toggle size="sm" variant="primary" id="actionDropdown">
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu alignRight>
                      <Dropdown.Item onClick={() => this.handleInvoiceDownload()}>
                        <i className="mdi mdi-file-pdf" />
                        {' '}
                        Download & Merge PDF
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        <i className="mdi mdi-trash-can" />
                        {' '}
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <StatisticsWidget
              isLoading={isLoading}
              // icon="mdi mdi-cart-plus"
              cardClass="p-2"
              description="Orders"
              title={(orderSummary && orderSummary.total) || '0'}
              trend={{
                textClass: orderSummary && orderSummary.total >= orderSummary.previous_total ? 'text-success' : 'text-danger',
                icon: orderSummary && orderSummary.total >= orderSummary.previous_total ? 'mdi mdi-arrow-up-bold' : 'mdi mdi-arrow-down-bold',
                value: orderSummary && orderSummary.percentage,
                time: orderSummary && orderSummary.text
              }}
            />
          </div>
          <div className="col-sm-6 col-lg-3">
            <StatisticsWidget
              isLoading={isLoading}
              // icon="mdi mdi-cash"
              cardClass="p-2"
              description="Sales"
              title={(saleSummary && saleSummary.total) || '0'}
              trend={{
                textClass: saleSummary && saleSummary.total >= saleSummary.previous_total ? 'text-success' : 'text-danger',
                icon: saleSummary && saleSummary.total >= saleSummary.previous_total ? 'mdi mdi-arrow-up-bold' : 'mdi mdi-arrow-down-bold',
                value: saleSummary && saleSummary.percentage,
                time: saleSummary && saleSummary.text
              }}
            />
          </div>
          <div className="col-sm-6 col-lg-3">
            <StatisticsWidget
              // icon="mdi mdi-pulse"
              isLoading={isLoading}
              bgclassName="bg-success"
              cardClass="p-2"
              textClass="text-white"
              description="Profits"
              title="36,254"
              trend={{
                textClass: 'badge badge-light-lighten',
                icon: 'mdi mdi-arrow-up-bold',
                value: '17.26%',
                time: 'Since last month'
              }}
            />
          </div>
          <div className="col-sm-6 col-lg-3">
            <StatisticsWidget
              // icon="mdi mdi-chart-line"
              isLoading={isLoading}
              bgclassName="bg-primary"
              cardClass="p-2"
              textClass="text-white"
              description="Growth"
              title="13%"
              trend={{
                textClass: 'badge badge-info',
                icon: 'mdi mdi-arrow-up-bold',
                value: '17.26%',
                time: 'Since last month'
              }}
            />
          </div> */}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row justify-content-end mb-3 mb-sm-0">
                  <div className="col-lg-6">
                    <OrderFilterForm
                      initialValues={
                        {
                          // filter_option: 'INVOICE_NUMBER',
                          // date_filter: 'yearly'
                        }
                      }
                      submitForm={this.handleFilter}
                      inProgress={isLoading}
                    />
                  </div>
                </div>
                <AdvanceTable
                  keyField="id"
                  data={orders.data}
                  columns={columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={orders.total}
                  className="table-nowrap"
                  disableSelect
                  // sizePerPage={orders.per_page}
                  sizePerPage={orders.per_page}
                  sizePerPageList={orders.filter_values}
                  hideSizePerPage={false}
                  page={page}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
  orders: state.order.allList,
  summary: state.order.summary,
  appSetting: state.app.settings,
  isLoading: state.order.isLoading,
  isSummaryLoading: state.order.isSummaryLoading,
  formValues: getFormValues('orderFilterForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllOrders: orderActions.getAllOrders,
      getSummary: orderActions.getSummary,
      changeStartDate: start => change('orderFilterForm', 'start', start),
      changeEndDate: end => change('orderFilterForm', 'end', end),
      changeDateFilter: option =>
        change('orderFilterForm', 'date_filter', option)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Order);

Order.defaultProps = {
  getAllOrders: () => {},
  formValues: {},
  changeDateFilter: () => {},
  changeStartDate: () => {},
  changeEndDate: () => {},
  orders: {},
  isLoading: false,
  appSetting: {}
};

Order.propTypes = {
  getAllOrders: PropTypes.func,
  formValues: PropTypes.objectOf(PropTypes.any),
  changeDateFilter: PropTypes.func,
  changeStartDate: PropTypes.func,
  changeEndDate: PropTypes.func,
  orders: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool,
  appSetting: PropTypes.objectOf(PropTypes.any)
};
