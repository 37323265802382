import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import menu from '../constants/menu';

function NavMenu({ collapse, toggleMenu }) {
  const [showMenu, setShowMenu] = useState(false);
  const MyLink = React.forwardRef((props, ref) => (
    <NavLink innerRef={ref} {...props} />
  ));
  return (
    <div className="topnav">
      <div className="container-fluid">
        <nav className="navbar navbar-dark navbar-expand-lg topnav-menu">
          <div
            className={`collapse navbar-collapse ${collapse ? '' : 'show'}`}
            id="topnav-menu-content"
          >
            <ul className="navbar-nav">
              {menu.map((item, key) => {
                if (item.children) {
                  return (
                    <li className="nav-item dropdown">
                      <Dropdown key={`drop_${key}`}>
                        <Dropdown.Toggle
                          to="/account"
                          as={MyLink}
                          navbar="true"
                          onMouseEnter={() => setShowMenu(true)}
                          onMouseLeave={() => setShowMenu(false)}
                          // onClick={e => e.preventDefault()}
                          className="nav-link arrow-none"
                          id={`drop_${key}`}
                        >
                          <i className={item.icon} />
                          {item.label}
                          <div className="arrow-down" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          show={showMenu}
                          key={`drop_${key}_menuItem_${key}`}
                        >
                          {item.children.map((subItem, subItemKey) => (
                            <Dropdown.Item
                              key={`drop_${key}_subItem_${subItemKey}`}
                              onClick={toggleMenu}
                              as={NavLink}
                              to={subItem.to}
                            >
                              {subItem.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  );
                }
                return (
                  <li className="nav-item dropdown" key={`drop_${key}`}>
                    <NavLink
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={toggleMenu}
                      to={item.to}
                    >
                      <i className={item.icon} />
                      {item.label}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default NavMenu;

NavMenu.defaultProps = {
  collapse: PropTypes.bool,
  toggleMenu: PropTypes.func
};
NavMenu.propTypes = {
  collapse: PropTypes.bool,
  toggleMenu: PropTypes.func
};
