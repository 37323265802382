import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { order as orderActionTypes } from '../actions';

function* getAllOrder(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/order`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: orderActionTypes.ORDER_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getOrder(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/orderdetail/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: orderActionTypes.ORDER_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: orderActionTypes.ORDER_ERROR,
      data: error
    });
  }
}

function* getTnxOrder(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/ordertnxdetail/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: orderActionTypes.ORDER_TNX_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: orderActionTypes.ORDER_ERROR,
      data: error
    });
  }
}

function* watchOrderListAll() {
  yield takeLatest(orderActionTypes.ORDER_ALL_LIST_REQUEST, getAllOrder);
}

function* watchGetOrder() {
  yield takeLatest(orderActionTypes.ORDER_REQUEST, getOrder);
}

function* watchGetTnxOrder() {
  yield takeLatest(orderActionTypes.ORDER_TNX_REQUEST, getTnxOrder);
}

export default [
  fork(watchOrderListAll),
  fork(watchGetOrder),
  fork(watchGetTnxOrder)
];
