import StorageService from '../services/storageService';

const initialState = {
  app: {
    countryList: [],
    stateList: [],
    cityList: [],
    categoryList: [],
    shippingList: [],
    imageStyle: {},
    settings: {
      site_title: 'MX4',
      def_currency: 'EUR',
      site_date_format: 'DD/MM/YYYY',
      site_def_lang: 'en'
    },
    storeAddress: {},
    storeAddressCountryWise: {},
    companyAddress: {},
    shopCountry: StorageService.getShopCountry() || undefined,
    shopState: StorageService.getShopState() || undefined,
    countryCurrencies: {}
    // shopCountry: undefined,
    // shopState: undefined
  },
  auth: {
    user: {},
    refUserId: '',
    refUserChecked: false,
    loginProgress: false,
    loginError: true,
    registrationProgress: false,
    userInfoProgress: true,
    forgotPasswordSuccess: false,
    forgotPasswordProgress: false,
    resetPasswordProgress: false,
    changePasswordProgress: false
  },
  associate: {
    isLoading: false,
    createProgress: false,
    list: []
  },
  tree: {
    isLoading: false,
    list: [],
    allList: [],
    customers: [],
    loadingCustomer: false
  },
  packages: {
    isLoading: false,
    createProgress: false,
    list: [],
    allList: []
  },
  category: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  product: {
    isLoading: false,
    isLoadingSingle: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  permission: {
    roles: [],
    isLoadingRoles: false,
    users: [],
    isLoadingUsers: false,
    allPermissions: [],
    isProcessingPermissions: false,
    rolePermission: {
      module: [],
      permission: {}
    },
    isLoadingPermissions: false
  },
  generation: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  rank: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  fsb: {
    isLoading: false,
    plan: {},
    updateProgress: false
  },
  associateFsb: {
    isLoading: false,
    plan: {}
  },
  tax: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  notification: [],
  address: {
    isLoading: false,
    createProgress: false,
    list: [],
    editAddress: {},
    updateProgress: false,
    modal: false,
    alert: false
  },
  paymentOption: {
    isLoading: false,
    isCardLoading: false,
    isBankLoading: false,
    inProgress: false,
    cardList: {
      data: []
    },
    bankList: {
      data: []
    },
    editPaymentOption: {},
    modal: false,
    alert: false
  },
  profile: {
    isLoading: false,
    data: [],
    updateProgress: false,
    updateImageProgress: false,
    isReferralProfileValid: false
  },
  template: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  shop: {
    isLoadingList: false,
    isLoadingDetails: false,
    productDetails: {},
    list: {
      data: []
    },
    categoryList: [],
    categoryLoading: false
  },
  cart: {
    cartId: null,
    cartDetails: {},
    cartCalculation: {},
    calculationProgress: false,
    cartShippingOptions: {},
    loadingreviseCart: false
  },
  shipping: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  setting: {
    isLoading: false,
    allList: {
      data: []
    },
    data: {},
    createProgress: false,
    updateProgress: false
  },
  media: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false,
    isScanning: false,
    allFileList: []
  },
  location: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  loginHistory: {
    isLoading: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  order: {
    isLoading: false,
    isLoadingSingle: false,
    allList: {
      data: []
    },
    tnxDetails: {}
  },
  checkout: {
    data: [],
    progress: false
  },
  dashboard: {
    progress: false,
    data: {},
    error: {}
  }
};

export default initialState;
