import { profile as profileActionTypes } from '../actions';
import initialState from '../constants/initialState';

function profile(state = initialState.profile, action) {
  switch (action.type) {
    case profileActionTypes.PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case profileActionTypes.PROFILE_SUCCESS:
      if (action.data) {
        return {
          ...state,
          data: action.data,
          isLoading: false
        };
      }
      return state;
    case profileActionTypes.PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case profileActionTypes.PROFILE_UPDATE_SUCCESS:
      if (action.data) {
        return {
          ...state,
          data: action.data,
          updateProgress: false
        };
      }
      return {
        ...state,
        updateProgress: false
      };
    case profileActionTypes.PROFILE_UPDATE_ERROR:
      return {
        ...state,
        updateProgress: false
      };
    case profileActionTypes.PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        updateImageProgress: true
      };
    case profileActionTypes.PROFILE_IMAGE_SUCCESS:
    case profileActionTypes.PROFILE_IMAGE_ERROR:
      return {
        ...state,
        updateImageProgress: false
      };
    default:
      return state;
  }
}

export default profile;
