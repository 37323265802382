const packages = {
  PACKAGE_LIST_REQUEST: 'PACKAGE_LIST_REQUEST',
  PACKAGE_LIST_SUCCESS: 'PACKAGE_LIST_SUCCESS',
  PACKAGE_ALL_LIST_REQUEST: 'PACKAGE_ALL_LIST_REQUEST',
  PACKAGE_ALL_LIST_SUCCESS: 'PACKAGE_ALL_LIST_SUCCESS',
  PACKAGE_CREATE_REQUEST: 'PACKAGE_CREATE_REQUEST',
  PACKAGE_CREATE_SUCCESS: 'PACKAGE_CREATE_SUCCESS',
  PACKAGE_CREATE_ERROR: 'PACKAGE_CREATE_ERROR',
  PACKAGE_UPDATE_REQUEST: 'PACKAGE_UPDATE_REQUEST',
  PACKAGE_UPDATE_SUCCESS: 'PACKAGE_UPDATE_SUCCESS',
  PACKAGE_UPDATE_ERROR: 'PACKAGE_UPDATE_ERROR',
  PACKAGE_REQUEST: 'PACKAGE_REQUEST',
  PACKAGE_SUCCESS: 'PACKAGE_SUCCESS',
  getPackages: data => ({ type: packages.PACKAGE_LIST_REQUEST, data }),
  getAllPackages: data => ({ type: packages.PACKAGE_ALL_LIST_REQUEST, data }),
  getPackageById: id => ({ type: packages.PACKAGE_REQUEST, id }),
  createPackage: data => ({ type: packages.PACKAGE_CREATE_REQUEST, data }),
  updatePackage: data => ({ type: packages.PACKAGE_UPDATE_REQUEST, data })
};

export default packages;
