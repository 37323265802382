const dashboard = {
  DASHBOARD_REQUEST: 'DASHBOARD_REQUEST',
  DASHBOARD_SUCCESS: 'DASHBOARD_SUCCESS',
  DASHBOARD_ERROR: 'DASHBOARD_ERROR',
  getCpointCalculation: data => ({
    type: dashboard.DASHBOARD_REQUEST,
    data
  })
};

export default dashboard;
