import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { dashboard as dashboardTypes } from '../actions';

function* getCpCalculation(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/customer/dashboard`,
      method: 'GET',
      params: actions.data,
      successMessage: 'C-Point Calculations loaded.'
    });
    if (responseJson) {
      yield put({
        type: dashboardTypes.DASHBOARD_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: dashboardTypes.DASHBOARD_ERROR, data: error });
  }
}

function* watchGetCpCalculation() {
  yield takeLatest(dashboardTypes.DASHBOARD_REQUEST, getCpCalculation);
}

export default [fork(watchGetCpCalculation)];
