import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';

function Account() {
  return (
    <React.Fragment>
      <div className="row mb-2">
        <div className="col-12">
          <div className="page-title-box">
            <BreadCrumb list={routePath.account.breadcrumb} />
            <h4 className="page-title">{routePath.account.title}</h4>
          </div>
        </div>
      </div>
      <div className="row row-eq-height mb-5">
        <div className="col-sm-6 mb-3">
          <Link
            to={routePath.profile.path}
            className="card bg-primary text-white h-100"
          >
            <div className="card-body">
              <div className="text-center">
                <h2 className="mb-2">
                  <i className="dripicons-user" />
                </h2>
                <h4>Profile &amp; Security</h4>
                <p className="card-text">
                  Manage your profile details and change your password.
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-sm-6 mb-3">
          <Link
            to={routePath.addresses.path}
            className="card bg-warning text-white h-100"
          >
            <div className="card-body">
              <div className="text-center">
                <h2 className="mb-2">
                  <i className="dripicons-home" />
                </h2>
                <h4>Addresses</h4>
                <p className="card-text">
                  Manage your billing and shipping address details for smooth
                  payment process.
                </p>
              </div>
            </div>
          </Link>
        </div>
        {/* <div className="col-sm-4 mb-3">
          <Link
            to={routePath.payOptions.path}
            className="card bg-success text-white h-100"
          >
            <div className="card-body">
              <div className="text-center">
                <h2 className="mb-2">
                  <i className="dripicons-card" />
                </h2>
                <h4>Payment Options</h4>
                <p className="card-text">
                  Manage your credit or debit cards and bank account details.
                </p>
              </div>
            </div>
          </Link>
        </div> */}
      </div>
    </React.Fragment>
  );
}
export default Account;
