import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Redirect } from 'react-router';
import Select from 'react-select';

export default function CountryModal({
  countryList,
  stateList,
  handleSubmit,
  getStateByCountry
}) {
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [home, setHome] = useState(false);
  const getValue = () => countryList.filter(val => val.value === country);
  const getValueState = () =>
    stateList.filter(val => val.value === parseInt(state, 10));
  const handleChangeCountry = input => {
    if (input && input.value) {
      setCountry(input.value);
      setState('');
      getStateByCountry(input.value);
    }
  };
  const handleChangeState = input => {
    if (input && input.value) {
      setState(input.value);
    }
  };
  const handleEnterEscKey = e => {
    const keyType = e.key;
    if (keyType === 'Enter') handleSubmit(country, state);
    else if (keyType === 'Escape') setHome(true);
  };
  useEffect(() => {
    document.addEventListener('keydown', handleEnterEscKey);
    return () => {
      document.removeEventListener('keydown', handleEnterEscKey);
    };
  });
  if (home) return <Redirect path="/" />;
  return (
    <Modal show backdrop="static">
      <Modal.Header>
        <Modal.Title className="font-17">
          Choose your Shipping Country
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <Select
            value={getValue()}
            onChange={handleChangeCountry}
            placeholder="Choose Country..."
            options={countryList}
            className="react-select"
            classNamePrefix="react-select"
          />
        </div>
        {/* <div className="form-group">
          <Select
            value={getValueState()}
            onChange={handleChangeState}
            placeholder="Choose State"
            options={stateList || []}
            className="react-select"
            classNamePrefix="react-select"
          />
        </div> */}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="primary"
          className="btn-lg pl-4 pr-4"
          onClick={() => handleSubmit(country, state)}
        >
          Go
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
