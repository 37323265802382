import React from 'react';
import PropTypes from 'prop-types';
import { change, Field, Form, getFormValues, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import { selectField, inputField } from '../../components/Fields';
import btnLoader from '../../assets/images/btn-loader.gif';

const statusOptions = [
  { value: '', label: 'All Status' },
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'SUCCESS', label: 'Success' },
  { value: 'FAILED', label: 'Failed' }
];

class OrderFilterForm extends React.PureComponent {
  componentDidMount() {
    const { handleSubmit } = this.props;
    setTimeout(() => handleSubmit(), 0);
  }

  handleFilterOption = val => {
    const { dispatch } = this.props;
    dispatch(change('orderFilterForm', 'filter_option', val));
    dispatch(change('orderFilterForm', 'filter', ''));
    // if (formValues.filter) this.handleChangeFilter();
  };

  handleChangeFilter = () => {
    const { handleSubmit } = this.props;
    setTimeout(() => handleSubmit(), 0);
  };

  render() {
    const { handleSubmit, submitForm, isLoading } = this.props;

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row">
          <div className="col-12 col-md-5">
            <Field name="start" component="text" type="hidden" />
            <Field name="end" component="text" type="hidden" />
            <Field name="filter_option" component="text" type="hidden" />
            <Field name="date_filter" component="text" type="hidden" />
            <Field
              name="filter"
              component={inputField}
              className="form-control"
              // label="Search"
              placeholder="Inv. no."
              // onChange={this.handleChangeFilter}
            />
            {/* <Field
              name="filter"
              component={inputGroupField}
              className="form-control"
              // label="Search"
              placeholder={formValues.filter_option === 'INVOICE_NUMBER' ? 'Inv. no.' : 'UUID or Name'}
              // onChange={this.handleChangeFilter}
            >
              <Dropdown>
                <Dropdown.Toggle variant="light" drop="right" id="filterOptionDropdown">
                  {formValues.filter_option === 'INVOICE_NUMBER' ? 'Invoice' : 'Associate or Customer'}
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight>
                  <Dropdown.Item onClick={() => this.handleFilterOption('INVOICE_NUMBER')}>

                    Invoice
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => this.handleFilterOption('USERS')}>

                    Associate or Customer
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Field> */}
          </div>
          <div className="col-12 col-md-4">
            <Field
              name="payment_status"
              component={selectField}
              // multiple
              notSearchable
              // label="Payment Status"
              placeholder="All Status"
              options={statusOptions}
              // onChange={this.handleChangeFilter}
            />
          </div>
          <div className="col-12 col-md-3">
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary btn-block"
            >
              {isLoading ? <img src={btnLoader} alt="" /> : 'Filter'}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('orderFilterForm')(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const OrderFilterFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderFilterForm);

export default reduxForm({
  form: 'orderFilterForm' // a unique identifier for this form
})(OrderFilterFormConnected);

OrderFilterForm.defaultProps = {
  handleSubmit: () => {},
  submitForm: () => {},
  isLoading: false
};

OrderFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitForm: PropTypes.func,
  isLoading: PropTypes.bool
};
