import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { OverlayLoader } from './InnerLoader';

/**
 * Statistics widget
 */
function StatisticsWidget({
  textClass,
  cardClass,
  bgclassName,
  icon,
  title,
  description,
  trend,
  children,
  isLoading
}) {
  const textCls = textClass;
  return (
    <Card className={bgclassName}>
      {isLoading && <OverlayLoader type="spinner" />}
      <Card.Body className={cardClass}>
        {icon && (
          <div className="float-right">
            <i
              className={classNames(
                icon,
                'widget-icon',
                'ml-2',
                'bg-secondary',
                'text-white',
                'rounded-circle'
              )}
            />
          </div>
        )}
        {description && (
          <h5
            className={classNames(
              'font-weight-semibold',
              'font-13',
              'mt-0',
              'text-uppercase',
              textCls
            )}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {title && <h4 className={classNames('mt-3', textCls)}>{title}</h4>}
        {children}
        {trend && (
          <p
            className={classNames(
              'mb-0',
              'mt-2',
              'font-weight-semibold',
              'font-12',
              textCls
            )}
          >
            <span className={classNames(trend.textClass, 'mr-2')}>
              <i className={classNames(trend.icon)} />
              <span>{trend.value}</span>
            </span>
            <span className="text-nowrap1">{trend.time}</span>
          </p>
        )}
      </Card.Body>
    </Card>
  );
}

export default StatisticsWidget;
