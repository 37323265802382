/* global location */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import StorageService from '../../services/storageService';
import { app as appActions } from '../../actions';
import CountryModal from './CountryModal';

class ShopFilterForm extends React.PureComponent {
  componentDidMount() {
    const { getCountry, shopCountry, getStateByCountry } = this.props;
    getCountry();
    if (shopCountry) {
      getStateByCountry(shopCountry);
    }
  }

  /* catOptions = (cats) => {
    const options = [];
    cats.map(cat => options.push({ label: `${hierarchyDash(cat.hierarchyLevel)} ${cat.title}`, value: cat.id }));
    return options;
  }; */

  handleShopCountry = country => {
    if (country) {
      const {
        setShopCountry,
        setShopState,
        getStateByCountry,
        shopCountry
      } = this.props;
      // only set sho state to null if shopCountry was already set
      if (shopCountry) {
        setShopState(null);
      }

      setShopCountry(country);

      getStateByCountry(country);
      StorageService.setShopCountry(country);
    }
  };

  handleModalSubmit = (country, state) => {
    if (country) {
      const { setShopCountry, setShopState, getStateByCountry } = this.props;
      setShopCountry(country);
      setShopState(state || null);
      StorageService.setShopCountry(country);
      StorageService.setShopState(state);

      getStateByCountry(country);
    }
  };

  handleShopState = state => {
    const { setShopState } = this.props;
    if (state) {
      setShopState(state);
      StorageService.setShopState(state);
    }
  };

  getValueCountry = () => {
    const { countryList, shopCountry } = this.props;
    return countryList.filter(val => val.value === shopCountry);
  };

  getValueState = () => {
    const { stateList, shopState } = this.props;
    return stateList.filter(val => val.value === parseInt(shopState, 10));
  };

  render() {
    const {
      layoutStyle,
      handleLayout,
      categoryList,
      countryList,
      stateList,
      hideGridLayout,
      hideCategory,
      shopCountry,
      getStateByCountry,
      selectedCategory,
      onCategoryChange
    } = this.props;

    let categoryVal = '';

    const categoryOptions = categoryList.map(item => {
      if (selectedCategory === item.slug) {
        categoryVal = {
          label: item.title,
          value: item.slug
        };
      }
      return {
        label: item.title,
        value: item.slug
      };
    });

    return (
      <>
        <div className="row mb-3">
          <div className="col-12 col-md-4">
            <Select
              value={this.getValueCountry()}
              onChange={input => {
                if (input) {
                  this.handleShopCountry(input.value);
                }
              }}
              placeholder="Choose Country"
              options={countryList}
              className="react-select"
              classNamePrefix="react-select"
            />
          </div>
          {/* <div className="col-12 col-md-3">
            <Select
              value={this.getValueState()}
              onChange={input => {
                if (input) {
                  this.handleShopState(input.value);
                }
              }}
              placeholder="Choose State"
              options={stateList}
              className="react-select"
              classNamePrefix="react-select"
            />
          </div> */}
          {!hideCategory ? (
            <div className="col-12 col-md-4">
              <Select
                value={categoryVal}
                onChange={input => {
                  if (input) {
                    onCategoryChange(input.value);
                  }
                }}
                placeholder="Choose Category"
                options={categoryOptions}
                className="react-select"
                classNamePrefix="react-select"
              />
            </div>
          ) : null}
          {!hideGridLayout && (
            <div className="col-12 col-sm-12">
              <div className="float-right">
                <div className="btn-group ml-2 d-none d-md-inline-block">
                  <button
                    type="button"
                    onClick={() => handleLayout('grid')}
                    className={
                      layoutStyle === 'grid'
                        ? 'btn btn-secondary'
                        : 'btn btn-link text-muted'
                    }
                  >
                    <i className="dripicons-view-apps" />
                  </button>
                </div>
                <div className="btn-group d-none d-md-inline-block">
                  <button
                    type="button"
                    onClick={() => handleLayout('list')}
                    className={
                      layoutStyle === 'list'
                        ? 'btn btn-secondary'
                        : 'btn btn-link text-muted'
                    }
                  >
                    <i className="dripicons-checklist" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {!shopCountry && (
          <CountryModal
            countryList={countryList}
            stateList={stateList}
            handleSubmit={this.handleModalSubmit}
            getStateByCountry={getStateByCountry}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.app.countryList,
  stateList: state.app.stateList,
  categoryList: state.shop.categoryList,
  shopCountry: state.app.shopCountry,
  shopState: state.app.shopState
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPrefetchCategory: appActions.getPrefetchCategory,
      getCountry: appActions.getCountry,
      getStateByCountry: appActions.getStateByCountry,
      setShopCountry: appActions.setShopCountry,
      setShopState: appActions.setShopState
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopFilterForm);
