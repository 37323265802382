import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  generation as generationActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllGeneration() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/generation`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: generationActionTypes.GENERATION_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getGeneration(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/generation/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: generationActionTypes.GENERATION_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* createGeneration(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/generation`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Generation was addedd successfully'
    });
    if (responseJson) {
      yield put({
        type: generationActionTypes.GENERATION_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.generation.path
      });
    }
  } catch (error) {
    yield put({
      type: generationActionTypes.GENERATION_CREATE_ERROR,
      data: error
    });
  }
}

function* updateGeneration(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/generation/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Generation updated successfully'
    });
    if (responseJson) {
      yield put({
        type: generationActionTypes.GENERATION_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.generation.path
        });
      }
    }
  } catch (error) {
    yield put({
      type: generationActionTypes.GENERATION_UPDATE_ERROR,
      data: error
    });
  }
}

function* watchPackageListAll() {
  yield takeLatest(
    generationActionTypes.GENERATION_ALL_LIST_REQUEST,
    getAllGeneration
  );
}

function* watchGetGeneration() {
  yield takeLatest(generationActionTypes.GENERATION_REQUEST, getGeneration);
}

function* watchGenerationCreate() {
  yield takeLatest(
    generationActionTypes.GENERATION_CREATE_REQUEST,
    createGeneration
  );
}

function* watchGenerationUpdate() {
  yield takeLatest(
    generationActionTypes.GENERATION_UPDATE_REQUEST,
    updateGeneration
  );
}

export default [
  fork(watchPackageListAll),
  fork(watchGenerationCreate),
  fork(watchGenerationUpdate),
  fork(watchGetGeneration)
];
