import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { checkout as checkoutActions } from '../../actions';
import BreadCrumb from '../../components/BreadCrumb';
import routerPath from '../../constants/routePath';
import PageLoader from '../../components/PageLoader';

class Cancel extends Component {
  mounted = false;

  state = {
    orderId: null,
    error: null,
    success: null,
    redirect: null
  };

  componentDidMount() {
    this.mounted = true;
    const {
      location: { search },
      cancelPayment
    } = this.props;

    const params = new URLSearchParams(search);
    const order = params.get('order');

    if (order) {
      if (this.mounted) {
        this.setState({ orderId: order });
      }
      cancelPayment({ order_id: order });
    }
  }

  componentWillReceiveProps(props) {
    const {
      data: { error, data }
    } = props;
    if (error) {
      if (this.mounted) {
        this.setState({
          error: error.order_id ? error.order_id[0] : error.message
        });
      }
      setTimeout(() => {
        if (this.mounted) {
          this.setState({
            redirect: routerPath.checkout.path
          });
        }
      }, 5000);
    } else {
      this.setState({ success: data.message });
      setTimeout(() => {
        if (this.mounted) {
          this.setState({
            redirect: routerPath.shop.path
          });
        }
      }, 5000);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { progress } = this.props;
    const { orderId, error, success, redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <div>
        <div className="page-title-box">
          <BreadCrumb list={['checkout']} />
          <h4 className="page-title">Checkout</h4>
        </div>
        {progress === true ? (
          <PageLoader />
        ) : (
          <div className="my-4 text-center">
            <div className="row align-items-center">
              <div className="col-md-12 my-auto font-weight-bold font-16">
                <div className="text-danger font-16 my-2">
                  {orderId === null && (
                    <div>
                      <i className="mdi mdi-close bg-danger rounded-circle text-white widget-icon ml-2 rounded-circle my-2" />
                      <div>Order ID is Invalid</div>
                      <a href="/" className="btn btn-danger my-2">
                        Go to Dashboard
                      </a>
                    </div>
                  )}
                  {orderId !== null && error && (
                    <div>
                      <i className="mdi mdi-close bg-danger rounded-circle text-white widget-icon ml-2 rounded-circle my-2" />
                      <div>{error}</div>
                      <div className="font-14 font-weight-light mt-2">
                        Redirecting to Checkout Page...
                      </div>
                    </div>
                  )}
                  {orderId !== null && success && (
                    <div className="text-success">
                      <i className="mdi mdi-check bg-success rounded-circle text-white widget-icon ml-2 rounded-circle my-2" />
                      <div>{success}</div>
                      <div className="font-14 font-weight-light mt-2">
                        Redirecting to Shop Page...
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  progress: state.checkout.progress,
  formValues: getFormValues('checkoutForm')(state),
  data: state.checkout
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      cancelPayment: checkoutActions.cancelPayment
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cancel);
