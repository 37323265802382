const auth = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  AFFILIATE_LOGIN_REQUEST: 'AFFILIATE_LOGIN_REQUEST',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERROR: 'REGISTER_ERROR',
  REF_USER_ID_REQUEST: 'REF_USER_ID_REQUEST',
  REF_USER_ID_SUCCESS: 'REF_USER_ID_SUCCESS',
  REF_USER_ID_ERROR: 'REF_USER_ID_ERROR',
  USER_INFO_REQUEST: 'USER_INFO_REQUEST',
  USER_INFO_SUCCESS: 'USER_INFO_SUCCESS',
  USER_INFO_ERROR: 'USER_INFO_ERROR',
  SUBSCRIPTION_REQUEST: 'SUBSCRIPTION_REQUEST',
  SUBSCRIPTION_SUCCESS: 'SUBSCRIPTION_SUCCESS',
  SUBSCRIPTION_ERROR: 'SUBSCRIPTION_ERROR',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
  doLogin: data => ({ type: auth.LOGIN_REQUEST, data }),
  doAffiliateLogin: data => ({ type: auth.AFFILIATE_LOGIN_REQUEST, data }),
  doLogout: data => ({ type: auth.LOGOUT_REQUEST, data }),
  doRegister: data => ({ type: auth.REGISTER_REQUEST, data }),
  getUserInfo: data => ({ type: auth.USER_INFO_REQUEST, data }),
  subscribe: data => ({ type: auth.SUBSCRIPTION_REQUEST, data }),
  forgotPassword: data => ({ type: auth.FORGOT_PASSWORD_REQUEST, data }),
  resetPassword: data => ({ type: auth.RESET_PASSWORD_REQUEST, data }),
  changePassword: data => ({ type: auth.CHANGE_PASSWORD_REQUEST, data }),
  getRefUserId: data => ({ type: auth.REF_USER_ID_REQUEST, data })
};

export default auth;
