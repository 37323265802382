import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import {
  required,
  requiredIfSavedCard,
  number,
  requiredIfNotPickupFromStore
} from '../../utills/validator';

const CheckoutForm = ({ handleSubmit, submitCheckout }) => (
  <Form noValidate onSubmit={handleSubmit(submitCheckout)}>
    <Field
      name="billing_address_id"
      component="input"
      type="hidden"
      validate={[required]}
    />
    <Field
      name="shipping_address_id"
      component="input"
      type="hidden"
      validate={[requiredIfNotPickupFromStore]}
    />
    <Field
      name="pickup_from_store"
      component="input"
      type="hidden"
      validate={[number]}
    />
    <Field
      name="shipping_mode"
      component="input"
      type="hidden"
      validate={[requiredIfNotPickupFromStore]}
    />
    <Field
      name="payment_type"
      component="input"
      type="hidden"
      validate={[required]}
    />
    <Field
      name="card_id"
      component="input"
      type="hidden"
      validate={[requiredIfSavedCard]}
    />
    <Field
      name="cp_cart_items[]"
      component="input"
      type="hidden"
      validate={[required]}
    />
    <Field name="coupon_code" component="input" type="hidden" />
    {/* <Field
      name="card_type"
      component="input"
      type="hidden"
      validate={[requiredIfCard]}
    />
    <Field
      name="card_number"
      component="input"
      type="hidden"
      validate={[requiredIfCard]}
    />
    <Field
      name="card_name"
      component="input"
      type="hidden"
      validate={[requiredIfCard]}
    />
    <Field
      name="exp_year"
      component="input"
      type="hidden"
      validate={[requiredIfCard, ccExpYear]}
    />
    <Field
      name="exp_month"
      component="input"
      type="hidden"
      validate={[requiredIfCard, ccExpMonth]}
    />
    <Field
      name="cvv"
      component="input"
      type="hidden"
      validate={[requiredIfCard, ccv]}
    /> */}
  </Form>
);

export default reduxForm({
  form: 'checkoutForm' // a unique identifier for this form
})(CheckoutForm);

CheckoutForm.defaultProps = {
  handleSubmit: () => {},
  submitCheckout: () => {}
};

CheckoutForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitCheckout: PropTypes.func
};
