import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { shop as shopAction } from '../../actions';

class SearchComponent extends Component {
  searchProductList = debounce(searchTerm => {
    const { getSearchTerm } = this.props;

    this.setState({ text: searchTerm });
    getSearchTerm(searchTerm);
  }, 1000);

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: props.searchTerm,
      text: ''
    };
    this.setSearchTerm = this.setSearchTerm.bind(this);
    this.searchProductList = this.searchProductList.bind(this);
  }

  setSearchTerm(searchTerm) {
    this.setState({ searchTerm, text: '' });
    this.searchProductList(searchTerm);
  }

  render() {
    const { searchTerm, text } = this.state;
    const { isSearching, products } = this.props;
    return (
      <div>
        <div>
          <input
            placeholder="Search Products..."
            className="form-control"
            onChange={e => {
              this.setSearchTerm(e.target.value);
            }}
          />
        </div>
        {text && !isSearching && (
          <div className="block my-2">
            <small>
              Search results for &#34;{searchTerm}&#34; -{' '}
              <b>{products.total}&nbsp;</b>
              record(s) found!
            </small>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  products: state.shop.list,
  isSearching: state.shop.isSearching
});

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(
//     {
//       searchItem: shopAction.searchItem
//     },
//     dispatch
//   );

const SearchComponentWithredux = connect(
  mapStateToProps,
  null
)(SearchComponent);

function Search(props) {
  return <SearchComponentWithredux {...props} />;
}

export default Search;

SearchComponent.defaultProps = {
  getSearchTerm: () => {},
  searchTerm: undefined,
  isSearching: false,
  products: {}
};

SearchComponent.propTypes = {
  getSearchTerm: PropTypes.func,
  searchTerm: PropTypes.string,
  isSearching: PropTypes.bool,
  products: PropTypes.objectOf(PropTypes.any)
};
