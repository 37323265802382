import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { Button, Form } from 'react-bootstrap';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment';
import { debounce } from 'lodash';
import {
  dateField,
  inputField,
  radioField,
  selectField,
  phoneField
} from '../../components/Fields';

import { email, required } from '../../utills/validator';
import { app as appActions, profile as profileActions } from '../../actions';
import ProfileImageForm from './ProfileImageForm';
import btnLoader from '../../assets/images/btn-loader.gif';

class ProfileForm extends React.Component {
  state = {
    uploadPic: false
  };

  constructor(props) {
    super(props);
    this.stateChangeDebounce = debounce(
      this.checkProfileAvailabilityHandler,
      500
    );
  }

  componentDidMount() {
    const {
      getCountry,
      getCityByState,
      getStateByCountry,
      initialValues
    } = this.props;
    // Fetch state and city list
    getCountry();

    if (initialValues) {
      if (initialValues.country) {
        getStateByCountry(initialValues.country);
      }
      if (initialValues.state) {
        getCityByState(initialValues.state);
      }
    }
  }

  countryChange = val => {
    const { dispatch, getStateByCountry } = this.props;
    dispatch(change('editProfileForm', 'city', ''));
    dispatch(change('editProfileForm', 'state', ''));
    if (val) {
      getStateByCountry(val);
    }
  };

  stateChange = val => {
    const { dispatch, getCityByState } = this.props;
    dispatch(change('editProfileForm', 'city', ''));
    if (val) {
      getCityByState(val);
    }
  };

  openUploadPicForm = () => {
    const { uploadPic } = this.state;
    this.setState({ uploadPic: !uploadPic });
  };

  submitForm = values => {
    const { updateProfile, checkProfileAvailability } = this.props;
    let { city } = values;
    if (values.city && values.city === 'others' && values.otherCity) {
      city = values.otherCity;
    }
    const fieldValues = {
      id: values.id,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      gender: values.gender,
      dob: values.dob
        ? moment(values.dob, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : undefined,
      country: values.country,
      state: values.state,
      city,
      company_name: values.company_name,
      tax_number: values.tax_number,
      ref_url: values.referral_link,
      phone_number: values.phone_number
        ? values.phone_number.replace(/\D/g, '')
        : undefined
    };
    // console.log(fieldValues);
    updateProfile(fieldValues);
  };

  checkProfileAvailabilityHandler = () => {
    // eslint-disable-next-line react/prop-types
    const { checkProfileAvailability, formValues } = this.props;
    checkProfileAvailability(formValues.referral_link);
  };

  render() {
    const {
      countryList,
      stateList,
      cityList,
      handleSubmit,
      pristine,
      submitting,
      inProgress,
      initialValues,
      formValues,
      // eslint-disable-next-line react/prop-types
      profile
    } = this.props;

    return (
      <div className="row">
        <div className="col-sm-3">
          <ProfileImageForm />
        </div>
        <div className="col-sm-9">
          <Form
            className="needs-validation"
            noValidate
            onSubmit={handleSubmit(this.submitForm)}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mb-0">
                  <h4 className="font-weight-semibold mb-0">
                    {initialValues && initialValues.uuid}
                  </h4>
                  <div className="font-13 mb-3 font-weight-semibold">
                    Cust ID
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <Field
                  name="first_name"
                  className="form-control"
                  type="text"
                  component={inputField}
                  placeholder="First name"
                  id="first-name"
                  label="First Name"
                  validate={[required]}
                />
              </div>
              <div className="col-md-6">
                <Field
                  name="last_name"
                  className="form-control"
                  type="text"
                  component={inputField}
                  placeholder="Last name"
                  id="last-name"
                  label="Last Name"
                  validate={[required]}
                />
              </div>
              <div className="col-md-6">
                <Field
                  name="gender"
                  options={[
                    { value: 'male', label: 'Male' },
                    { value: 'female', label: 'Female' }
                  ]}
                  isInline
                  id="gender"
                  component={radioField}
                  label="Gender"
                  validate={[required]}
                />
              </div>
              <div className="col-md-6">
                <Field
                  name="dob"
                  component={dateField}
                  type="text"
                  className="form-control"
                  label="Date of Birth"
                />
              </div>
              <div className="col-md-6">
                <Field
                  name="email"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="Email Address"
                  placeholder="e.g. hello@example.com"
                  validate={[required, email]}
                />
              </div>
              <div className="col-md-6">
                <Field
                  name="phone_number"
                  component={phoneField}
                  className="form-control"
                  placeholder="xxx xxx xxxx"
                  label="Phone or Mobile"
                />
              </div>
              <div className="col-xs-12 col-md-4">
                <Field
                  disabled
                  name="country"
                  id="country"
                  component={selectField}
                  label="Country"
                  feedback={this.countryChange}
                  options={countryList}
                  validate={[required]}
                />
              </div>
              <div className="col-xs-12 col-md-4">
                <Field
                  name="state"
                  id="state"
                  component={selectField}
                  label="Region"
                  feedback={this.stateChange}
                  options={stateList}
                />
              </div>
              <div className="col-xs-12 col-md-4">
                <Field
                  name="city"
                  id="city"
                  component={selectField}
                  label="City"
                  options={[{ label: 'Others', value: 'others' }, ...cityList]}
                />
              </div>
              {formValues.city === 'others' && (
                <div className="col-xs-12 col-md-12">
                  <Field
                    name="otherCity"
                    id="otherCity"
                    component={inputField}
                    type="text"
                    className="form-control"
                    label="City name"
                    validate={[required]}
                  />
                </div>
              )}
              {initialValues && initialValues.user_type !== 'individual' && (
                <div className="col-md-6">
                  <Field
                    name="company_name"
                    component={inputField}
                    type="text"
                    label="Company Name"
                    className="form-control"
                    placeholder=""
                  />
                </div>
              )}
              <div className="col-md-12">
                <Field
                  name="referral_link"
                  component={inputField}
                  type="text"
                  label="Referral Profile Name"
                  className="form-control"
                  placeholder=""
                  feedback={this.stateChangeDebounce}
                />
              </div>
              {(profile.isReferralProfileValid ||
                initialValues.customer_referral_url) && (
                <div className="col-md-12">
                  <Field
                    name="customer_referral_url"
                    component={inputField}
                    readOnly
                    type="text"
                    label="Customer Referral URL"
                    className="form-control"
                    placeholder=""
                  />
                </div>
              )}
              <div className="col-md-12">
                <div className="form-group mt-23">
                  <Button
                    type="submit"
                    disabled={pristine || submitting}
                    variant="primary"
                  >
                    {inProgress ? (
                      <img src={btnLoader} alt="" />
                    ) : (
                      'Save Changes'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.app.countryList,
  stateList: state.app.stateList,
  cityList: state.app.cityList,
  profile: state.profile,
  formValues: getFormValues('editProfileForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountry: appActions.getCountry,
      getStateByCountry: appActions.getStateByCountry,
      getCityByState: appActions.getCityByState,
      updateProfile: profileActions.updateProfile,
      checkProfileAvailability: profileActions.checkProfileAvailability
    },
    dispatch
  );

const EditProfileFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileForm);

export default reduxForm({
  form: 'editProfileForm' // a unique identifier for this form
})(EditProfileFormWithRedux);

ProfileForm.defaultProps = {
  getCountry: () => {},
  getCityByState: () => {},
  getStateByCountry: () => {},
  initialValues: {},
  updateProfile: () => {},
  countryList: [],
  stateList: [],
  cityList: [],
  handleSubmit: () => {},
  pristine: false,
  submitting: false,
  inProgress: false,
  formValues: {}
};

ProfileForm.propTypes = {
  getCountry: PropTypes.func,
  getCityByState: PropTypes.func,
  getStateByCountry: PropTypes.func,
  initialValues: PropTypes.objectOf(PropTypes.any),
  updateProfile: PropTypes.func,
  countryList: PropTypes.arrayOf(PropTypes.object),
  stateList: PropTypes.arrayOf(PropTypes.object),
  cityList: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  inProgress: PropTypes.bool,
  formValues: PropTypes.objectOf(PropTypes.any)
};
