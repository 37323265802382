import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  packages as packageActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getPackages() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/package`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: packageActionTypes.PACKAGE_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAllPackages() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/all-package`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: packageActionTypes.PACKAGE_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getPackage(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/package/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: packageActionTypes.PACKAGE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* createPackage(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/package/add`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Package was addedd successfully'
    });
    if (responseJson) {
      yield put({
        type: packageActionTypes.PACKAGE_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.associatePackage.path
      });
    }
  } catch (error) {
    yield put({ type: packageActionTypes.PACKAGE_CREATE_ERROR, data: error });
  }
}

function* updatePackage(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/package/${actions.data.id}/edit`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Package updated successfully'
    });
    if (responseJson) {
      yield put({
        type: packageActionTypes.PACKAGE_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.associatePackage.path
        });
      }
    }
  } catch (error) {
    yield put({ type: packageActionTypes.PACKAGE_UPDATE_ERROR, data: error });
  }
}

function* watchPackageList() {
  yield takeLatest(packageActionTypes.PACKAGE_LIST_REQUEST, getPackages);
}

function* watchPackageListAll() {
  yield takeLatest(packageActionTypes.PACKAGE_ALL_LIST_REQUEST, getAllPackages);
}

function* watchGetPackage() {
  yield takeLatest(packageActionTypes.PACKAGE_REQUEST, getPackage);
}

function* watchPackageCreate() {
  yield takeLatest(packageActionTypes.PACKAGE_CREATE_REQUEST, createPackage);
}

function* watchPackageUpdate() {
  yield takeLatest(packageActionTypes.PACKAGE_UPDATE_REQUEST, updatePackage);
}

export default [
  fork(watchPackageList),
  fork(watchPackageListAll),
  fork(watchGetPackage),
  fork(watchPackageCreate),
  fork(watchPackageUpdate)
];
