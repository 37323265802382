/* global appConfig */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { get } from 'lodash';
import { auth as authActions } from '../actions';
import NavMenu from './NavMenu';
import avatar from '../assets/images/avatar.svg';
import routePath from '../constants/routePath';
import CartDropdown from '../containers/Cart/CartDropdown';
import { profileImage } from '../constants/imageSize';
import Image from './Image';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapse: true };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  toggleMenu = () => {
    this.setState(a => ({
      collapse: !a.collapse
    }));
  };

  handleClickOutside(event) {
    const { collapse } = this.state;
    if (
      !collapse &&
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target)
    ) {
      this.setState({ collapse: false });
    }
  }

  render() {
    const { doLogout, userInfo } = this.props;
    const { collapse } = this.state;
    let pImage = get(userInfo, 'profile_picture_file.file_path', null);
    if (pImage) {
      pImage = `${process.env.REACT_APP_IMAGE_URL}/${pImage}`;
    } else {
      pImage = avatar;
    }

    return (
      <>
        <nav className="navbar-custom topnav-navbar">
          <div className="container-fluid">
            <button
              type="button"
              onClick={() => this.toggleMenu()}
              className={`button-menu-mobile open-left disable-btn text-left p-0 ${
                collapse ? 'collapsed' : 'open'
              }`}
            >
              <i className={`mdi mdi-${collapse ? 'menu' : 'close'}`} />
            </button>
            <Link className="topnav-logo" to={routePath.shop.path}>
              <span className="topnav-logo-lg">
                <img
                  className=""
                  src={appConfig.logo}
                  alt="{appConfig.logoALT}"
                  height="40"
                />
              </span>
              <span className="topnav-logo-sm">
                <img
                  className=""
                  src={appConfig.logo}
                  alt="{appConfig.logoALT}"
                  height="16"
                />
              </span>
            </Link>
            <ul className="list-unstyled topbar-right-menu float-right mb-0">
              <li className="notification-list cart-dropdown topbar-dropdown d-lg-block">
                <CartDropdown />
              </li>
              {/* <li className="dropdown notification-list">
              <Dropdown>
                <Dropdown.Toggle size="sm" variant="link" id="notiDropdown" className="nav-link dropdown-toggle arrow-none">
                  <i className="dripicons-bell noti-icon" />
                  <span className="custom-icon-badge">2</span>
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight className="notification-header-dropdown dropdown-lg">
                  <Dropdown.Header className="dropdown-header noti-title">
                    <h5 className="m-0">
                      <span className="float-right">
                        <Link to={routePath.account.path} className="text-dark">
                          <small>Clear All</small>
                        </Link>
                      </span>
                      Notification
                    </h5>
                  </Dropdown.Header>
                  <Dropdown.Item as={Link} to={routePath.account.path} className="notify-item">
                    <div className="notify-icon bg-primary">
                      <i className="mdi mdi-comment-account-outline" />
                    </div>
                    <p className="notify-details">Karen Robinson</p>
                    <p className="text-muted mb-0 user-msg">
                      <small>Wow ! this admin looks good and awesome design</small>
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={routePath.account.path} className="notify-item">
                    <div className="notify-icon bg-warning">
                      <i className="mdi mdi-comment-account-outline" />
                    </div>
                    <p className="notify-details">Karen Robinson</p>
                    <p className="text-muted mb-0 user-msg">
                      <small>Wow ! this admin looks good and awesome design</small>
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={routePath.account.path} className="text-center text-primary notify-item notify-all">
                    View all
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
              <li className="dropdown notification-list">
                <Dropdown>
                  <Dropdown.Toggle
                    size="sm"
                    variant="link"
                    id="userDropdown"
                    className="nav-link dropdown-toggle nav-user arrow-none mr-0"
                  >
                    <span className="account-user-avatar d-none d-sm-inline-block">
                      <Image
                        className="rounded-circle"
                        src={pImage}
                        alt={`${userInfo.first_name} ${userInfo.last_name}`}
                        size={profileImage.profileSmall}
                      />
                    </span>
                    <span>
                      <span className="account-user-name">
                        <span className="d-none d-sm-inline-block">
                          Welcome,
                        </span>{' '}
                        {userInfo.first_name}!
                      </span>
                      <span
                        className={`d-none1 d-sm-inline-block1 flag-icon flag-icon-${
                          userInfo.customer_info &&
                          userInfo.customer_info.country
                            ? userInfo.customer_info.country.toLowerCase()
                            : ''
                        } mr-1`}
                      />
                      <span className="d-none1 d-sm-inline-block1 account-position u-id">{`${userInfo.uuid}`}</span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight>
                    <Dropdown.Item as={Link} to={routePath.account.path}>
                      <i className="mdi mdi-account-circle mr-1" />
                      <span>My Account</span>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={routePath.profile.path}>
                      <i className="mdi mdi-account-edit mr-1" />
                      <span>Profile</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={doLogout} to="/">
                      <i className="mdi mdi-logout mr-1" />
                      <span>Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            {/* <div className="app-search">
            <form>
              <div className="input-group" id="custom-search">
                <input type="text" className="form-control" placeholder="Search..." />
                <span className="mdi mdi-magnify" />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="submit">Search</button>
                </div>
              </div>
            </form>
            <Link className="navbar-brand" to={routePath.dashboard}>
              <img className="logo-img d-none d-sm-inline-block" src={logo} alt="" width="120" />
              <img className="logo-img d-sm-none" src={logo} alt="" width="80" />
            </Link>
          </div> */}
          </div>
        </nav>
        <div ref={this.setWrapperRef}>
          <NavMenu collapse={collapse} toggleMenu={this.toggleMenu} />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogout: authActions.doLogout
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);

Navbar.defaultProps = {
  doLogout: () => {},
  userInfo: {}
};

Navbar.propTypes = {
  doLogout: PropTypes.func,
  userInfo: PropTypes.objectOf(PropTypes.any)
};
