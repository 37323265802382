import { app as appActionTypes, auth as authActionTypes } from '../actions';
import initialState from '../constants/initialState';
import { categoryHierarchyWIthIndex, makeKeyValue } from '../utills/custom';

function app(state = initialState.app, action) {
  switch (action.type) {
    case appActionTypes.COUNTRY_LIST_REQUEST:
      return {
        ...state,
        stateList: [],
        countryList: []
      };
    case appActionTypes.COUNTRY_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          countryList: action.data
        };
      }
      return state;
    case appActionTypes.STATE_LIST_REQUEST:
      return {
        ...state,
        stateList: [],
        cityList: []
      };
    case appActionTypes.STATE_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          stateList: action.data
        };
      }
      return state;
    case appActionTypes.PREFETCH_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        categoryList: []
      };
    case appActionTypes.PREFETCH_CATEGORY_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          categoryList: categoryHierarchyWIthIndex(action.data)
        };
      }
      return state;
    case appActionTypes.PREFETCH_SHIPPING_LIST_REQUEST:
      return {
        ...state,
        shippingList: []
      };
    case appActionTypes.PREFETCH_SETTING_SUCCESS:
      if (action.data) {
        return {
          ...state,
          settings: {
            ...state.settings,
            ...makeKeyValue(action.data, 'key', 'value')
          }
        };
      }
      return state;
    case appActionTypes.PREFETCH_SHIPPING_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          shippingList: categoryHierarchyWIthIndex(action.data)
        };
      }
      return state;
    case appActionTypes.CITY_LIST_REQUEST:
      return {
        ...state,
        cityList: []
      };
    case appActionTypes.CITY_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          cityList: action.data
        };
      }
      return state;
    case appActionTypes.PREFETCH_IMAGE_STYLE_REQUEST:
      return {
        ...state,
        imageStyle: []
      };
    case appActionTypes.PREFETCH_IMAGE_STYLE_SUCCESS:
      if (action.data) {
        return {
          ...state,
          imageStyle: action.data
        };
      }
      return state;
    case appActionTypes.PREFETCH_STORE_ADDRESS_REQUEST:
      return {
        ...state,
        storeAddress: []
      };
    case appActionTypes.PREFETCH_STORE_ADDRESS_SUCCESS:
      if (action.data) {
        return {
          ...state,
          storeAddress: action.data,
          storeAddressCountryWise: {
            ...state.storeAddressCountryWise,
            [action.country]: action.data
          }
        };
      }
      return state;
    case appActionTypes.SET_SHOP_COUNTRY:
      if (action.data) {
        return {
          ...state,
          shopCountry: action.data,
          shopState: undefined
        };
      }
      return state;
    case appActionTypes.SET_SHOP_STATE:
      if (action.data) {
        return {
          ...state,
          shopState: action.data
        };
      }
      return state;
    case appActionTypes.PREFETCH_COUNTRY_WISE_CURRENCY_SUCCESS:
      if (action.data) {
        return {
          ...state,
          countryCurrencies: action.data
        };
      }
      return state;
    case authActionTypes.LOGOUT_SUCCESS:
      return { ...initialState.app, shopCountry: '', shopState: '' };
    default:
      return state;
  }
}

export default app;
