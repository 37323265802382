import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { tree as treeActionTypes } from '../actions';

function* getCustomers(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/customer/downline-tree`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: treeActionTypes.CUSTOMER_TREE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchCustomerTree() {
  yield takeLatest(treeActionTypes.CUSTOMER_TREE_REQUEST, getCustomers);
}

export default [fork(watchCustomerTree)];
