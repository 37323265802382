import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import routePath from '../constants/routePath';

export default function NoResult({ icon, title, body }) {
  return (
    <div className="text-center w-100 mb-3">
      <div className="error-section">
        <div className="error-section-content">
          <h2 className="display-3">
            <i className={`${icon || 'dripicons-archive'} text-warning`} />
          </h2>
          <h3>{title || 'Nothing found'}</h3>
          <p>{body || 'Currently there is nothing to display here'}</p>
        </div>
      </div>
    </div>
  );
}
NoResult.defaultProps = {
  icon: PropTypes.undefined,
  title: PropTypes.undefined,
  body: PropTypes.undefined
};
NoResult.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string
};
