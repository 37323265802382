/* global appConfig */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withRouter } from 'react-router';
import ResetPasswordForm from './ResetPasswordForm';
import NoMatch from '../NoMatch';
import InnerLoader from '../../components/InnerLoader';
import routePath from '../../constants/routePath';
import Footer from '../../components/Footer';
import { auth as authActions } from '../../actions';

class ResetPassword extends React.Component {
  state = {
    validToken: false,
    tokenValidating: true
  };

  email = '';

  token = '';

  componentDidMount() {
    const { match } = this.props;
    if (match.params && match.params.token) {
      axios({
        url: `${process.env.REACT_APP_API_URL}/password/check-token`,
        method: 'POST',
        data: { token: match.params.token },
        hideMessage: true
      })
        .then(data => {
          this.email = data.email;
          this.token = match.params.token;
          this.setState({ tokenValidating: false, validToken: true });
        })
        .catch(() => {
          this.email = '';
          this.token = '';
          this.setState({ tokenValidating: false, validToken: false });
        });
    }
  }

  getContent() {
    const { resetPasswordProgress } = this.props;
    const { validToken } = this.state;
    if (validToken) document.body.classList.add('authentication-bg');
    return (
      <>
        <div className="account-pages">
          <div className="container">
            <div className="row justify-content-center">
              {validToken ? (
                <div className="col-lg-5">
                  <div className="card">
                    <div className="card-header border-bottom-dash text-center">
                      <img
                        className="logo-img"
                        src={appConfig.logo}
                        alt="{appConfig.logoALT}"
                        height="54"
                      />
                    </div>
                    <div className="card-body">
                      <div className="text-center">
                        <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                          Reset Password
                        </h4>
                      </div>
                      <ResetPasswordForm
                        submitLogin={this.handleSubmit}
                        inProgress={resetPasswordProgress}
                      />
                    </div>
                    <div className="card-footer text-center">
                      <p className="text-muted mt-1">
                        <span>Already have an account?</span>
                        <Link to={routePath.login.path} className="ml-1">
                          <b>Sign In</b>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <NoMatch />
              )}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  handleSubmit = values => {
    const { resetPassword } = this.props;
    resetPassword({ ...values, email: this.email, token: this.token });
  };

  render() {
    const { tokenValidating } = this.state;
    return (
      <>{tokenValidating ? <InnerLoader type="table" /> : this.getContent()}</>
    );
  }
}

ResetPassword.defaultProps = {
  match: {},
  resetPasswordProgress: false
};

ResetPassword.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  resetPasswordProgress: PropTypes.bool,
  resetPassword: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  resetPasswordProgress: state.auth.resetPasswordProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ resetPassword: authActions.resetPassword }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPassword)
);
