import React from 'react';
import { Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { Redirect } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
// import PageLoader from '../../components/PageLoader';
import routePath from '../../constants/routePath';
import BillingInfo from './BillingInfo';
import ShippingInfo from './ShippingInfo';
import DeleteAlert from '../../components/ConfirmAlert';
import AddressModal from '../Account/AddressModal';
import CheckoutForm from './CheckoutForm';
import OrderSummary from './OrderSummary';
import PaymentOption from './PaymentOption';
import {
  cart as cartActions,
  address as addressActions,
  checkout as checkoutActions
} from '../../actions';
import ZottoPayModal from '../../components/ZottoPayModal';

class Checkout extends React.Component {
  state = {
    activeTab: 'shipping-information'
  };

  componentDidMount() {
    const { getCartDetails } = this.props;
    getCartDetails();
  }

  handleTabChange = val => {
    this.setState({ activeTab: val });
  };

  handleAddressDelete = () => {
    const { deleteAddressById, alert, formValues, dispatch } = this.props;
    deleteAddressById(alert.id);
    if (parseInt(formValues.shipping_address_id, 10) === alert.id) {
      dispatch(change('checkoutForm', 'shipping_address_id', ''));
    }
    if (parseInt(formValues.billing_address_id, 10) === alert.id) {
      dispatch(change('checkoutForm', 'billing_address_id', ''));
    }
  };

  handleCheckout = () => {
    const { formValues, doCheckout } = this.props;
    doCheckout(formValues);
  };

  render() {
    const { cartDetails, updateProgress, hideAlert, alert } = this.props;

    const { activeTab } = this.state;

    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.checkout.breadcrumb} />
              <h4 className="page-title">{routePath.checkout.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* <div className="card d-lg-none">
              {cartDetails && cartDetails.items && cartDetails.items.length && (
                <OrderSummary />
              )}
            </div> */}
            <div className="card">
              {cartDetails && cartDetails.items && cartDetails.items.length ? (
                <>
                  <Nav
                    variant="pills"
                    className="nav nav-pills bg-nav-pills nav-justified"
                  >
                    <Nav.Item>
                      <Nav.Link
                        className="rounded-0"
                        active={activeTab === 'shipping-information'}
                      >
                        <i className="mdi mdi-truck-fast font-18" />
                        <span className="d-block">Shipping</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="rounded-0"
                        active={activeTab === 'billing-information'}
                      >
                        <i className="mdi mdi-account-circle font-18" />
                        <span className="d-block">Billing</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="rounded-0"
                        active={activeTab === 'payment-information'}
                      >
                        <i className="mdi mdi-cash-multiple font-18" />
                        <span className="d-block">Payment</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-8">
                        {activeTab === 'shipping-information' ? (
                          <ShippingInfo
                            handleTabChange={this.handleTabChange}
                          />
                        ) : null}
                        {activeTab === 'billing-information' ? (
                          <BillingInfo handleTabChange={this.handleTabChange} />
                        ) : null}
                        {activeTab === 'payment-information' ? (
                          <PaymentOption
                            handleTabChange={this.handleTabChange}
                          />
                        ) : null}
                      </div>
                      <div className="col-lg-4 mt-3 mt-lg-0">
                        <OrderSummary />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                // <PageLoader />
                <Redirect to={{ pathname: routePath.shop.path }} />
              )}
            </div>
          </div>
        </div>
        <DeleteAlert
          show={!!alert}
          inProgress={updateProgress}
          onConfirm={this.handleAddressDelete}
          onCancel={hideAlert}
        />
        <AddressModal />
        {cartDetails && cartDetails.items ? (
          <CheckoutForm
            submitCheckout={this.handleCheckout}
            initialValues={{ cart_id: cartDetails.id, pickup_from_store: 0 }}
          />
        ) : null}
        <ZottoPayModal />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  cartDetails: state.cart.cartDetails,
  updateProgress: state.address.updateProgress,
  alert: state.address.alert,
  formValues: getFormValues('checkoutForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCartDetails: cartActions.getCartDetails,
      updateCart: cartActions.updateCart,
      hideAlert: addressActions.hideAlert,
      deleteAddressById: addressActions.deleteAddressById,
      doCheckout: checkoutActions.doCheckout,
      dispatch: action => action
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);

Checkout.defaultProps = {
  getCartDetails: () => {},
  deleteAddressById: () => {},
  alert: false,
  formValues: {},
  doCheckout: () => {},
  cartDetails: {},
  updateProgress: false,
  hideAlert: () => {}
};

Checkout.propTypes = {
  getCartDetails: PropTypes.func,
  deleteAddressById: PropTypes.func,
  alert: PropTypes.bool,
  formValues: PropTypes.objectOf(PropTypes.any),
  doCheckout: PropTypes.func,
  cartDetails: PropTypes.objectOf(PropTypes.any),
  updateProgress: PropTypes.bool,
  hideAlert: PropTypes.func
};
