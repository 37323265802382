import React, { useEffect, useState } from 'react';
import { activePaymentModeByCountry } from '../../services/apiService';

function PaymentMode({ country, value, onChange }) {
  const [activeMode, setActiveMode] = useState([]);
  useEffect(() => {
    const fetchGateway = async () => {
      try {
        const res = await activePaymentModeByCountry(country);
        setActiveMode(res);
      } catch (error) {
        console.error(error);
      }
    };

    fetchGateway();
  }, [country]);

  return (
    <>
      {!activeMode || !activeMode.length ? (
        <div className="border p-3 mb-3 rounded">
          <h5 className="card-title">No payment mode found!</h5>
        </div>
      ) : (
        <>
          {activeMode && activeMode.includes('ZP') ? (
            <div className="border p-3 mb-3 rounded">
              <div className="row">
                <div className="col-sm-7">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="zotto_pay"
                      name="payment_type"
                      value="zotto_pay"
                      checked={value === 'zotto_pay'}
                      onChange={onChange}
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="zotto_pay">
                      <span className="font-weight-bold font-16">
                        Pay with Zotto
                      </span>
                      <br />
                      <span className="small">
                        Credit or Debit Card - For all countries
                      </span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-5 text-sm-right mt-3 mt-sm-0">
                  <img
                    src="/assets/images/payments/master.png"
                    height="24"
                    alt="master-card-img"
                  />
                  <img
                    src="/assets/images/payments/visa.png"
                    height="24"
                    alt="visa-card-img"
                  />
                  <img
                    src="/assets/images/payments/maestro.png"
                    height="24"
                    alt="maestro-img"
                  />
                </div>
              </div>
            </div>
          ) : null}
          {activeMode && activeMode.includes('QP') ? (
            <div className="border p-3 mb-3 rounded">
              <div className="row">
                <div className="col-sm-7">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="quickpay"
                      name="payment_type"
                      value="quickpay"
                      checked={value === 'quickpay'}
                      onChange={onChange}
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="quickpay">
                      <span className="font-weight-bold font-16">
                        Pay with QuickPay
                      </span>
                      <br />
                      <span className="small">Credit or Debit Card</span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-5 text-sm-right mt-3 mt-sm-0">
                  <img
                    src="/assets/images/payments/master.png"
                    height="24"
                    alt="master-card-img"
                  />
                  <img
                    src="/assets/images/payments/visa.png"
                    height="24"
                    alt="visa-card-img"
                  />
                </div>
              </div>
              {/* <div className="row mt-4">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="card_number">Card Number</label>
                  <InputMask
                    mask="9999 9999 9999 9999"
                    id="card_number"
                    name="card_number"
                    className="form-control"
                    onChange={this.handleCardNumber}
                    value={cardNumber}
                    disabled={paymentType !== 'quickpay'}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="card_name">Name on card</label>
                  <input
                    type="text"
                    id="card_name"
                    name="card_name"
                    className="form-control"
                    value={cardName}
                    onChange={this.handleCardInputs}
                    placeholder="Master Shreyu"
                    disabled={paymentType !== 'quickpay'}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="card_exp">Expiry date</label>
                  <InputMask
                    mask="99/99"
                    id="card_exp"
                    className="form-control"
                    onChange={this.handleCardExp}
                    value={expDate}
                    disabled={paymentType !== 'quickpay'}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="cvv">CVV code</label>
                  <InputMask
                    mask="999"
                    id="cvv"
                    name="cvv"
                    className="form-control"
                    onChange={this.handleCardInputs}
                    value={cvv}
                    disabled={paymentType !== 'quickpay'}
                  />
                </div>
              </div>
            </div> */}
            </div>
          ) : null}
        </>
      )}
    </>
  );
}

export default PaymentMode;
