import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export async function setDefaultAddress(id) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/address/toggleStatus/${id}`,
    method: 'PUT'
  });

  return res;
}

export async function activePaymentModeByCountry(country) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/cart/active-payment-mode/${country}`,
    method: 'GET'
  });

  return res;
}

export async function validateCoupon(data) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/cartitem/calculation`,
    method: 'POST',
    data,
    hideMessage: true
  });

  return res;
}

export async function getCartShippingOption(params) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/cart/get-shipping`,
    method: 'GET',
    params
  });

  return res;
}
