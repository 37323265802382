import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import { required, email } from '../../utills/validator';
import { inputField } from '../../components/Fields';
import btnLoader from '../../assets/images/btn-loader.gif';

const ForgotPasswordForm = props => {
  const { handleSubmit, submitLogin, inProgress } = props;
  return (
    <Form
      className="needs-validation"
      noValidate
      onSubmit={handleSubmit(submitLogin)}
    >
      <div className="form-group">
        <Field
          name="email"
          component={inputField}
          type="text"
          className="form-control"
          label="Email"
          placeholder="Enter your email address"
          validate={[required, email]}
        />
      </div>
      <div className="form-group text-center">
        <button type="submit" disabled={inProgress} className="btn btn-primary">
          {inProgress ? <img src={btnLoader} alt="" /> : 'Reset Password'}
        </button>
      </div>
    </Form>
  );
};

export default reduxForm({
  form: 'forgorPasswordForm' // a unique identifier for this form
})(ForgotPasswordForm);

ForgotPasswordForm.defaultProps = {
  handleSubmit: () => {},
  submitLogin: () => {},
  inProgress: false
};

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitLogin: PropTypes.func,
  inProgress: PropTypes.bool
};
