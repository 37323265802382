import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';

import BreadCrumb from '../../components/BreadCrumb';
import DeleteAlert from '../../components/ConfirmAlert';
import routePath from '../../constants/routePath';
import AddressModal from './AddressModal';
import AddressItem from './AddressItem';
import {
  address as addressActions,
  address as addressActionTypes
} from '../../actions';
import InnerLoader from '../../components/InnerLoader';
import NoResult from '../../components/NoResult';

class Addresses extends React.Component {
  componentDidMount() {
    const { getAllAddresses } = this.props;
    getAllAddresses();
  }

  handleDelete = () => {
    const { deleteAddressById, alert } = this.props;
    deleteAddressById(alert.id);
  };

  handleAdd = () => {
    const { showModal } = this.props;
    showModal({ is_default: '0' });
  };

  render() {
    const {
      addresses,
      hideAlert,
      alert,
      isLoading,
      updateProgress
    } = this.props;

    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.addresses.breadcrumb} />
              <h4 className="page-title">{routePath.addresses.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <div className="row">
              <div className="col">
                <p className="text-muted font-13">
                  Manage your billing and shipping addresses below.
                </p>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-primary float-right"
                  onClick={this.handleAdd}
                >
                  <i className="mdi mdi-plus-circle mr-2" />
                  Add New
                </button>
                <AddressModal />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              {isLoading && <InnerLoader type="table" />}
              {addresses &&
                addresses.map(address => (
                  <AddressItem address={address} key={address.id} />
                ))}
              {!isLoading && addresses.length === 0 && (
                <NoResult icon="mdi mdi-map-marker-multiple" />
              )}
              <DeleteAlert
                show={!!alert}
                inProgress={updateProgress}
                onConfirm={this.handleDelete}
                onCancel={hideAlert}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  addresses: state.address.list,
  isLoading: state.address.isLoading,
  updateProgress: state.address.updateProgress,
  alert: state.address.alert
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllAddresses: addressActionTypes.getAllAddresses,
      showModal: addressActions.showModal,
      showAlert: addressActions.showAlert,
      hideAlert: addressActions.hideAlert,
      deleteAddressById: addressActions.deleteAddressById
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Addresses);

Addresses.defaultProps = {
  getAllAddresses: () => {},
  deleteAddressById: () => {},
  alert: undefined,
  showModal: () => {},
  addresses: [],
  hideAlert: () => {},
  isLoading: false,
  updateProgress: false
};

Addresses.propTypes = {
  getAllAddresses: PropTypes.func,
  deleteAddressById: PropTypes.func,
  alert: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  showModal: PropTypes.func,
  addresses: PropTypes.arrayOf(PropTypes.any),
  hideAlert: PropTypes.func,
  isLoading: PropTypes.bool,
  updateProgress: PropTypes.bool
};
