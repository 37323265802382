import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  setting as settingActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllSetting() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/settings`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: settingActionTypes.SETTING_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getSetting(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/settings?options=${actions.tag}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: settingActionTypes.SETTING_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* createSetting(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/settings`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Setting was added successfully'
    });
    if (responseJson) {
      yield put({
        type: settingActionTypes.SETTING_CREATE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: settingActionTypes.SETTING_CREATE_ERROR, data: error });
  }
}

function* updateSetting(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/settings`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Settings updated successfully.'
    });
    if (responseJson) {
      yield put({
        type: settingActionTypes.SETTING_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.setting.path
        });
      }
    }
  } catch (error) {
    yield put({
      type: settingActionTypes.SETTING_UPDATE_ERROR,
      data: error
    });
  }
}

function* getAllLoginHistories() {
  try {
    /* const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/login-history`,
      method: 'GET'
    }); */
    const responseJson = {
      data: [
        {
          id: 1,
          uuid: 123,
          username: 'Sample',
          agent: 'Testing',
          ip_address: '192.168.1.0',
          in_time: '',
          out_time: '',
          user_time: ''
        },
        {
          id: 2,
          uuid: 456,
          username: 'Sample',
          agent: 'Testing',
          ip_address: '192.168.1.0',
          in_time: '',
          out_time: '',
          user_time: ''
        }
      ]
    };
    if (responseJson) {
      yield put({
        type: settingActionTypes.LOGIN_HISTORY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchSettingListAll() {
  yield takeLatest(settingActionTypes.SETTING_ALL_LIST_REQUEST, getAllSetting);
}

function* watchSettingCreate() {
  yield takeLatest(settingActionTypes.SETTING_CREATE_REQUEST, createSetting);
}

function* watchGetSetting() {
  yield takeLatest(settingActionTypes.SETTING_REQUEST, getSetting);
}

function* watchUpdateSetting() {
  yield takeLatest(settingActionTypes.SETTING_UPDATE_REQUEST, updateSetting);
}

function* watchGetLoginHistories() {
  yield takeLatest(
    settingActionTypes.LOGIN_HISTORY_REQUEST,
    getAllLoginHistories
  );
}

export default [
  fork(watchSettingListAll),
  fork(watchSettingCreate),
  fork(watchGetSetting),
  fork(watchUpdateSetting),
  fork(watchGetLoginHistories)
];
