import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

function PaginationNormal({
  baseUrl = '/shop',
  currentPage = 1,
  lastPage = 1,
  // perPage,
  handleOnClick
}) {
  function handleClick(e, page) {
    e.preventDefault();
    // const pathName = history.location.pathname.replace(/\/$/, '');
    // const datUrl =
    //   e.target.getAttribute('data-url') ||
    //   e.target.parentNode.getAttribute('data-url');
    // if (datUrl && pathName !== datUrl) {
    //   if (perPage) {
    //     history.push(`${datUrl}?per_page=${perPage}`);
    //   } else {
    //     history.push(datUrl);
    //   }
    // }
    if (page && currentPage !== page) {
      handleOnClick(page);
    }
  }

  function getLinks() {
    const links = [];
    const startingLinks = [];
    const endingLinks = [];
    let start = 1;
    let end = lastPage;
    if (lastPage < 6) {
      start = 1;
      end = lastPage;
    } else if (currentPage <= 5) {
      start = 1;
      end = 5;
      endingLinks.push(<Pagination.Ellipsis key="ellipsis_last" />);
      endingLinks.push(
        <Pagination.Item
          key={`pagination_item_${lastPage}`}
          href={`${baseUrl}/${lastPage}`}
          data-url={`${baseUrl}/${lastPage}`}
          // onClick={handleClick}
          onClick={event => handleClick(event, lastPage)}
        >
          {lastPage}
        </Pagination.Item>
      );
    } else if (currentPage > lastPage - 5) {
      startingLinks.push(
        <Pagination.Item
          key={`pagination_item_${1}`}
          href={`${baseUrl}/${1}`}
          data-url={`${baseUrl}/${1}`}
          // onClick={handleClick}
          onClick={event => handleClick(event, 1)}
        >
          {1}
        </Pagination.Item>
      );
      startingLinks.push(<Pagination.Ellipsis key="ellipsis_first" />);
      start = lastPage - 5;
      end = lastPage;
    } else {
      startingLinks.push(
        <Pagination.Item
          key={`pagination_item_${1}`}
          href={`${baseUrl}/${1}`}
          data-url={`${baseUrl}/${1}`}
          // onClick={handleClick}
          onClick={event => handleClick(event, 1)}
        >
          {1}
        </Pagination.Item>
      );
      startingLinks.push(<Pagination.Ellipsis key="ellipsis_first" />);
      start = currentPage - 2;
      end = currentPage + 2;
      endingLinks.push(<Pagination.Ellipsis key="ellipsis_last" />);
      endingLinks.push(
        <Pagination.Item
          key={`pagination_item_${lastPage}`}
          href={`${baseUrl}/${lastPage}`}
          data-url={`${baseUrl}/${lastPage}`}
          // onClick={handleClick}
          onClick={event => handleClick(event, lastPage)}
        >
          {lastPage}
        </Pagination.Item>
      );
    }

    links.push(...startingLinks);
    for (let i = start; i <= end; i += 1) {
      links.push(
        <Pagination.Item
          key={`pagination_item_${i}`}
          active={currentPage === i}
          href={`${baseUrl}/${i}`}
          data-url={`${baseUrl}/${i}`}
          // onClick={handleClick}
          onClick={event => handleClick(event, i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    links.push(...endingLinks);
    return links;
  }

  let prev = currentPage - 1;
  let next = currentPage + 1;
  if (prev < 1) {
    prev = 1;
  }
  if (next > lastPage) {
    next = lastPage;
  }

  return (
    <Pagination className="pagination pagination-rounded float-sm-right mb-3">
      {currentPage !== 1 && (
        <Pagination.First
          href={`${baseUrl}/${1}`}
          data-url={`${baseUrl}/1`}
          onClick={event => handleClick(event, 1)}
        />
      )}
      {currentPage !== prev && (
        <Pagination.Prev
          href={`${baseUrl}/${prev}`}
          data-url={`${baseUrl}/${prev}`}
          // onClick={handleClick}
          onClick={event => handleClick(event, prev)}
        />
      )}
      {getLinks()}
      {currentPage !== next && (
        <Pagination.Next
          href={`${baseUrl}/${next}`}
          data-url={`${baseUrl}/${next}`}
          // onClick={handleClick}
          onClick={event => handleClick(event, next)}
        />
      )}
      {currentPage !== lastPage && (
        <Pagination.Last
          href={`${baseUrl}/${lastPage}`}
          data-url={`${baseUrl}/${lastPage}`}
          // onClick={handleClick}
          onClick={event => handleClick(event, lastPage)}
        />
      )}
    </Pagination>
  );
}

export default PaginationNormal;

PaginationNormal.defaultProps = {
  baseUrl: '/shop',
  currentPage: 1,
  lastPage: 1,
  handleOnClick: () => {}
};

PaginationNormal.propTypes = {
  baseUrl: PropTypes.string,
  currentPage: PropTypes.number,
  lastPage: PropTypes.number,
  handleOnClick: PropTypes.func
};
