import axios from 'axios';
import { get } from 'lodash';
import { NotificationManager } from 'react-notifications';
import storageService from './storageService';
import history from './historyService';
import { getErrorMessage } from '../utills/ajax';

const patt = new RegExp('/login$');

const customFileReader = file => {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error('Problem parsing file'));
    };
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsText(file);
  });
};

const readMessageFromBlobType = async data => {
  try {
    const file = await customFileReader(data);
    const errorMassage = JSON.parse(file);
    NotificationManager.error(getErrorMessage(getErrorMessage(errorMassage)));
  } catch (error) {
    NotificationManager.error(getErrorMessage(getErrorMessage({})));
  }
};

class InterceptorsService {
  constructor() {
    axios.interceptors.request.use(
      config => {
        const token = storageService.getToken();
        const localConfig = config;
        if (!localConfig.thirdParty) {
          if (token) {
            if (!localConfig.headers) {
              localConfig.headers = {};
            }
            localConfig.headers.Authorization = `Bearer ${storageService.getToken()}`;
          }
          if (!localConfig.headers['content-type']) {
            localConfig.headers['content-type'] = 'application/json';
          }
        }
        return localConfig;
      },
      error => Promise.reject(error)
    );

    axios.interceptors.response.use(
      response => {
        const data = get(response, 'data', {});
        const config = get(response, 'config', {});
        if (config && config.method !== 'get' && !config.hideMessage) {
          if (response.config.successMessage) {
            NotificationManager.success(response.config.successMessage);
          } else {
            NotificationManager.success(data.message);
          }
        }

        // Return entire response if response type blob
        if (config && config.responseType === 'blob') {
          return response;
        }

        return data;
      },
      error => {
        const response = get(error, 'response', {});
        if (response) {
          if (response.status === 401 && !patt.test(response.config.url)) {
            // Do logout
            storageService.clearStorage();
            history.replace('/login');
            NotificationManager.error('Session Expired!');
          } else if (!error.config.hideErrorMessage) {
            const responseType = get(error, 'config.responseType', '');
            if (responseType === 'blob') {
              readMessageFromBlobType(response.data);
            } else {
              NotificationManager.error(getErrorMessage(response));
            }
          }
        }
        return Promise.reject(response);
      }
    );
  }
}

export default new InterceptorsService();
