import React from 'react';
import { Link } from 'react-router-dom';
import routePath from '../../constants/routePath';

class Package extends React.Component {
  componentDidMount() {
    document.body.classList.add('authentication-bg');
  }

  componentWillUnmount() {
    document.body.classList.remove('authentication-bg');
  }

  render() {
    return (
      <div className="container">
        <div className="card package_wrapper">
          <h3 className="text-dark-50 text-center mt-0 font-weight-bold">
            Thank You
          </h3>
          <div className="package_nav mt-3">
            <ul className="nav nav-pills bg-nav-pills nav-justified form-wizard-header mb-4">
              <li className="nav-item">
                <span className="nav-link rounded-0 pt-2 pb-2">
                  <i className="far fa-handshake mr-1" />
                  <span className="d-none d-sm-inline">Sponsor</span>
                </span>
              </li>
              <li className="nav-item">
                <span className="nav-link rounded-0 pt-2 pb-2">
                  <i className="far fa-user mr-1" />
                  <span className="d-none d-sm-inline">Personal Details</span>
                </span>
              </li>
              <li className="nav-item">
                <span className="nav-link rounded-0 pt-2 pb-2 active">
                  <i className="far fa-check-circle mr-1" />
                  <span className="d-none d-sm-inline">Finish</span>
                </span>
              </li>
            </ul>
          </div>
          <div className="col-xl-12">
            <div className="row mb-4">
              <div className="col-xl-12">
                <div className="text-center">
                  <h2 className="mt-0">
                    <i className="mdi mdi-check-all" />
                  </h2>
                  <h3 className="mt-0">Congratulations!</h3>
                  <p className="w-75 mb-3 mx-auto">
                    Where something special happens every day.
                  </p>
                  <Link to={routePath.shop.path} className="btn btn-primary">
                    Shop Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Package;
