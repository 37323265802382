/* global appConfig */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { find, filter } from 'lodash';
import moment from 'moment';
import { downloadBlob } from '../../utills/ajax';
import StoreAddress from '../../components/StoreAddress';
import { getOrderStatusClass } from '../../utills/custom';

function renderAddress(address) {
  if (address) {
    return (
      <address className="font-13">
        {address.name}
        <p className="mb-1" />
        {address.street_name_number}
        <br />
        {address.address_line1}
        <br />
        {address.address_line2 ? (
          <>
            {address.address_line2}
            <br />
          </>
        ) : null}
        {address.postcode}
        <br />
        {address.city || address.city_details ? (
          <>
            {address.city_details ? address.city_details.name : address.city}
            <br />
          </>
        ) : null}
        {address.state ? address.state.name : ''}
        <br />
        {address.country.name || address.country}
        <br />
        {`Phone: ${address.mobile_number}`}
      </address>
    );
  }
  return <address className="font-13">N/A</address>;
}

function renderOrderItem(data, currency) {
  if (data.order_item) {
    return data.order_item.map(item => (
      <tr key={item.product_id}>
        <td>
          <b>{item.product.title}</b>
        </td>
        <td>{item.product.ean}</td>
        <td>{item.product.sku}</td>
        <td className="text-center">
          {item.product_cp ? item.product_cp : '0.00'}
        </td>
        <td className="text-center">{item.quantity}</td>
        <td className="text-right">{`${currency}${item.price}`}</td>
        <td className="text-right">
          {`${currency}${item.price * item.quantity}`}
        </td>
      </tr>
    ));
  }
  return null;
}

function renderTaxBreakdown(data, currency) {
  if (data.order_taxes_shipping_details) {
    const taxes = filter(data.order_taxes_shipping_details, ['type', 1]);
    return taxes.map((item, index) => (
      <tr key={`tax_${index}`}>
        <td>
          <b>{`${item.name} (${item.amount}%):`}</b>
        </td>
        <td>{`${currency}${item.total}`}</td>
      </tr>
    ));
  }
  return [];
}

function renderShipping(data, currency) {
  if (data.order_taxes_shipping_details) {
    const taxes = filter(data.order_taxes_shipping_details, ['type', 2]);
    return taxes.map((item, index) => {
      if (item.name.indexOf('|') > -1) {
        const desc = item.name.split('|');
        return (
          <tr key={`ship_${index}`}>
            <td>
              <b className="d-block">Shipping:</b>
              {item.amount > 0 ? (
                <>
                  {desc.map(descItem => (
                    <small className="d-block">{descItem}</small>
                  ))}
                </>
              ) : null}
            </td>
            {item.amount > 0 ? (
              <td>{`${currency}${item.amount}`}</td>
            ) : (
              <td className="text-success">Pick-up from Store</td>
            )}
          </tr>
        );
      }
      return (
        <tr key={`ship_${index}`}>
          <td>
            <b>{`${item.name} (${item.amount}%):`}</b>
          </td>
          <td>{`${currency}${item.total}`}</td>
        </tr>
      );
    });
  }
  return [];
}

class InvoiceViewComponent extends React.Component {
  handleSingleDownload = async () => {
    const { data } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/product/invoice`;
    const response = await axios({
      url,
      method: 'GET',
      params: { id: data.id },
      headers: { Accept: 'application/pdf' },
      responseType: 'blob'
    });
    downloadBlob(
      response,
      `${moment().format('DDMMYYYY')}-${data.invoice_number}.pdf`
    );
  };

  render() {
    const { data } = this.props;
    const currency = data.currency ? data.currency.currency_symbol : '';
    const shippingAddress = find(data.order_address, { type: 'SHIPPING' });
    const billingAddress = find(data.order_address, { type: 'BILLING' });
    return (
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-sm-9">
            <img
              className="mb-2"
              src={appConfig.logo}
              alt="{appConfig.logoALT}"
              height="40"
            />
            <address className="font-13">
              <StoreAddress country={data.country} />
            </address>
          </div>
          <div className="col-sm-3">
            <h3 className="mt-0">{`Invoice #${data.invoice_number}`}</h3>
            <div className="font-13">
              <strong>Order Date: </strong>{' '}
              <span className="float-right">
                {data.order_local_date_tz.replace(/\s.*/, '')}
              </span>
            </div>
            <div className="font-13">
              <strong>Order Status: </strong>{' '}
              <span
                className={`${getOrderStatusClass(
                  data.payment_gateway_status
                )} float-right`}
              >
                {data.payment_gateway_status}
              </span>
            </div>
            <div className="font-13">
              <strong>Order ID: </strong>{' '}
              <span className="float-right">{`#${data.id}`}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <h6>
              {`Hi ${
                data.user_info
                  ? `${data.user_info.first_name} ${data.user_info.last_name},`
                  : ''
              }`}
            </h6>
            <p className="text-muted font-11 pr-4">
              <strong>Cust ID:</strong> {data.user_info.uuid}
              <br />
              <strong>Email:</strong> {data.user_info.email}
            </p>
            <p className="text-muted font-13 pr-4">
              Please find your order below:-
            </p>
          </div>
          <div className="col-sm-3">
            <h6>Shipping Address</h6>
            {renderAddress(shippingAddress)}
          </div>
          <div className="col-sm-3">
            <h6>Billing Address</h6>
            {renderAddress(billingAddress)}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table mt-4 table-nowrap">
                <thead>
                  <tr>
                    <th width="50%">Product(s)</th>
                    <th>EAN 13 Number</th>
                    <th>SKU</th>
                    <th>C-Point</th>
                    <th className="text-center">Qty.</th>
                    <th className="text-right">Rate</th>
                    <th className="text-right">Total</th>
                  </tr>
                </thead>
                <tbody>{renderOrderItem(data, currency)}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <h6>
              <small>
                Total C-Point:{' '}
                <strong>
                  {data.total_cp
                    ? parseFloat(data.total_cp).toFixed(2)
                    : '0.00'}
                </strong>
              </small>
            </h6>
          </div>
          <div className="col-sm-6">
            <div className="float-right mt-3 mt-sm-0">
              <table className="table table-sm text-right">
                <tbody>
                  <tr>
                    <td>
                      <b>Sub-total:</b>
                    </td>
                    <td>{`${currency}${data.subtotal}`}</td>
                  </tr>
                  {renderTaxBreakdown(data, currency)}
                  {data.order_coupon ? (
                    <tr>
                      <td>
                        <strong className="text-danger d-block">
                          Coupon Discount:
                        </strong>
                        <small className="d-block">
                          {data.order_coupon
                            ? `(${data.order_coupon.code})`
                            : ''}
                        </small>
                      </td>
                      <td>
                        <strong className="text-danger">{`- ${currency}${data.order_coupon.discount}`}</strong>
                      </td>
                    </tr>
                  ) : null}
                  {renderShipping(data, currency)}
                  {data.order_cp_details ? (
                    <tr>
                      <td>
                        <strong className="text-danger">
                          {`CP Used (${data.order_cp_details.cpoint_used})`}:
                        </strong>
                      </td>
                      <td>
                        <strong className="text-danger">{`- ${currency}${data.order_cp_details.total_cp_amount_with_vat}`}</strong>
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>
                      <b>Gross Total:</b>
                    </td>
                    <td>
                      <h3>{`${currency}${data.total}`}</h3>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <div className="d-print-none mt-4">
          <div className="text-right">
            <button
              type="button"
              onClick={() => window.print()}
              className="btn btn-primary mr-2"
            >
              <i className="mdi mdi-printer" /> Print
            </button>
            <button
              type="button"
              onClick={this.handleSingleDownload}
              className="btn btn-success"
            >
              <i className="mdi mdi-download" /> Download
            </button>
          </div>
        </div>
        <div className="clearfix pt-5 text-center">
          This is a computer generated invoice &amp; hence requires no
          signature.
        </div>
      </div>
    );
  }
}

InvoiceViewComponent.defaultProps = {
  data: {}
};

InvoiceViewComponent.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
};

export default InvoiceViewComponent;
