import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import img from '../../assets/images/img-placeholder.jpg';
import Image from '../../components/Image';
import { productImage } from '../../constants/imageSize';
import { getCustomerProductPrice } from '../../utills/custom';

function CartDropdownItem({ data, currency }) {
  // const [quantity, setQuantity] = useState(data.quantity);

  /* useEffect(() => {
    setQuantity(data.quantity);
  }, [data.quantity]);

  function handleQtyChange(e) {
    setQuantity(e.target.value);
    updateCart(data.id, {
      cart_id: data.cart_id,
      quantity: e.target.value
    });
  }

  function handleDelete() {
    deleteCart(data.id);
  } */

  let pimg = get(data, 'product.product_images[0].file.file_path', null);
  if (pimg) {
    pimg = `${process.env.REACT_APP_IMAGE_URL}/${pimg}`;
  } else {
    pimg = img;
  }
  return (
    <Dropdown.Item
      className="notify-item"
      as={Link}
      to={`/shop/product/${data.product.slug}`}
    >
      <div className="notify-icon">
        <Image
          src={pimg}
          alt={data.product.title}
          title={data.product.title}
          className="rounded mr-3 w-100 h-auto"
          size={productImage.productAdmin}
          height="64"
        />
      </div>
      <div className="notify-details text-dark">
        <div className="row">
          <div className="col-7">
            <span className="text-body font-weight-semibold">
              {data.product.title}
            </span>
          </div>
          <div className="col-5 text-right">
            {`${currency}${(
              data.quantity * getCustomerProductPrice(data.product)
            ).toFixed(2)}`}
          </div>
        </div>
      </div>
      <p className="text-muted mb-0 user-msg">
        <small>
          {`${currency}${
            parseInt(data.product.sale_price, 10)
              ? data.product.sale_price
              : data.product.regular_price
          } x ${data.quantity}`}
        </small>
      </p>
    </Dropdown.Item>
  );
}

export default CartDropdownItem;

CartDropdownItem.defaultProps = {
  data: {}
};

CartDropdownItem.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
};
