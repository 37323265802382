import { checkout as checkoutActionTypes } from '../actions';
import initialState from '../constants/initialState';

function checkout(state = initialState.checkout, action) {
  switch (action.type) {
    case checkoutActionTypes.CHECKOUT_REQUEST:
      return {
        ...state,
        progress: true
      };
    case checkoutActionTypes.CHECKOUT_SUCCESS:
    case checkoutActionTypes.CHECKOUT_ERROR:
      return {
        ...state,
        progress: false
      };
    case checkoutActionTypes.CANCEL_PAYMENT_REQUEST:
      return {
        ...state,
        progress: true
      };
    case checkoutActionTypes.CANCEL_PAYMENT_SUCCESS:
      return {
        ...state,
        progress: false,
        data: action.data
      };
    case checkoutActionTypes.CANCEL_PAYMENT_ERROR:
      return {
        ...state,
        progress: false,
        error: action.data.data
      };
    case checkoutActionTypes.ZOTTOPAY_MODAL_OPEN:
      return {
        ...state,
        zottopayModal: true
      };
    case checkoutActionTypes.ZOTTOPAY_MODAL_CLOSE:
      return {
        ...state,
        zottopayModal: false
      };
    default:
      return state;
  }
}

export default checkout;
