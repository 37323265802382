const fsb = {
  FSB_UPDATE_REQUEST: 'FSB_UPDATE_REQUEST',
  FSB_UPDATE_SUCCESS: 'FSB_UPDATE_SUCCESS',
  FSB_UPDATE_ERROR: 'FSB_UPDATE_ERROR',
  FSB_REQUEST: 'FSB_REQUEST',
  FSB_SUCCESS: 'FSB_SUCCESS',
  FSB_ERROR: 'FSB_ERROR',
  updateFsb: data => ({ type: fsb.FSB_UPDATE_REQUEST, data }),
  getFsb: id => ({ type: fsb.FSB_REQUEST, id })
};

export default fsb;
