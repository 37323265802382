import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getImageUrl } from '../utills/custom';

function Image({
  src = '',
  className = '',
  alt = '',
  title = '',
  size = null,
  imageStyle
}) {
  let imageUrl = src;
  if (
    !/placeholder/gi.test(src) &&
    !/avatar/gi.test(src) &&
    size &&
    imageStyle[size]
  ) {
    imageUrl = getImageUrl(src, imageStyle[size]);
  }
  return (
    <img
      className={className}
      src={imageUrl}
      {...(title && { title })}
      alt={alt}
    />
  );
}

const mapStateToProps = state => ({
  imageStyle: state.app.imageStyle
});

export default connect(
  mapStateToProps,
  null
)(Image);

Image.defaultProps = {
  src: '',
  className: '',
  alt: '',
  title: '',
  size: null,
  imageStyle: []
};

Image.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  imageStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
