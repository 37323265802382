import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  product as productActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllProduct(actions) {
  const page = actions.data && actions.data.page ? actions.data.page : false;
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/get${
        page > 1 ? `?page=${page}` : ''
      }`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: productActionTypes.PRODUCT_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAllProductByCategory(actions) {
  const page = actions.data && actions.data.page ? actions.data.page : false;
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/get/${actions.data.id}${
        page > 1 ? `?page=${page}` : ''
      }`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: productActionTypes.PRODUCT_ALL_LIST_CATEGORY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getProduct(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/${actions.id}/get`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: productActionTypes.PRODUCT_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* createProduct(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/add`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Product was addedd successfully'
    });
    if (responseJson) {
      yield put({
        type: productActionTypes.PRODUCT_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.product.path
      });
    }
  } catch (error) {
    yield put({ type: productActionTypes.PRODUCT_CREATE_ERROR, data: error });
  }
}

function* updateProduct(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/${actions.data.id}/edit`,
      method: 'POST',
      data: actions.data.data,
      successMessage: 'Product updated successfully'
    });
    if (responseJson) {
      yield put({
        type: productActionTypes.PRODUCT_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.product.path
        });
      }
    }
  } catch (error) {
    yield put({ type: productActionTypes.PRODUCT_UPDATE_ERROR, data: error });
  }
}

function* watchProductListAll() {
  yield takeLatest(productActionTypes.PRODUCT_ALL_LIST_REQUEST, getAllProduct);
}

function* watchProductListCategoryAll() {
  yield takeLatest(
    productActionTypes.PRODUCT_ALL_LIST_CATEGORY_REQUEST,
    getAllProductByCategory
  );
}

function* watchGetProduct() {
  yield takeLatest(productActionTypes.PRODUCT_REQUEST, getProduct);
}

function* watchProductCreate() {
  yield takeLatest(productActionTypes.PRODUCT_CREATE_REQUEST, createProduct);
}

function* watchProductUpdate() {
  yield takeLatest(productActionTypes.PRODUCT_UPDATE_REQUEST, updateProduct);
}

export default [
  fork(watchProductListAll),
  fork(watchProductListCategoryAll),
  fork(watchProductCreate),
  fork(watchProductUpdate),
  fork(watchGetProduct)
];
