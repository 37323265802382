import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';

export default function DeleteAlert(props) {
  const { title, inProgress, children } = props;
  return (
    <SweetAlert
      {...props}
      type="danger"
      title={title || 'Are you sure?'}
      disabled={inProgress}
      showConfirm={!inProgress}
      showCancel={!inProgress}
      confirmBtnText="Yes, delete"
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="default"
    >
      {children ||
        'This action will delete and cannot be undone. Please make sure before deleting.'}
    </SweetAlert>
  );
}

export function WarningAlert(props) {
  const { title = 'Are you sure?', inProgress, children } = props;
  return (
    <SweetAlert
      {...props}
      type="warning"
      title={title}
      disabled={inProgress}
      showConfirm={!inProgress}
      showCancel={!inProgress}
      confirmBtnText="Yes, confirm"
      confirmBtnBsStyle="warning"
      cancelBtnBsStyle="default"
    >
      {children || 'This action cannot be undone. Please make sure before.'}
    </SweetAlert>
  );
}

DeleteAlert.propTypes = {
  title: PropTypes.bool,
  inProgress: PropTypes.bool
};
DeleteAlert.defaultProps = {
  title: undefined,
  inProgress: undefined
};
WarningAlert.propTypes = {
  title: PropTypes.bool,
  inProgress: PropTypes.bool
};
WarningAlert.defaultProps = {
  title: undefined,
  inProgress: undefined
};
