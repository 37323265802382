const product = {
  PRODUCT_ALL_LIST_REQUEST: 'PRODUCT_ALL_LIST_REQUEST',
  PRODUCT_ALL_LIST_SUCCESS: 'PRODUCT_ALL_LIST_SUCCESS',
  PRODUCT_ALL_LIST_ERROR: 'PRODUCT_ALL_LIST_ERROR',
  PRODUCT_ALL_LIST_CATEGORY_REQUEST: 'PRODUCT_ALL_LIST_CATEGORY_REQUEST',
  PRODUCT_ALL_LIST_CATEGORY_SUCCESS: 'PRODUCT_ALL_LIST_CATEGORY_SUCCESS',
  PRODUCT_ALL_LIST_CATEGORY_ERROR: 'PRODUCT_ALL_LIST_CATEGORY_ERROR',
  PRODUCT_CREATE_REQUEST: 'PRODUCT_CREATE_REQUEST',
  PRODUCT_CREATE_SUCCESS: 'PRODUCT_CREATE_SUCCESS',
  PRODUCT_CREATE_ERROR: 'PRODUCT_CREATE_ERROR',
  PRODUCT_UPDATE_REQUEST: 'PRODUCT_UPDATE_REQUEST',
  PRODUCT_UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
  PRODUCT_UPDATE_ERROR: 'PRODUCT_UPDATE_ERROR',
  PRODUCT_REQUEST: 'PRODUCT_REQUEST',
  PRODUCT_SUCCESS: 'PRODUCT_SUCCESS',
  getAllProducts: data => ({ type: product.PRODUCT_ALL_LIST_REQUEST, data }),
  createProduct: data => ({ type: product.PRODUCT_CREATE_REQUEST, data }),
  updateProduct: data => ({ type: product.PRODUCT_UPDATE_REQUEST, data }),
  getProductById: id => ({ type: product.PRODUCT_REQUEST, id }),
  getProductsByCategory: data => ({
    type: product.PRODUCT_ALL_LIST_CATEGORY_REQUEST,
    data
  })
};

export default product;
