import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { address as addressActions } from '../../actions';

function AddressItem({
  address,
  showModal,
  showAlert,
  isSelected,
  grid,
  onSelect,
  beforeSelect
}) {
  function handleEdit() {
    let city = '';
    let otherCity = '';
    if (address.city_details) {
      city = address.city_details.id;
    } else if (address.city) {
      city = 'others';
      otherCity = address.city;
    }

    showModal({
      id: address.id,
      country: address.country ? address.country.iso2 : '',
      type: address.type,
      name: address.name,
      mobile_number: address.mobile_number,
      postcode: address.postcode,
      street_name_number: address.street_name_number,
      address_line1: address.address_line1,
      address_line2: address.address_line2,
      state: address.state ? address.state.id : '',
      city,
      otherCity,
      location_type: address.location_type,
      is_default: address.is_default
    });
  }

  function gridClass() {
    switch (grid) {
      case 4:
        return 'col-sm-6 col-md-3 mb-2';
      case 3:
        return 'col-sm-6 col-md-4  mb-2';
      case 2:
        return 'col-sm-6 mb-2';
      case 1:
        return 'col-sm-12 mb-2';
      default:
        return 'col-sm-6 col-md-3 mb-2';
    }
  }

  function handleAddressSelect(e) {
    if (beforeSelect) {
      if (beforeSelect(e.target.value)) {
        onSelect(e.target.value);
      }
    } else {
      onSelect(e.target.value);
    }
  }

  return (
    <div className={gridClass()}>
      <label
        className="card cursor-pointer m-0 h-100"
        htmlFor={`selectAddress_${address.id}`}
      >
        <address className="m-0 address-lg p-2">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id={`selectAddress_${address.id}`}
              name={address.type}
              className="custom-control-input"
              checked={isSelected === address.id}
              value={address.id}
              onChange={handleAddressSelect}
            />
            <div className="custom-control-label font-weight-bold">
              {address.name}
              <div className="font-12 font-weight-normal">
                {address.street_name_number}
                <br />
                {address.address_line1}
                <br />
                {address.address_line2 ? (
                  <>
                    {address.address_line2}
                    <br />
                  </>
                ) : null}
                {address.postcode}
                <br />
                {address.city || address.city_details ? (
                  <>
                    {address.city_details
                      ? address.city_details.name
                      : address.city}
                    <br />
                  </>
                ) : null}
                {address.state ? address.state.name : ''}
                <br />
                {address.country.name || address.country}
                <br />
                <button
                  type="button"
                  className="btn btn-link p-0 card-link text-custom font-12"
                  onClick={handleEdit}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="btn btn-link p-0 card-link text-custom font-12"
                  onClick={() => showAlert(address)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </address>
      </label>
    </div>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showModal: addressActions.showModal,
      showAlert: addressActions.showAlert
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(AddressItem);

AddressItem.defaultProps = {
  address: {},
  showModal: () => {},
  showAlert: () => {},
  isSelected: NaN,
  grid: undefined,
  onSelect: () => {}
};

AddressItem.propTypes = {
  address: PropTypes.objectOf(PropTypes.any),
  showModal: PropTypes.func,
  showAlert: PropTypes.func,
  isSelected: PropTypes.number,
  grid: PropTypes.number,
  onSelect: PropTypes.func
};
