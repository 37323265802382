import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { formValueSelector, change } from 'redux-form';
import AddressItem from './AddressItem';
import { address as addressActionTypes } from '../../actions';
import InnerLoader from '../../components/InnerLoader';
import NoResult from '../../components/NoResult';

class BillingInfo extends React.Component {
  componentDidMount() {
    const { getAllAddresses } = this.props;
    getAllAddresses();
  }

  handleAddressAdd = () => {
    const { showModal } = this.props;
    showModal();
  };

  handleAddressSelect = val => {
    const { dispatch } = this.props;
    dispatch(change('checkoutForm', 'billing_address_id', val));
  };

  render() {
    const {
      addresses,
      isLoading,
      billingAddress,
      handleTabChange
    } = this.props;
    let billings = [];
    billings = addresses.filter(
      address => address.type === 'BILLING' || address.type === 'BOTH'
    );
    return (
      <>
        <div className="row mb-2">
          <div className="col-12">
            <h5 className="mt-2 mb-3">Choose Billing Address</h5>
          </div>
          <div className="col-12">
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={this.handleAddressAdd}
            >
              <i className="mdi mdi-plus-circle" />
              <span>&nbsp; Add New Address</span>
            </button>
          </div>
        </div>
        <div className="row row-eq-height">
          {!isLoading ? (
            <>
              {billings.length ? (
                billings.map(address => (
                  <AddressItem
                    address={address}
                    grid={3}
                    key={address.id}
                    isSelected={parseInt(billingAddress, 10)}
                    onSelect={this.handleAddressSelect}
                  />
                ))
              ) : (
                <NoResult />
              )}
            </>
          ) : (
            <InnerLoader type="table" />
          )}
        </div>
        <div className="row text-center text-xs-left text-sm-left">
          <div className="col-12">
            <hr />
          </div>
          <div className="col-xs-6 col-sm-6">
            <Button
              variant="light"
              className="d-sm-inline-block font-weight-semibold mb-3 mb-sm-0"
              onClick={() => handleTabChange('shipping-information')}
            >
              <i className="mdi mdi-arrow-left mr-1" />
              <span>Back</span>
            </Button>
          </div>
          <div className="col-xs-6 col-sm-6">
            <div className="text-xs-right text-sm-right">
              <Button
                disabled={!billingAddress}
                className="font-weight-semibold"
                onClick={() => handleTabChange('payment-information')}
              >
                <span>Continue</span>
                <i className="mdi mdi-arrow-right ml-1" />
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const selector = formValueSelector('checkoutForm');

const mapStateToProps = state => ({
  addresses: state.address.list,
  isLoading: state.address.isLoading,
  billingAddress: selector(state, 'billing_address_id')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllAddresses: addressActionTypes.getAllAddresses,
      showModal: addressActionTypes.showModal,
      dispatch: action => action
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingInfo);

BillingInfo.defaultProps = {
  getAllAddresses: () => {},
  showModal: () => {},
  addresses: [],
  isLoading: false,
  billingAddress: '',
  handleTabChange: () => {}
};

BillingInfo.propTypes = {
  getAllAddresses: PropTypes.func,
  showModal: PropTypes.func,
  addresses: PropTypes.arrayOf(PropTypes.any),
  isLoading: PropTypes.bool,
  billingAddress: PropTypes.string,
  handleTabChange: PropTypes.func
};
