/* global appConfig */
import React from 'react';

export default function Footer() {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <span>&copy; {appConfig.footerYear}, </span>
            <a href={appConfig.footerURL} className="text-muted">
              <b> {appConfig.companyName}</b>
            </a>
            <span>. {appConfig.footerText}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
