import Login from './containers/Login';
import Register from './containers/Register';
import Redirect from './components/Redirect';
import ThankYou from './containers/ThankYou';
import Cancel from './containers/Cancel';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import AffiliateLogin from './containers/AffiliateLogin';
import Dashboard from './containers/Dashboard';
import Tree from './containers/Tree';
import MainContent from './containers/Main';
import Order, { InvoiceView, ConfirmationZottoPay } from './containers/Order';
import Shop, { ProductDetails, ShopCategoryProducts } from './containers/Shop';
import Checkout from './containers/Checkout';
import Cart from './containers/Cart';
import Account, {
  Profile,
  Addresses,
  PaymentOptions
} from './containers/Account';
import CancelPayment from './containers/Checkout/Cancel';
import routePath from './constants/routePath';

const routes = [
  {
    path: routePath.login.path,
    component: Login,
    isPublic: true,
    name: routePath.login.title // Name is important for key and must be unique
  },
  {
    path: routePath.register.path,
    component: Register,
    isPublic: true,
    name: routePath.register.title // Name is important for key and must be unique
  },
  {
    path: routePath.ref.path,
    component: Redirect,
    isPublic: true,
    name: routePath.ref.title // Name is important for key and must be unique
  },
  {
    path: routePath.thankyou.path,
    component: ThankYou,
    isPublic: true,
    name: routePath.thankyou.title // Name is important for key and must be unique
  },
  {
    path: routePath.cancel.path,
    component: Cancel,
    isPublic: true,
    name: routePath.cancel.title // Name is important for key and must be unique
  },
  {
    path: routePath.forgot.path,
    component: ForgotPassword,
    isPublic: true,
    name: routePath.forgot.title // Name is important for key and must be unique
  },
  {
    path: routePath.reset.path,
    component: ResetPassword,
    isPublic: true,
    name: routePath.reset.title // Name is important for key and must be unique
  },
  {
    path: routePath.affiliateLogin.path,
    component: AffiliateLogin,
    isPublic: true,
    name: routePath.affiliateLogin.title // Name is important for key and must be unique
  },
  {
    path: '/',
    component: MainContent,
    name: 'root',
    routes: [
      {
        path: routePath.dashboard.path,
        exact: true,
        component: Dashboard,
        name: routePath.dashboard.title
      },

      {
        path: routePath.tree.path,
        exact: true,
        component: Tree,
        name: routePath.tree.title,
        permission: routePath.tree.permission
      },

      {
        path: routePath.order.path,
        exact: true,
        component: Order,
        name: routePath.order.title
      },
      {
        path: routePath.myOrder.path,
        exact: true,
        component: Order,
        name: routePath.myOrder.title
      },

      {
        path: routePath.invoiceView.path,
        exact: true,
        component: InvoiceView,
        name: routePath.invoiceView.title
      },
      {
        path: routePath.confirmationZotto.path,
        exact: true,
        component: ConfirmationZottoPay,
        name: routePath.confirmationZotto.title
      },
      {
        path: routePath.confirmation.path,
        exact: true,
        component: InvoiceView,
        name: routePath.confirmation.title
      },
      {
        path: routePath.shopProduct.path,
        exact: true,
        component: ProductDetails,
        name: routePath.shopProduct.title
      },
      {
        path: routePath.shop.path,
        exact: true,
        component: Shop,
        name: routePath.shop.title
      },
      {
        path: routePath.shopCategoryProducts.path,
        exact: true,
        component: ShopCategoryProducts,
        name: routePath.shop.title
      },
      {
        path: routePath.shopPagination.path,
        exact: true,
        component: ShopCategoryProducts,
        name: routePath.shopPagination.title
      },
      {
        path: routePath.cart.path,
        exact: true,
        component: Cart,
        name: routePath.cart.title
      },
      {
        path: routePath.checkout.path,
        exact: true,
        component: Checkout,
        name: routePath.checkout.title
      },
      {
        path: routePath.cancelPayment.path,
        exact: true,
        component: CancelPayment,
        name: routePath.cancelPayment.title
      },
      {
        path: routePath.account.path,
        exact: true,
        component: Account,
        name: routePath.account.title
      },
      {
        path: routePath.profile.path,
        exact: true,
        component: Profile,
        name: routePath.profile.title
      },
      {
        path: routePath.addresses.path,
        exact: true,
        component: Addresses,
        name: routePath.addresses.title
      }
      // {
      //   path: routePath.payOptions.path,
      //   exact: true,
      //   component: PaymentOptions,
      //   name: routePath.payOptions.title
      // }
    ]
  }
];

export default routes;
