import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import qs from 'qs';
import { auth as authActions } from '../actions';
import history from '../services/historyService';
import routePath from '../constants/routePath';

function AffiliateLogin({ doAffiliateLogin, loginProgress, loginError }) {
  useEffect(() => {
    const qParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    if (qParams && qParams.cid) {
      // Make API call to autologin
      doAffiliateLogin({
        cid: qParams.cid
      });
    } else {
      // Redirect to login if CID missing
      history.push(routePath.login.path);
    }
  }, []);

  if (loginProgress) {
    return (
      <div className="p-5 mt-5">
        <div
          className="alert alert-success d-flex align-item-center flex-row align-items-center"
          role="alert"
        >
          <div className="spinner-border spinner-border-sm m-2" role="status" />
          <span>Please wait, We are working on it</span>
        </div>
      </div>
    );
  }

  if (loginError) {
    return (
      <div className="p-5 mt-5">
        <div className="alert alert-danger" role="alert">
          Something went wrong
        </div>
      </div>
    );
  }

  return null;
}

const mapStateToProps = state => ({
  loginProgress: state.auth.loginProgress,
  loginError: state.auth.loginError
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { doAffiliateLogin: authActions.doAffiliateLogin },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AffiliateLogin);

AffiliateLogin.defaultProps = {
  doAffiliateLogin: () => {}
};

AffiliateLogin.propTypes = {
  doAffiliateLogin: PropTypes.func,
  loginProgress: PropTypes.bool.isRequired,
  loginError: PropTypes.bool.isRequired
};
