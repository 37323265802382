/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import NoResult from './NoResult';

function selectRow(props) {
  return {
    mode: 'checkbox',
    classes: 'table-light',
    // eslint-disable-next-line react/prop-types
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div className="custom-control custom-checkbox">
        <input
          type={mode}
          checked={checked || indeterminate}
          onChange={() => {}}
          className="custom-control-input"
        />
        <label className="custom-control-label" />
      </div>
    ),
    // eslint-disable-next-line react/prop-types
    selectionRenderer: ({ mode, checked, disabled }) => (
      <div className="custom-control custom-checkbox">
        <input
          type={mode}
          checked={checked}
          disabled={disabled}
          onChange={() => {}}
          className="custom-control-input"
        />
        <label className="custom-control-label" />
      </div>
    ),
    ...props
  };
}

const tableLoading = loading => {
  if (!loading) return <NoResult />;
  return <div className="py-5" />;
};

const pageButtonRenderer = ({ page, active, onPageChange }) => {
  const handleClick = e => {
    e.preventDefault();
    onPageChange(page);
  };

  let pageText = page;
  if (page === 'Next') pageText = '&#8250;';
  if (page === 'Back') pageText = '&#8249;';
  if (page === 'First') pageText = '&#171;';
  if (page === 'Last') pageText = '&#187;';

  return (
    <li key={`page_${page}`} className={`page-item ${active && 'active'}`}>
      <button
        type="button"
        className="page-link"
        onClick={handleClick}
        dangerouslySetInnerHTML={{ __html: pageText }}
      />
    </li>
  );
};

const paginationOptions = ({
  totalSize,
  sizePerPage,
  hideSizePerPage,
  page = 1,
  sizePerPageList
}) => {
  let sizeList = [];
  if (sizePerPageList) {
    sizeList = sizePerPageList.map(limit => ({
      text: limit,
      value: limit
    }));
  } else {
    sizeList = [{ text: '25', value: 25 }];
    if (totalSize > 25) sizeList.push({ text: '50', value: 50 });
    if (totalSize > 50) sizeList.push({ text: '100', value: 100 });
    if (totalSize > 100) sizeList.push({ text: '250', value: 250 });
    if (totalSize > 250) sizeList.push({ text: '500', value: 500 });
    if (totalSize > 500) sizeList.push({ text: '1000', value: 1000 });
  }

  return {
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    hideSizePerPage: hideSizePerPage || totalSize === 0,
    // hideSizePerPage: hideSizePerPage || totalSize < sizePerPage,
    sizePerPageList: sizeList,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    totalSize,
    sizePerPage,
    page,
    paginationTotalRenderer: (from, to) => {
      if (totalSize) {
        return (
          <div className="react-bootstrap-table-pagination-total text-muted align-middle text-center text-sm-left ml-0 mb-3 mb-sm-0 ml-sm-2 mt-1 mt-sm-0 d-sm-inline-block">
            {`Showing ${from} to ${to} of ${totalSize} Results`}
          </div>
        );
      }
      return '';
    },
    pageButtonRenderer
  };
};

function AdvanceTable(props) {
  const {
    columns,
    data = [],
    keyField,
    fixed,
    loading,
    remote,
    totalSize,
    sizePerPage,
    sizePerPageList,
    hideSizePerPage = true,
    page,
    onTableChange,
    disableSelect,
    onSelect,
    onSelectAll,
    selected = [],
    className = ''
  } = props;
  return (
    <BootstrapTable
      keyField={keyField || 'id'}
      bootstrap4
      columns={columns}
      data={data}
      remote={remote}
      onTableChange={onTableChange}
      pagination={paginationFactory(
        paginationOptions({
          totalSize: totalSize || data.length,
          page,
          sizePerPage,
          hideSizePerPage,
          sizePerPageList
        })
      )}
      loading={loading}
      bordered={false}
      wrapperClasses={`table-responsive ${className} ${
        fixed ? 'fixed-header' : ''
      }`}
      headerClasses="thead-light"
      {...(disableSelect
        ? {}
        : { selectRow: selectRow({ onSelect, onSelectAll, selected }) })}
      noDataIndication={() => tableLoading(loading)}
      overlay={overlayFactory({
        spinner: true,
        styles: {
          overlay: base => ({
            ...base,
            text: 'Loading...',
            background: 'rgba(241, 243, 250, 0.9)'
          })
        }
      })}
    />
  );
}

export {
  AdvanceTable as default,
  paginationFactory,
  selectRow,
  tableLoading,
  paginationOptions,
  BootstrapTable
};

selectRow.defaultProps = {
  mode: '',
  checked: false,
  indeterminate: false,
  disabled: false
};
selectRow.propTypes = {
  mode: PropTypes.string,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool
};
pageButtonRenderer.defaultProps = {
  page: '',
  active: '',
  onPageChange: () => {}
};
pageButtonRenderer.propTypes = {
  page: PropTypes.string,
  active: PropTypes.string,
  onPageChange: PropTypes.func
};

AdvanceTable.defaultProps = {
  columns: [],
  data: [],
  keyField: 'id',
  fixed: undefined,
  loading: false,
  remote: {},
  totalSize: undefined,
  sizePerPage: '',
  sizePerPageList: undefined,
  hideSizePerPage: true,
  onTableChange: () => {},
  selected: [],
  className: 'table-nowrap',
  page: 1,
  disableSelect: true,
  onSelect: () => {},
  onSelectAll: () => {}
};

AdvanceTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  keyField: PropTypes.string,
  fixed: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  loading: PropTypes.bool,
  remote: PropTypes.objectOf(PropTypes.bool),
  totalSize: PropTypes.number,
  sizePerPage: PropTypes.string,
  sizePerPageList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])
  ),
  hideSizePerPage: PropTypes.bool,
  onTableChange: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
  page: PropTypes.number,
  disableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func
};
