import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, Form, reduxForm } from 'redux-form';
import routePath from '../../constants/routePath';
import { required } from '../../utills/validator';
import { inputField } from '../../components/Fields';
import btnLoader from '../../assets/images/btn-loader.gif';
import ShowHidePasswordField from '../../components/ShowHidePasswordField';

const LoginForm = props => {
  const { handleSubmit, submitLogin, inProgress } = props;
  return (
    <Form
      className="needs-validation"
      noValidate
      onSubmit={handleSubmit(submitLogin)}
    >
      <div className="form-group">
        <Field
          name="username"
          component={inputField}
          type="text"
          className="form-control"
          placeholder="Cust ID or Email"
          validate={[required]}
          label="Cust ID or Email"
        />
      </div>
      <div className="form-group position-relative">
        <Link to={routePath.forgot.path} className="text-muted float-right">
          <small>Forgot Password?</small>
        </Link>
        <ShowHidePasswordField
          name="password"
          placeholder="Password"
          validate={[required]}
        />
      </div>
      {/* <div className="form-group">
        <label className="custom-control custom-checkbox">
          <input className="custom-control-input" type="checkbox" />
          <span className="custom-control-label">
            Remember Me
          </span>
        </label>
      </div> */}
      <div className="form-group mb-0 text-center">
        <button
          type="submit"
          disabled={inProgress}
          // style={{ marginTop: '15px' }}
          className="btn btn-primary btn-lg pl-5 pr-5 mt-2"
        >
          {inProgress ? <img src={btnLoader} alt="" /> : 'Login'}
        </button>
      </div>
    </Form>
  );
};
LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitLogin: PropTypes.func,
  inProgress: PropTypes.bool
};
LoginForm.defaultProps = {
  handleSubmit: () => {},
  submitLogin: () => {},
  inProgress: false
};

export default reduxForm({
  form: 'loginForm' // a unique identifier for this form
})(LoginForm);
