/* eslint-disable camelcase */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import Select from 'react-select';
import BreadCrumb from '../components/BreadCrumb';
import {
  auth as authActions,
  dashboard,
  dashboard as dashboardAction
} from '../actions';
import PageLoader from '../components/PageLoader';
import StatisticsWidget from '../components/StatisticsWidget';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateMonthWise: [],
      selectedMMYY: {
        label: 'Lifetime',
        value: ''
      }
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { getCpointCalculation } = this.props;
    const { selectedMMYY } = this.state;
    this.getYearMonth();
    getCpointCalculation({ mmyy: selectedMMYY ? selectedMMYY.value : '' });
  }

  getYearMonth() {
    let startDate = moment('04/30/2020');
    const currentDate = moment().add(1, 'month');
    const difference = currentDate.diff(startDate, 'months');
    const wiseData = [];
    let index = 1;

    // eslint-disable-next-line no-plusplus
    for (index; index <= difference + 1; index++) {
      const newDate = startDate;
      wiseData.push({
        label: `${newDate.format('MMMM')} - ${newDate.format('YYYY')}`,
        value: `${newDate.format('M')},${newDate.format('YYYY')}`
      });
      startDate = startDate.add(1, 'month');
    }
    wiseData.push({ label: 'Lifetime', value: '' });
    this.setState({
      dateMonthWise: wiseData.reverse()
    });
  }

  handleSelectChange = event => {
    const { getCpointCalculation } = this.props;
    const selected = event.value;

    if (selected) {
      getCpointCalculation({
        mmyy: selected
      });
    } else {
      const { selectedMMYY } = this.state;
      getCpointCalculation({ mmyy: selectedMMYY ? selectedMMYY.value : '' });
    }
  };

  checkExpiredDate = date => {
    if (moment(date) < moment()) {
      return true; // is expired
    }
    return false;
  };

  render() {
    const { progress, dashboardData } = this.props;
    const { dateMonthWise, selectedMMYY } = this.state;
    return (
      <>
        <div className="page-title-box">
          <BreadCrumb list={['dashboard']} />
          <h4 className="page-title">Dashboard</h4>
        </div>
        <div className="mb-3">
          <img
            style={{ width: '100%' }}
            src="/assets/images/customer-program.jpg"
            alt="customer connect programme"
          />
        </div>
        {progress && <PageLoader />}
        <div>
          <div className="row">
            <div className="col-sm-6 col-lg-4 mb-3">
              <Select
                defaultValue={selectedMMYY}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select Month"
                isClearable={false}
                isSearchable
                name="month_picker"
                options={dateMonthWise}
                onChange={this.handleSelectChange}
              />
            </div>
          </div>

          <div className="row row-eq-height">
            <div className="col-md-4 mb-3">
              <div className="cta-box bg-success text-white mb-0 h-100 card">
                <div className="card-body p-2">
                  <div className="d-flex justify-content-between">
                    <h5 className="font-weight-bold font-13 mt-0 text-uppercase">
                      Total C-POINTS
                    </h5>
                    <div>
                      <i className="mdi mdi-artstation widget-icon ml-2 rounded-circle bg-white" />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <h6 className="text-white mb-0 text-truncate d-block">
                        Total Earned
                      </h6>
                      <h4 className="mt-2 mb-2 text-white font-weight-bold">
                        {dashboardData && dashboardData.total_cpoints
                          ? dashboardData.total_cpoints
                          : '0.00'}
                        <br />
                        <small>
                          {dashboardData && dashboardData.requested_month
                            ? `${dashboardData.requested_month}`
                            : ''}
                        </small>
                      </h4>
                    </div>
                    <div className="col-6">
                      <h6 className="text-white mb-0 text-truncate d-block text-right">
                        Last Earned
                      </h6>
                      <h4 className="mt-2 mb-2 text-white font-weight-bold text-right">
                        {dashboardData &&
                        dashboardData.earned &&
                        dashboardData.earned.last_earned_cpoint ? (
                          <>
                            {dashboardData.earned.last_earned_cpoint}
                            <br />
                            <small>
                              {dashboardData.earned.last_earned_date_tz}
                            </small>
                          </>
                        ) : (
                          '0.00'
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="cta-box bg-info text-white mb-0 h-100 card">
                <div className="card-body p-2">
                  <div className="d-flex justify-content-between">
                    <h5 className="font-weight-bold font-13 mt-0 text-uppercase">
                      Redeemed C-POINTS
                    </h5>
                    <div>
                      <i className="mdi mdi-repeat widget-icon ml-2 rounded-circle bg-white" />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-6">
                      <h6 className="text-white mb-0 text-truncate d-block">
                        Total Used
                      </h6>
                      <h4 className="mt-2 mb-2 text-white font-weight-bold">
                        {dashboardData && dashboardData.total_used_cp
                          ? dashboardData.total_used_cp
                          : '0.00'}
                      </h4>
                    </div>
                    <div className="col-6">
                      <h6 className="text-white mb-0 text-truncate d-block text-right">
                        Last Redeemed
                      </h6>
                      <h4 className="mt-2 mb-2 text-white font-weight-bold text-right">
                        {dashboardData &&
                        dashboardData.redeemed &&
                        dashboardData.redeemed.last_redeemed_cpoint ? (
                          <>
                            {dashboardData.redeemed.last_redeemed_cpoint}
                            <br />
                            <small>
                              {dashboardData.redeemed.last_redeemed_date_tz}
                            </small>
                          </>
                        ) : (
                          '0.00'
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div
                className="cta-box text-white mb-0 h-100 card"
                style={{ backgroundColor: '#b164a9' }}
              >
                <div className="card-body p-2">
                  <div className="d-flex justify-content-between">
                    <h5 className="font-weight-bold font-13 mt-0 text-uppercase">
                      Remaining C-POINTS
                    </h5>
                    <div>
                      <i className="mdi mdi-bank widget-icon ml-2 rounded-circle bg-white" />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-6">
                      <h6 className="text-white mb-0 text-truncate d-block">
                        Remaining
                      </h6>
                      <h4 className="mt-2 mb-2 text-white font-weight-bold">
                        {dashboardData && dashboardData.remaining_cp ? (
                          <>
                            {dashboardData.remaining_cp}
                            <br />
                            <small>
                              {dashboardData.expiry_date_tz
                                ? `Expires at ${dashboardData.expiry_date_tz}`
                                : null}
                            </small>
                          </>
                        ) : (
                          '0.00'
                        )}
                      </h4>
                    </div>
                    <div className="col-6">
                      <h6 className="text-white mb-0 text-truncate d-block text-right">
                        Expired
                      </h6>
                      <h4 className="mt-2 mb-2 text-white font-weight-bold text-right">
                        {dashboardData &&
                        dashboardData.expired &&
                        dashboardData.expired.expired_cpoint
                          ? dashboardData.expired.expired_cpoint
                          : '0.00'}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-eq-height">
            <div className="col-sm-6 col-lg-6 mb-3">
              <StatisticsWidget
                icon="mdi mdi-account"
                bgclassName="card-icon mb-0 h-100"
                cardClass="p-2"
                description="CONNECT LOYALTY POINTS"
                title={
                  dashboardData && dashboardData.total_clp
                    ? dashboardData.total_clp
                    : '0.00'
                }
              >
                <p className="mb-0 text-muted font-13">
                  <span className="text-nowrap">
                    {`Total - ${
                      dashboardData && dashboardData.requested_month
                        ? dashboardData.requested_month
                        : 'Lifetime'
                    }`}
                  </span>
                </p>
              </StatisticsWidget>
            </div>

            <div className="col-sm-6 col-lg-6 mb-3">
              <StatisticsWidget
                icon="mdi mdi-sitemap"
                bgclassName="card-icon mb-0 h-100"
                cardClass="p-2"
                description="CONNECT REFERRAL POINTS"
                title={
                  dashboardData && dashboardData.total_crp
                    ? dashboardData.total_crp
                    : '0.00'
                }
              >
                <p className="mb-0 text-muted font-13">
                  <span className="text-nowrap">
                    {`Total - ${
                      dashboardData && dashboardData.requested_month
                        ? dashboardData.requested_month
                        : 'Lifetime'
                    }`}
                  </span>
                </p>
              </StatisticsWidget>
            </div>
          </div>
          <div className="row">
            <div className="card bg-transparent mx-2">
              <div className="card-body">
                <div>
                  <h5 className="text-uppercase">
                    How your connect points work
                  </h5>
                  <div className="mb-3">
                    <ul className="list-none mb-0">
                      <li>
                        <i className="mdi mdi-leaf widget-icon-leaf bg-transparent" />{' '}
                        Your Connect Loyalty and Referral points are valid for a{' '}
                        <q>
                          {' '}
                          <strong>rolling</strong>
                        </q>{' '}
                        120 days.
                      </li>
                      <li>
                        <i className="mdi mdi-leaf widget-icon-leaf bg-transparent" />{' '}
                        What that means is that, if you purchase just 1 item in
                        120 days or refer someone that purchases or uses some of
                        your connect points in 120 days, all points get{' '}
                        <q>
                          <strong>banked</strong>
                        </q>{' '}
                        and roll over for another 120 days and can be used over
                        the following 120 days. This will keep rolling every 120
                        days as long as your account has been kept active.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.user,
  dashboardData: state.dashboard.data,
  progress: state.dashboard.progress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogout: authActions.doLogout,
      getCpointCalculation: dashboardAction.getCpointCalculation
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
