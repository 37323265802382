/* global appConfig */
import React from 'react';

export default function Footer() {
  return (
    <div className="footer footer-alt">
      <span>&copy; {appConfig.footerYear}, </span>
      <a href={appConfig.footerURL} className="text-muted">
        <b> {appConfig.companyName}</b>
      </a>
      <span>. {appConfig.footerText}</span>
    </div>
  );
}
