import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';

import { app as appActions } from '../actions';

function StoreAddress({
  country,
  storeAddressCountryWise,
  getPrefetchStoreAddress
}) {
  const storeAddress = storeAddressCountryWise
    ? storeAddressCountryWise[country]
    : {};

  useEffect(() => {
    if (country) {
      getPrefetchStoreAddress(country);
    }
  }, [country]);

  if (!isEmpty(storeAddress)) {
    return (
      <p className="font-weight-normal m-0">
        {storeAddress.company_name}
        <br />
        {storeAddress.street_name_number}, {storeAddress.address_line1}
        <br />
        {storeAddress.address_line2 ? (
          <>
            {storeAddress.address_line2}
            <br />
          </>
        ) : null}
        {storeAddress.city_details
          ? storeAddress.city_details.name
          : storeAddress.city}
        ,
        {storeAddress.state_details
          ? storeAddress.state_details.name
          : storeAddress.state}
        <br />
        {storeAddress.country_details
          ? storeAddress.country_details.name
          : storeAddress.country}
        <br />
        {storeAddress.postcode}
        <br />
        {`Tax Number: ${storeAddress.company_vat}`}
        <br />
        {`Phone: ${storeAddress.company_phone}`}
        <br />
        {`Email: ${storeAddress.company_email}`}
      </p>
    );
  }
  return null;
}

const mapStateToProps = state => ({
  storeAddressCountryWise: state.app.storeAddressCountryWise
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPrefetchStoreAddress: appActions.getPrefetchStoreAddress,
      dispatch: action => action
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreAddress);

StoreAddress.defaultProps = {
  getPrefetchStoreAddress: () => {},
  storeAddressCountryWise: undefined
};

StoreAddress.propTypes = {
  country: PropTypes.string.isRequired,
  getPrefetchStoreAddress: PropTypes.func,
  storeAddressCountryWise: PropTypes.PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
};
