import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import routes from './routes';
import RouteWithSubRoutes from './components/RouteWithSubRoutes';
import ShopHelper from './components/ShopHelper';
import configureStore from './store/configureStore';
import rootSaga from './sagas';
import history from './services/historyService';
import './services/interceptorsService';
// import Theme from './components/Theme';

const store = configureStore();
store.runSaga(rootSaga);

function App() {
  return (
    <Provider store={store}>
      {/* <Theme /> */}
      <div className="App">
        <Router history={history}>
          <Switch>
            {routes.map(route => (
              <RouteWithSubRoutes key={route.name} {...route} />
            ))}
          </Switch>
        </Router>
        <NotificationContainer />
        <ShopHelper />
      </div>
    </Provider>
  );
}

export default App;
