import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  location as locationActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllLocation(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${actions.data.type}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: locationActionTypes.LOCATION_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getLocation(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${actions.data.type}/${actions.data.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: locationActionTypes.LOCATION_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* createLocation(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${actions.data.type}`,
      method: 'POST',
      data: actions.data.data,
      successMessage: 'Location was added successfully'
    });
    if (responseJson) {
      yield put({
        type: locationActionTypes.LOCATION_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath[actions.data.type].path
      });
    }
  } catch (error) {
    yield put({ type: locationActionTypes.LOCATION_CREATE_ERROR, data: error });
  }
}

function* updateLocation(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${actions.data.type}/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Location updated successfully'
    });
    if (responseJson) {
      yield put({
        type: locationActionTypes.LOCATION_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath[actions.data.type].path
        });
      }
    }
  } catch (error) {
    yield put({ type: locationActionTypes.LOCATION_UPDATE_ERROR, data: error });
  }
}

function* watchPackageListAll() {
  yield takeLatest(
    locationActionTypes.LOCATION_ALL_LIST_REQUEST,
    getAllLocation
  );
}

function* watchGetLocation() {
  yield takeLatest(locationActionTypes.LOCATION_REQUEST, getLocation);
}

function* watchLocationCreate() {
  yield takeLatest(locationActionTypes.LOCATION_CREATE_REQUEST, createLocation);
}

function* watchLocationUpdate() {
  yield takeLatest(locationActionTypes.LOCATION_UPDATE_REQUEST, updateLocation);
}

export default [
  fork(watchPackageListAll),
  fork(watchLocationCreate),
  fork(watchLocationUpdate),
  fork(watchGetLocation)
];
