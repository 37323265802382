import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { shop as shopActionTypes } from '../actions';

const responseData = {};

function* getShopProductDetails(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/customer/shopDetails`,
      params: actions.params,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: shopActionTypes.SHOP_PRODUCT_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: shopActionTypes.SHOP_PRODUCT_ERROR, data: error });
  }
}

function* searchCustomerProductList(actions) {
  let token;

  try {
    let jsonResponse = null;
    if (token) {
      token.cancel();
    }
    // if (responseData[actions.query]) {
    //   jsonResponse = responseData[actions.query];
    // } else {
    token = axios.CancelToken;
    const axiosReqeust = token.source();

    jsonResponse = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/customer/shop`,
      method: 'GET',
      params: actions.params,
      cancelToken: axiosReqeust.token
    });
    // }

    if (jsonResponse) {
      // responseData[actions.query] = jsonResponse;
      yield put({
        type: shopActionTypes.SEARCH_SUCCESS,
        data: jsonResponse
      });
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request cancelled', error.message);
    } else {
      yield put({
        type: shopActionTypes.SEARCH_ERROR,
        data: error
      });
    }
  }
}

function* getShopCategory(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/prefetch/shop-category`,
      method: 'GET',
      params: actions.param
    });
    if (responseJson) {
      yield put({
        type: shopActionTypes.GET_SHOP_CATEGORY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: shopActionTypes.GET_SHOP_CATEGORY_ERROR, data: error });
  }
}

function* watchGetShopProductDetails() {
  yield takeLatest(shopActionTypes.SHOP_PRODUCT_REQUEST, getShopProductDetails);
}

function* watchSearchCustomerProductList() {
  yield takeLatest(shopActionTypes.SEARCH_REQUEST, searchCustomerProductList);
}

function* watchGetShopCategory() {
  yield takeLatest(shopActionTypes.GET_SHOP_CATEGORY_REQUEST, getShopCategory);
}

export default [
  fork(watchGetShopProductDetails),
  fork(watchSearchCustomerProductList),
  fork(watchGetShopCategory)
];
