const shipping = {
  SHIPPING_ALL_LIST_REQUEST: 'SHIPPING_ALL_LIST_REQUEST',
  SHIPPING_ALL_LIST_SUCCESS: 'SHIPPING_ALL_LIST_SUCCESS',
  SHIPPING_ALL_LIST_ERROR: 'SHIPPING_ALL_LIST_ERROR',
  SHIPPING_CREATE_REQUEST: 'SHIPPING_CREATE_REQUEST',
  SHIPPING_CREATE_SUCCESS: 'SHIPPING_CREATE_SUCCESS',
  SHIPPING_CREATE_ERROR: 'SHIPPING_CREATE_ERROR',
  SHIPPING_UPDATE_REQUEST: 'SHIPPING_UPDATE_REQUEST',
  SHIPPING_UPDATE_SUCCESS: 'SHIPPING_UPDATE_SUCCESS',
  SHIPPING_UPDATE_ERROR: 'SHIPPING_UPDATE_ERROR',
  SHIPPING_REQUEST: 'SHIPPING_REQUEST',
  SHIPPING_SUCCESS: 'SHIPPING_SUCCESS',
  getAllShipping: data => ({
    type: shipping.SHIPPING_ALL_LIST_REQUEST,
    data
  }),
  createShipping: data => ({ type: shipping.SHIPPING_CREATE_REQUEST, data }),
  updateShipping: data => ({ type: shipping.SHIPPING_UPDATE_REQUEST, data }),
  getShippingById: id => ({ type: shipping.SHIPPING_REQUEST, id })
};

export default shipping;
