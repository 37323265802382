import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  shipping as shippingActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllShipping() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/shipping-charges`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: shippingActionTypes.SHIPPING_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getShipping(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/shipping-charges/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: shippingActionTypes.SHIPPING_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* createShipping(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/shipping-charges`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Shipping was addedd successfully'
    });
    if (responseJson) {
      yield put({
        type: shippingActionTypes.SHIPPING_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.shipping.path
      });
    }
  } catch (error) {
    yield put({ type: shippingActionTypes.SHIPPING_CREATE_ERROR, data: error });
  }
}

function* updateShipping(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/shipping-charges/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Shipping updated successfully'
    });
    if (responseJson) {
      yield put({
        type: shippingActionTypes.SHIPPING_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.shipping.path
        });
      }
    }
  } catch (error) {
    yield put({ type: shippingActionTypes.SHIPPING_UPDATE_ERROR, data: error });
  }
}

function* watchShippingListAll() {
  yield takeLatest(
    shippingActionTypes.SHIPPING_ALL_LIST_REQUEST,
    getAllShipping
  );
}

function* watchGetShipping() {
  yield takeLatest(shippingActionTypes.SHIPPING_REQUEST, getShipping);
}

function* watchShippingCreate() {
  yield takeLatest(shippingActionTypes.SHIPPING_CREATE_REQUEST, createShipping);
}

function* watchShippingUpdate() {
  yield takeLatest(shippingActionTypes.SHIPPING_UPDATE_REQUEST, updateShipping);
}

export default [
  fork(watchShippingListAll),
  fork(watchShippingCreate),
  fork(watchShippingUpdate),
  fork(watchGetShipping)
];
