import { fsb as fsbActionTypes } from '../actions';
import initialState from '../constants/initialState';

function fsb(state = initialState.fsb, action) {
  switch (action.type) {
    case fsbActionTypes.FSB_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case fsbActionTypes.FSB_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          plan: action.data
        };
      }
      return state;
    case fsbActionTypes.FSB_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case fsbActionTypes.FSB_UPDATE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case fsbActionTypes.FSB_UPDATE_SUCCESS:
      if (action.data) {
        return {
          ...state,
          plan: action.data,
          updateProgress: false
        };
      }
      return {
        ...state,
        updateProgress: false
      };
    case fsbActionTypes.FSB_UPDATE_ERROR:
      return {
        ...state,
        updateProgress: false
      };
    default:
      return state;
  }
}

export default fsb;
