import { cart as cartActionTypes } from '../actions';
import initialState from '../constants/initialState';

function cart(state = initialState.cart, action) {
  switch (action.type) {
    case cartActionTypes.CART_SUCCESS:
      if (action.data) {
        return {
          ...state,
          cartId: action.data.id
        };
      }
      return state;
    case cartActionTypes.CART_DETAILS_SUCCESS:
    case cartActionTypes.UPDATE_CART_SUCCESS:
    case cartActionTypes.DELETE_CART_SUCCESS:
    case cartActionTypes.ADD_TO_CART_SUCCESS:
      if (action.data) {
        return {
          ...state,
          cartDetails: action.data
        };
      }
      return state;
    case cartActionTypes.REVISE_CART_REQUEST:
      return {
        ...state,
        loadingreviseCart: true
      };
    case cartActionTypes.REVISE_CART_SUCCESS:
      if (action.data) {
        return {
          ...state,
          cartDetails: action.data,
          loadingreviseCart: false
        };
      }
      return state;
    case cartActionTypes.CART_CALCULATION_SUCCESS:
      if (action.data) {
        return {
          ...state,
          cartCalculation: action.data
        };
      }
      return state;
    default:
      return state;
  }
}

export default cart;
