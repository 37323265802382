import React, { useCallback, useEffect, useState } from 'react';
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { findIndex } from 'lodash';
import { getCartShippingOption } from '../../services/apiService';

function ShippingOptions({
  shippingAddress,
  cartId,
  dispatch,
  shippingMode,
  storePickup,
  currency
}) {
  const [cartShippingOptions, setCartShippingOptions] = useState({});
  const callback = useCallback(
    data => {
      if (data && data.shipping_modes) {
        // Check if the selected shipping is present in API response
        const foundId = findIndex(data.shipping_modes, o => {
          return o.id === parseInt(shippingMode, 10);
        });
        // If not found set to the first shipping method
        if (foundId < 0) {
          dispatch(
            change('checkoutForm', 'shipping_mode', data.shipping_modes[0].id)
          );
        }
      } else {
        dispatch(change('checkoutForm', 'shipping_mode', ''));
      }
    },
    [shippingMode]
  );

  useEffect(() => {
    const fetchShippingOptions = async () => {
      try {
        if (shippingAddress || storePickup) {
          const res = await getCartShippingOption({
            cart_id: cartId,
            address_id: shippingAddress,
            pickup_from_store: storePickup
          });
          setCartShippingOptions(res);
          callback(res);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchShippingOptions();
  }, [shippingAddress, cartId, storePickup]);

  const handleShippingSelect = e => {
    dispatch(change('checkoutForm', 'shipping_mode', e.target.value));
  };

  if (cartShippingOptions && cartShippingOptions.shipping_modes) {
    return (
      <>
        <tr>
          <td colSpan="2">Shipping ({cartShippingOptions.country})</td>
        </tr>
        {cartShippingOptions.shipping_modes.map(item => (
          <tr className="font-13" key={`ship_id_${item.id}`}>
            <td className="pt-0">
              <label>
                <input
                  name="shipping_mode"
                  value={item.id}
                  checked={parseInt(shippingMode, 10) === item.id}
                  type="radio"
                  onChange={handleShippingSelect}
                  className="mr-1"
                />
                {item.delivery_name}:
              </label>
              {item.delivery_time ? (
                <small className="d-block">({item.delivery_time})</small>
              ) : null}
            </td>
            <td className="pt-0 text-right">{`${currency}${item.rate}`}</td>
          </tr>
        ))}
      </>
    );
  }

  return null;
}

const selector = formValueSelector('checkoutForm');

const mapStateToProps = state => ({
  cartShippingOptions: state.cart.cartShippingOptions,
  shippingAddress: selector(state, 'shipping_address_id'),
  shippingMode: selector(state, 'shipping_mode'),
  storePickup: selector(state, 'pickup_from_store')
});

export default connect(
  mapStateToProps,
  null
)(ShippingOptions);
