import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Image from '../../components/Image';
import { productImage } from '../../constants/imageSize';
import img from '../../assets/images/img-placeholder.jpg';
import { getCustomerProductPrice } from '../../utills/custom';

function UnavailableTag({ status, soldout }) {
  if (!status) {
    return (
      <div className="cart-item-sold-out col-12 font-weight-bold font-12 mb-2">
        <i className="dripicons-wrong mr-2" />
        Item no longer available! Please remove.
      </div>
    );
  }
  if (soldout) {
    return (
      <div className="cart-item-sold-out col-12 font-weight-bold font-12 mb-2">
        <i className="dripicons-warning mr-2" />
        Temporarily out of stock! Please remove.
      </div>
    );
  }
  return '';
}

function CartItem({ data, currency, cartCalculation, handleCpoint }) {
  const [cpQuantity, setCpQuantity] = useState(1);
  const [invalidValue, setInvalidValue] = useState(false);

  let pimg = get(data, 'product.product_images[0].file.file_path', null);
  if (pimg) {
    pimg = `${process.env.REACT_APP_IMAGE_URL}/${pimg}`;
  } else {
    pimg = img;
  }

  function getisChecked() {
    if (cartCalculation.cp_cart_items) {
      return cartCalculation.cp_cart_items.some(
        item => item.item.id === data.id
      );
    }

    return false;
  }

  function getMaxQuantity() {
    const maxQuantity = data.quantity;
    let calc = parseInt(
      parseInt(cartCalculation.available_cpoint, 10) /
        parseInt(data.product.cpoint, 10),
      10
    );

    const coveredCP = cartCalculation.cp_cart_items.filter(
      item => item.item.id === data.id
    );
    if (coveredCP.length) {
      calc += coveredCP[0].quantity_covered;
    }

    return calc > maxQuantity ? maxQuantity : calc;
  }

  function handleQuantityChange(event) {
    const quantity = parseInt(event.target.value, 10);
    setCpQuantity(quantity);
    if (
      Number.isNaN(Number(quantity)) ||
      quantity < 1 ||
      quantity > data.quantity ||
      quantity > getMaxQuantity()
    ) {
      setInvalidValue(true);
    } else {
      setInvalidValue(false);
      const cartItem = {
        id: data.id,
        use_cpoint: true,
        quantity
      };
      handleCpoint(cartItem);
    }
  }

  function handleCheckBox(event) {
    const macQty = getMaxQuantity();
    const cartItem = {
      id: data.id,
      use_cpoint: event.target.checked,
      quantity: macQty
    };
    handleCpoint(cartItem);
    setCpQuantity(macQty);
  }

  const isChecked = getisChecked();

  return (
    <div className="container">
      <div
        className={
          data.product.sold_out || !data.product.status
            ? 'row align-middle no-gutters1 pt-2 pb-2 border-top cart-item-sold-out mb-1'
            : 'row align-middle no-gutters1 pt-2 pb-2 border-top'
        }
      >
        <UnavailableTag
          soldout={data.product.sold_out}
          status={data.product.status}
        />
        <div className="col-1 px-0 mx-0">
          <input
            type="checkbox"
            disabled={
              !isChecked &&
              (!parseFloat(data.product.cpoint) ||
                parseFloat(cartCalculation.available_cpoint) <
                  parseFloat(data.product.cpoint))
            }
            id={`box_${data.id}`}
            checked={isChecked}
            onChange={handleCheckBox}
          />
        </div>
        <div className="col-2 px-0">
          <Image
            src={pimg}
            alt={data.product.title}
            size={productImage.productAdmin}
            className="rounded w-100"
          />
        </div>
        <div className="col-6 pr-0">
          <Link
            to={`/shop/product/${data.product.slug}`}
            className="text-body font-weight-semibold font-12"
          >
            {data.product.title}
          </Link>
          <br />
          <small>
            {`${data.quantity} x ${currency}${
              parseInt(data.product.sale_price, 10)
                ? data.product.sale_price
                : data.product.regular_price
            }`}
          </small>
          <br />
          <span className="badge badge-light text-success font-9">
            C-POINT: {data.product.cpoint}
          </span>
          <div className="font-weight-bold font-9">
            TOTAL CP:{' '}
            {parseFloat(data.product.cpoint * data.quantity, 10).toFixed(2)}
          </div>
        </div>
        <div className="col-3 text-right font-weight-bold font-12 px-0">
          {`${currency}${(
            data.quantity * getCustomerProductPrice(data.product, true)
          ).toFixed(2)}`}
          {isChecked ? (
            <div className="mt-2">
              <input
                type="number"
                min="1"
                max={getMaxQuantity()}
                value={cpQuantity}
                className={
                  invalidValue
                    ? 'form-control text-danger border-danger'
                    : 'form-control'
                }
                onChange={handleQuantityChange}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CartItem;
CartItem.defaultProps = {
  data: {}
};

CartItem.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
};
