import routePath from './routePath';

const menu = [
  {
    label: routePath.dashboard.title,
    to: routePath.dashboard.path,
    icon: 'mdi mdi-view-dashboard mr-1'
  },
  {
    label: routePath.shop.title,
    to: routePath.shop.path,
    icon: 'mdi mdi-shopping mr-1'
  },
  {
    label: routePath.order.title,
    to: routePath.order.path,
    icon: 'fas fa-shopping-cart mr-1'
  },
  {
    label: 'My Account',
    section: true,
    icon: 'mdi mdi-face mr-1',
    children: [
      {
        label: routePath.profile.title,
        to: routePath.profile.path,
        icon: 'mdi mdi-face'
        // permission: routePath.profile.permission
      },
      {
        label: routePath.addresses.title,
        to: routePath.addresses.path,
        icon: 'mdi mdi-map-marker-multiple'
      }
      // {
      //   label: routePath.payOptions.title,
      //   to: routePath.payOptions.path,
      //   icon: 'mdi mdi-wallet'
      // }
    ]
  }
  // display downline on the navbar
  // {
  //   label: routePath.tree.title,
  //   to: routePath.tree.path,
  //   icon: 'mdi mdi-sitemap mr-1'
  // }
];

export default menu;
